.Other_Say {
  position: relative;
}

.Other_Say .heading-holder .overlay {
  position: absolute;
  top: -5%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.Other_Say .heading-holder {
  top: 99px;
  text-align: center;
  position: relative;
}

.Other_Say .heading-holder .rooms {
  font-family: "Poppins", "sans-serif" !important;
  font-size: 16px;
  color: black;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.Other_Say .heading-holder h1.hostel {
  letter-spacing: 3px;
  margin-top: 44px;
  font-size: 40px;
  color: black;
  font-family: "Poppins", "sans-serif" !important;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.Other_Say .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 12px;
}

.Other_Say .heading-holder h1.book {
  font-family: Signatrue Font;
  font-size: 60px;
  color: #b7b7b7;
  opacity: 50%;
  letter-spacing: 8.23px;
}

.Other_Say .slider_main .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.Other_Say .slider_main {
  margin-top: 10%;
}

.Other_Say .slider_main .image {
  text-align: center;
}

.Other_Say .slider_main img.img-fluid {
  width: 70px;
  height: 70px;

  border-radius: 50%;
}

.Other_Say .slider_main h4.text {
  text-align: center;
  line-height: 27px;
  color: #00000099;
  font-size: 16px;
}

.Other_Say .slider_main .device {
  width: 671px;
  height: 291px;
  left: 35%;
  top: 0px;
  /* height: 100%; */
  padding: 30px 40px;
  border: 3px solid white;
  margin: 5px auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 9px #00000029;
  opacity: 1;
}

.Other_Say .slider_main h4.text {
  position: relative;
  text-align: center;
  line-height: 27px;
  color: #00000099;
  font-size: 14px;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.Other_Say .slider_main h4.text p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}

.Other_Say .slider_main h5.shubham {
  text-align: center;
  font-size: 20px;
  color: #000000;
}

.Other_Say .swiper-pagination-bullet {
  height: 10px;
  border: 0.5px solid #2eb0d1;
  border-radius: 100%;
  display: inline-block;
  background: white;
  width: 10px;
  opacity: 3;
}

.Other_Say .slider_main .swiper-pagination-bullet-active {
  background-color: #2eb0d1;
}

.Other_Say .slider_main img.quate {
  width: 13px;
  position: absolute;
  top: 0px;
  margin-left: -3%;
}

.Other_Say .slider_main img.quate2 {
  position: absolute;
  width: 12px;
}

.Other_Say .slider_main .swiper-button-prev,
.swiper-button-next {
  color: transparent;
}

.shadow {
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1.7;
  filter: blur(22px);
  margin: -35px 0px 0px 0px;
}

.Other_Say .slider_main {
  margin: 165px 0px 0px 0px;
}

img.other-img {
  /* height: 196px; */
  width: 100%;
}

.other_image {
  bottom: 28px;
  position: relative;
}

.quatation quote {
  width: 60vw;
  padding: 17px 32px;
  position: relative;
}

.quatation quote::before {
  position: absolute;
  content: "“";
  font-family: "Noto Sans JP", sans-serif;
  font-size: 60px;
  color: gray;
  top: 5px;
  left: 16px;
}

.quatation quote::after {
  position: absolute;
  content: "”";
  font-family: "Noto Sans JP", sans-serif;
  font-size: 60px;
  color: gray;
  bottom: -18px;
  right: 16px;
}

/* ******************************* */

/* media query */

@media (min-width: 280px) and (max-width: 320px) {
  .Other_Say .slider_main .device {
    width: 99%;
    left: 0px;
    padding: 28px 19px;
    height: 370px;
  }

  .Other_Say .slider_main img.quate {
    margin-left: -7%;
  }

  .Other_Say .slider_main h4.text {
    font-size: 13px;
  }

  .Other_Say .slider_main h5.shubham {
    font-size: 15px;
  }

  .Other_Say .heading-holder .rooms {
    font-size: 13px;
  }

  .Other_Say .heading-holder h1.book {
    font-size: 46px;
  }

  .Other_Say .heading-holder h1.hostel {
    margin-top: 34px;
    font-size: 23px;
  }

  .Other_Say .slider_main {
    margin-top: 30%;
  }

  .shadow {
    background: none;
    margin: 0px 0px 0px 0px;
  }

  .Other_Say .heading-holder {
    top: 64px;
  }

  section.Other_Say {
    margin-top: 4%;
  }

  img.other-img {
    display: none;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Other_Say .slider_main .device {
    width: 99%;
    height: 330px;
    left: 0px;
  }

  .Other_Say .slider_main {
    margin-top: 34%;
  }

  .Other_Say .heading-holder .rooms {
    font-size: 14px;
  }

  .Other_Say .heading-holder h1.book {
    font-size: 55px;
  }

  .Other_Say .heading-holder h1.hostel {
    margin-top: 8%;
    font-size: 28px;
  }

  .Other_Say .slider_main h5.shubham {

    font-size: 16px;

  }

  .Other_Say .background_img {
    background-image: none;
    height: 330px;
  }

  section.Other_Say {
    margin-top: 4%;
  }

  .shadow {
    background: none;
  }

  img.other-img {
    display: none;
  }

  .facilities img.fix-editor {
    height: inherit;
    width: 100%;
  }

  .Other_Say .slider_main h4.text {
    line-height: 34px;
    font-size: 14px;
    margin-top: 11px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Other_Say .slider_main .device {
    width: 99%;

    left: 0px;
  }

  .Other_Say .heading-holder .rooms {
    font-size: 14px;
  }

  .Other_Say .heading-holder h1.hostel {
    margin-top: 8%;
    font-size: 33px;
    letter-spacing: 0px;
  }

  section.Other_Say {
    margin-top: 4%;
  }

  .Other_Say .slider_main {
    margin-top: 27%;
  }

  .Other_Say .background_img {
    height: 270px;
    transform: perspective(100px) rotatex(0deg);
  }

  .shadow {
    background: none;
  }

  .Other_Say .slider_main h5.shubham {

    font-size: 17px;

  }

  img.other-img {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Other_Say .heading-holder h1.hostel {
    margin-top: 6%;
    font-size: 34px;
  }

  .Other_Say .slider_main {
    margin-top: 24%;
  }

  .Other_Say .slider_main .device {
    width: 99%;
  }

  .Other_Say .slider_main h4.text {
    line-height: 30px;
    font-size: 15px;
  }

  .Other_Say .slider_main h5.shubham {
    font-size: 18px;
  }

  section.Other_Say {
    margin-top: 4%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Other_Say .heading-holder h1.hostel {
    margin-top: 5%;
    font-size: 32px;
  }

  .Other_Say .slider_main {
    margin-top: 19%;
  }

  .BlogFeed {
    margin-top: 5%;
  }

  section.Other_Say {
    margin-top: 4%;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .Other_Say .heading-holder h1.hostel {
    margin-top: 3%;
    font-size: 39px;
  }

  .background_img {
    /* margin: 45% auto 0px auto; */
    width: 75%;
    height: auto;
  }

  section.Other_Say {
    margin-top: 4%;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .Other_Say .slider_main {
    margin: 180px 0 0;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .Other_Say .slider_main {
    margin: 176px 0 0;
  }
}

@media (min-width: 1600px) and (max-width: 1880px) {
  section.Other_Say {
    margin-top: 4%;
  }
}

@media (min-width: 2000px) and (max-width: 2690px) {
  .Other_Say .slider_main {
    margin: 238px 0 0;
  }

  .Other_Say .heading-holder .rooms {
    color: #000000;
    font-size: 24px;
  }

  .Other_Say .heading-holder h1.book {
    font-size: 95px;
    letter-spacing: 8.23px;
  }

  .Other_Say .heading-holder h1.hostel {
    font-size: 65px;
    margin-top: 62px;
  }

  section.Other_Say {
    margin-top: 4%;
  }

  .Other_Say .slider_main .device {
    height: 314px;
    width: 893px;
  }

  .Other_Say .slider_main img.img-fluid {
    width: 100px;
    height: 100px;
  }

  .Other_Say .slider_main h5.shubham {
    font-size: 26px;
  }

  .Other_Say .slider_main .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 24px;
    bottom: var(--swiper-pagination-bottom, 23px);
  }

  .BlogFeed .swiper-pagination-bullet {
    height: 23px;
    width: 23px;
  }

  .Other_Say .slider_main h4.text {
    font-size: 22px;
    margin-top: 29px;
  }
}