.Team {
  background-image: url('../../../../public/assets/Images/AboutUs/Banner/team.png');
  height: 660px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 5%;
  box-shadow: 0 8px 13px -6px #b3b3b3, 0 -1px 10px 1px #b3b3b3;
}

.Team .content {
  padding: 51px 0px 0px 0px;
}

.Team img.yash {
  width: 100%;
  height: 330px;
  transition: transform 0.3s ease;
}

.Team img.rahul {
  height: 330px;
  transition: transform 0.3s ease;
}

.Team img.women {
  height: 330px;
}

.Team .content p.our {
  font-size: 17px;
  color: #000000;
}

.Team .content h1.meet {
  font-weight: 600;
  font-size: 40px;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.Team head p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.Team h5.sean {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.Team h5.head {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

/* .Team img.yash:hover {
  transform: perspective(419px) rotateY(6deg);
  box-shadow: 0px 0px 9px #00000080;
} */

.Team .swiper-button-prev,
.Team .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 10px);
  right: auto;
  display: none;
}

img.yash:hover {
  transform: perspective(419px) rotateY(6deg);
  box-shadow: 0px 0px 9px #00000080;
}

.Team .swiper-slide.swiper-slide-next img.yash:hover {
  transform: perspective(419px) rotateY(-6deg);
  box-shadow: 0px 0px 9px #00000080;
}

.Team .swiper-slide.swiper-slide-active img.yash:hover {
  transform: none;
  box-shadow: none;
}

.Team img.rahul:hover {
  transform: perspective(419px) rotateY(-6deg);
  box-shadow: 0px 0px 9px #00000080;
}

.Team .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  padding: 0px 9px;
}

.team-member {
  margin-top: 5%;
}

.Team .team-member .main {
  text-align: center;
}

.Team .swiper-pagination {
  display: none !important;
}

/* Team Css started */




/* Media Query Started */

@media (min-width: 0px) and (max-width: 320px) {
  .Team {
    height: auto;
  }

  .Team .content h1.meet {
    font-size: 25px;
  }

  .Team .content {
    padding: 10px 0px 0px 0px;
  }

  .main {
    text-align: center;
  }

  .Team img.yash {
    height: 260px;
    width: 80%;
  }

  .Team img.rahul {
    height: auto;
    width: 80%;
  }

  .Team img.women {
    height: auto;
    width: 80%;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Team {
    height: auto;
  }

  .Team .content h1.meet {
    font-size: 25px;
  }

  .main {
    text-align: center;
  }

  .Team .content {
    padding: 10px 0px 0px 0px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Team {
    height: auto;
  }

  .main {
    text-align: center;
  }

  .Team .content {
    padding: 10px 0px 0px 0px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Team {
    height: auto;
  }

  .main {
    text-align: center;
  }

  .Team .content {
    padding: 10px 0px 0px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main {
    text-align: center;
  }

  .Team .content {
    padding: 12px 0px 0px 0px;
  }

  .Team {
    height: auto;
  }

  .Team img.yash {
    width: 100%;

  }
}

/* Media Query end */