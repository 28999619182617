.facilities {
  padding-bottom: 78px;
  background-image: url("/public/assets/Images/facilities/facilities.png");
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 10px 6px -6px #b3b3b3;
}

.facilities .heading-holder .overlay {
  position: absolute;
  top: -5%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.facilities .heading-holder {
  top: 99px;
  text-align: center;
  position: relative;
}

.facilities .heading-holder .rooms {
  font-family: "Poppins", "sans-serif" !important;
  font-size: 16px;
  color: black;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.facilities .heading-holder h1.hostel {
  margin-top: 48px;
  font-size: 40px;
  color: black;
  font-family: "Poppins", "sans-serif" !important;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.facilities .heading-holder h1.book {
  font-family: Signatrue Font;
  font-size: 60px;
  color: #b7b7b7;
  opacity: 50%;
  letter-spacing: 7.2px;
}

.facilities p.sailing {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  opacity: 1;
  color: #000000;
  font-size: 20px;
}

.facilities .icons_part {
  margin-top: 9%;
}

.facilities .tea {
  text-align: -webkit-center;
}

.facilities h4.breakfast {
  font-size: 25px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.icon-wrapper {
  border: 1px solid black;
  border-radius: 141px;
  /* background-color: #2EB0D1; */
  text-align: center;
  width: 109px;
  height: 109px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
}

.icon-wrapper .fix-editor {
  width: 72px;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1/1;
  transition: transform 0.5s;
}

.icons_part .icon-wrapper:hover .fix-editor {
  transform: scale(1.2);

}

.icon-wrapper:hover {
  background-color: #2eb0d1;
  border-radius: 141px;
  color: white;
  border: none;
}

/* media Query started */

@media (min-width: 280px) and (max-width: 320px) {
  .facilities .icons_part {
    margin-top: 59%;
  }

  .facilities .heading-holder h1.hostel {
    margin-top: 13%;
    font-size: 29px;
  }

  .facilities p.sailing {
    font-size: 12px;
  }

  .facilities .icon-wrapper .fa {
    font-size: 37px;
  }

  .facilities .icon-wrapper {
    width: 85px;
    height: 85px;
  }

  .fix-editor {
    width: 100%;
    height: auto;
  }

  .facilities h4.breakfast {
    font-size: 14px;
  }

  .icon-wrapper .fix-editor {
    width: 52px;

  }

  .facilities .heading-holder {
    top: 73px;

  }

  /* .facilities img.fix-editor {
    height: auto;
    width: inherit;
  } */
}

@media (min-width: 321px) and (max-width: 485px) {
  .facilities h4.breakfast {
    font-size: 14px;
  }

  .facilities .icons_part {
    margin-top: 37%;
  }

  .facilities .icon-wrapper .fa {
    font-size: 40px;
  }

  .facilities .icon-wrapper {
    width: 90px;
    height: 90px;
  }

  .facilities .heading-holder h1.book {
    font-size: 50px;
  }

  .facilities .heading-holder {
    top: 74px;
  }

  .facilities .heading-holder h1.hostel {
    margin-top: 8%;
    font-size: 27px;
  }

  .facilities p.sailing {
    font-size: 14px;
  }

  .icon-wrapper .fix-editor {
    width: 52px;

  }

  .facilities .heading-holder {
    top: 73px;

  }
}

@media (min-width: 486px) and (max-width: 575px) {

  /* .facilities {
  height: 912px;
} */
  .facilities h4.breakfast {
    font-size: 14px;
  }

  .facilities .icons_part {
    margin-top: 37%;
  }

  .facilities .icon-wrapper .fa {
    font-size: 50px;
  }

  .facilities .icon-wrapper {
    width: 96px;
    height: 96px;
  }

  .facilities .heading-holder h1.hostel {
    margin-top: 8%;
    font-size: 28px;
  }

  .facilities p.sailing {
    font-size: 16px;
  }

  .icon-wrapper .fix-editor {
    width: 52px;

  }

  .facilities .heading-holder {
    top: 73px;

  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .facilities .heading-holder h1.hostel {
    font-size: 30px;
    margin-top: 7%;
  }

  .facilities .icons_part {
    margin-top: 28%;
  }

  .facilities p.sailing {
    font-size: 16px;
  }

  .facilities .icon-wrapper .fa {
    font-size: 40px;
  }

  .facilities .icon-wrapper {
    width: 90px;
    height: 90px;
  }

  .facilities h4.breakfast {
    font-size: 19px;
  }

  .icon-wrapper .fix-editor {
    width: 52px;

  }

  .facilities .heading-holder {
    top: 73px;

  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .facilities .heading-holder h1.hostel {
    margin-top: 5%;
    font-size: 32px;
  }

  .facilities p.sailing {
    font-size: 17px;
  }

  .facilities .icons_part {
    margin-top: 16%;
  }

  .facilities h4.breakfast {
    font-size: 20px;
  }

  .facilities .heading-holder {
    top: 73px;

  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .facilities .icons_part {
    margin-top: 14%;
  }

  .facilities .heading-holder h1.hostel {
    margin-top: 4%;
    font-size: 34px;
  }

  .facilities p.sailing {
    font-size: 18px;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .facilities .icons_part {
    margin-top: 11%;
  }

  .facilities .heading-holder h1.hostel {
    margin-top: 3%;
    font-size: 36px;
  }
}

@media (min-width: 2400px) and (max-width: 2690px) {
  .facilities .heading-holder .rooms {
    font-size: 24px;
  }

  .facilities .heading-holder h1.book {
    font-size: 88px;
  }

  .facilities .heading-holder h1.hostel {
    letter-spacing: 2.2px;
    margin-top: 2%;
    font-size: 71px;
  }

  .facilities p.sailing {
    font-size: 28px;
  }

  .icon-wrapper {

    width: 125px;
    height: 125px;

  }

  .facilities .icon-wrapper .fa {
    margin-left: 23px;
    margin-top: 22px;
    font-size: 77px;
  }

}