/* text-map-footer css start */


.main .footer-section .text-map-footer .box {
    box-shadow: 0px 0px 7px #00000047;
}

.main .footer-section .text-map-footer .box .text-holder {
    margin: 6% 0%;
}

.main .footer-section .text-map-footer .box .text-holder h1 {
    font-size: 40px;
    font-weight: 600;
    color: #000000;
    margin: 0px;
}

.main .footer-section .text-map-footer .box .text-holder .underline {
    background-color: #000000;
    bottom: 0;
    display: block;
    height: 1px;
    position: relative;
    width: 180px;
    margin: 0px auto;
}

.main .footer-section .text-map-footer .box .text-holder .info .dashed {
    bottom: 0px;
    display: block;
    border-bottom: 2px dashed #000000;
    height: 1px;
    position: relative;
    width: 50px;
    margin: 0px auto;
}

.main .footer-section .text-map-footer .box .text-holder .info h6 {
    font-size: 20px;
    font-weight: 600;
    color: #2eb0d1;
    margin: 0px;
}

.main .footer-section .text-map-footer .box .text-holder .info p {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    margin-top: 3%;
}

.main .footer-section .text-map-footer .box .text-holder .info {
    margin-top: 10%;
}

/* text-map-footer css end */

/* footer-map css start */

.main .footer-section .text-map-footer .map-section .mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: 481px;
}

.main .footer-section .text-map-footer .map-section .gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 481px;
}

.main .footer-section .text-map-footer .map-section .gmap_iframe {
    height: 481px !important;
    width: 100%;
}

/* footer-map css end */


/* bottom footer css start */
.main .footer-section .text-map-footer .bottom-footer {
    background-color: #2eb0d1;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    padding: 1% 0%;
    margin-top: 3%;
}

/* bottom footer css end */
@media (min-width:280px) and (max-width:320px) {
    .blog-page .complete-sec .blog-icons {
        margin-bottom: -10%;
    }
}

@media (min-width:321px) and (max-width:485px) {
    .blog-page .complete-sec .blog-icons {
        margin-bottom: -8%;
    }
}

@media (min-width:486px) and (max-width:575px) {
    .blog-page .complete-sec .blog-icons {
        margin-bottom: -6%;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .blog-page .complete-sec .blog-icons {
        margin-bottom: -5%;
    }
}

@media (min-width:768px) and (max-width:991px) {}

@media (min-width:1601px) and (max-width:2685px) {
    .main .footer-section .text-map-footer .box .text-holder {
        margin-top: 8%;
    }

    .main .footer-section .text-map-footer .box .text-holder .info {
        margin-top: 7%;
    }
}

@media (min-width:2000px) and (max-width:2685px) {
    .main .footer-section .text-map-footer .box .text-holder h1 {
        font-size: 65px;
    }

    .main .footer-section .text-map-footer .box .text-holder .info h6 {
        font-size: 35px;
        margin-bottom: 10px;
    }

    .main .footer-section .text-map-footer .box .text-holder .info p {
        font-size: 25px;
    }

    .main .footer-section .text-map-footer .map-section .gmap_iframe {
        height: 800px !important;
    }

    .main .footer-section .text-map-footer .map-section .gmap_canvas {
        height: 800px;
    }

    .main .footer-section .text-map-footer .map-section .mapouter {
        height: 800px;
    }

    .main .footer-section .text-map-footer .box .text-holder .info {
        margin-top: 10%;
    }

    .main .footer-section .text-map-footer .box .text-holder {
        margin: 6% 0%;
    }

    .main .footer-section .text-map-footer .bottom-footer {
        font-size: 30px;
    }

    .main .footer-section .text-map-footer .box .text-holder .underline {
        width: 300px;
    }

    .main .footer-section .text-map-footer .box .text-holder .info .dashed {
        width: 90px;
    }

    section.footer-section {
        margin-top: 4%;
    }
}

@media (min-width:768px) and (max-width:992px) {

    /* section.footer-section {
margin-top: 4%;
} */
    .main .footer-section .text-map-footer .box .text-holder .info {
        margin-top: 4%;
    }

    .main .footer-section .text-map-footer .box .text-holder {
        margin: 4% 0%;
    }
}

@media (min-width:993px) and (max-width:1199px) {
    .main .footer-section .text-map-footer .box .text-holder {
        margin: 17% 0%;
    }
}

@media (min-width:1200px) and (max-width:1300px) {
    .main .footer-section .text-map-footer .box .text-holder {
        margin: 14% 0%;
    }
}

@media (min-width:1301px) and (max-width:1400px) {
    .main .footer-section .text-map-footer .box .text-holder {
        margin: 11% 0%;
    }
}

@media (min-width:1500px) and (max-width:1800px) {}

@media (min-width:0px) and (max-width:767px) {
    .map-section {
        padding: 0px 0px 0px 12px;
    }

    .main .footer-section .text-map-footer .box .text-holder .info {
        margin: 5% 0%;
    }
}

@media (min-width:0px) and (max-width:992px) {
    .main .footer-section .text-map-footer .bottom-footer {
        font-size: 12px;
    }

    .main .footer-section .text-map-footer .box .text-holder h1 {
        font-size: 20px;
    }

    .main .footer-section .text-map-footer .box .text-holder .info h6 {
        font-size: 16px;
    }

    .main .footer-section .text-map-footer .box .text-holder .info p {
        font-size: 11px;
        margin-top: 2%;
    }

    .main .footer-section .text-map-footer .box .text-holder {
        margin: 4% 0%;
    }


}

@media (min-width: 2400px) and (max-width: 2690px) {
    section.footer-section {
        margin-top: 4%;
    }
}