@font-face {
  font-family: Signatrue Font;
  src: url("/public/assets/Images/HomePage/Signatrue.ttf");
}

.main .banner .text-holder {
  /* height: 412px; */
  width: 100%;
  height: 483px;
  top: 0;
  z-index: 2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main .banner .text-holder h1 {
  font-family: "Signatrue Font";
  font-size: 60px;
  letter-spacing: 8.32px;
  color: #ffffff;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-weight: bold;
  font-size: 31px;
  color: white;
}

/* media Query Started */

@media (min-width: 0px) and (max-width: 320px) {
  .main .banner .bg-img .banner-img {
    height: 150px;
  }

  .main .banner {
    height: 200px;
  }

  .main .banner .text-holder h1 {
    font-size: 20px;
  }

  .main .banner .text-holder {
    height: 100%;
    width: 100%;
    /* top: 48px; */
    font-size: 25px;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main .banner .text-holder {
    height: 300px;
  }

  .main .banner {
    height: 300px;
  }

  .main .banner .text-holder h1 {
    font-size: 30px;
  }
}

@media (min-width: 2000px) and (max-width: 2685px) {
  .main .banner {
    height: 680px;
  }

  .main .banner .text-holder {
    height: 680px;
  }

  .main .banner .text-holder h1 {
    font-size: 90px;
  }
}

/* media Query end */