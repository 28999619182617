@font-face {
    font-family: Signatrue Font;
    src: url("/public/assets/Images/HomePage/Signatrue.ttf");
}

.main .banner {
    position: relative;
    background: #000000;
}

.main .banner .bg-img .banner-img {
    width: 100%;
    height: 539px;
    object-fit: cover;
}

.main .banner .overley {
    position: absolute;
    z-index: 1;
    background-image: linear-gradient(46deg,
            rgb(0 0 0 / 50%) 111%,
            #00000073 -65%);
    width: 100%;
    height: 539px;
    top: 0;
    object-fit: cover;
}

.main .banner .text-holder {
    width: 100%;
    height: 483px;
    top: 18px;
    z-index: 2;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main .banner .text-holder h1 {
    font-family: "Signatrue Font";
    font-size: 60px;
    letter-spacing: 8.32px;
    color: #ffffff;
}


/* Media Query Started */



@media (min-width:0px) and (max-width:320px) {

    .main .banner .text-holder h1 {
        font-size: 20px;
    }

    .main .banner .text-holder {
        height: 150px;
        font-size: 25px;
        text-align: center;
    }

    .main .banner .bg-img .banner-img {
        height: 150px;
    }

    .main .banner .text-holder h1 {
        font-size: 25px;
        text-align: center;
    }


    .main .banner .overley {
        height: 150px;
    }



    .main .banner {
        height: 150px;
    }
}

@media (min-width:321px) and (max-width:485px) {
    .main .banner .bg-img .banner-img {
        height: 200px;
    }

    .main .banner .text-holder h1 {
        font-size: 25px;
        text-align: center;
    }


    .main .banner .overley {
        height: 200px;
    }

    .main .banner .text-holder {
        height: 200px;
    }

    .main .banner {
        height: 200px;
    }
}

@media (min-width:486px) and (max-width:575px) {

    .main .banner .bg-img .banner-img {
        height: 200px;
    }

    .main .banner .text-holder h1 {
        font-size: 30px;

    }

    .main .banner .overley {
        height: 200px;
    }

    .main .banner .text-holder {
        height: 200px;
    }

    .main .banner {
        height: 200px;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .main .banner .bg-img .banner-img {
        height: 300px;
    }

    .main .banner .text-holder h1 {
        font-size: 30px;

    }

    .main .banner .overley {
        height: 300px;
    }

    .main .banner .text-holder {
        height: 300px;
    }

    .main .banner {
        height: 300px;
    }

}

@media (min-width:768px) and (max-width:991px) {
    .main .banner {
        height: 335px;
    }

    .main .banner .bg-img .banner-img {
        height: 335px;
    }

    .main .banner .text-holder h1 {
        font-size: 30px;

    }

    .main .banner .overley {
        height: 335px;
    }

    .main .banner .text-holder {
        height: 335px;
    }


}

@media (min-width:992px) and (max-width:1199px) {}

@media (min-width: 2000px) and (max-width: 2685px) {
    .main .banner .bg-img .banner-img {
        height: 680px;
    }

    .main .banner .text-holder {
        height: 680px;
    }

    .main .banner .overley {
        height: 680px;
    }
}


/* @media (min-width: 0px) and (max-width: 768px) {
    .main .banner .text-holder h1 {
      font-size: 25px;
      text-align: center;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .main .banner .text-holder h1 {
      font-size: 30px;
    }
  }
  
  @media (min-width: 2000px) and (max-width: 2685px) {
    .main .banner .text-holder h1 {
      font-size: 90px;
    }
  } */