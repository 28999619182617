.About-Room {
  margin-top: 4%;
}

.About-Room .main-content {
  padding: 69px 0px 0px 35px;
}

.About-Room .main-content .welcome {
  color: #000;
  font-size: 20px;
  font-family: "Poppins", "sans-serif" !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.About-Room .main-content .finest {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  font-family: "Poppins", "sans-serif" !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.About-Room .main-content p.sitting {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #000;
  display: -webkit-box;
  font-size: 18px;
  font-weight: 400;
  /* line-height: 28px; */
  overflow: hidden;
}

.About-Room .main-content button.button-read {
  background-color: initial;
  border: none;
  color: #000;
  font-size: 19px;
  font-weight: 400;
  margin: -7px 1px;
  /* margin-bottom: 21px; */
}

.About-Room .image-sketch img.sketch {
  width: 100%;
  height: 316px;
}

.About-Room .image-1 img.image-one {
  width: 100%;
  height: 520px;
  object-fit: cover;
}

.About-Room .image-2 img.young {
  width: 100%;
  height: 520px;
}

.About-Room .image-1 {
  box-shadow: 0px 0px 9px #00000080;
}

.About-Room .image-2 {
  margin-top: -17%;
  box-shadow: 0px 0px 9px #00000080;
}

.About-Room .image-sketch .image-sketch {
  padding: 35px 0px 0px 96px;
}

.About-Room .image-sketch {
  padding: 10% 0px 0px 0px;
  position: relative;
}

.About-Room img.img-fluid {
  width: 23px;
  height: 11px;
}

.About-Room .main-content .description-div p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 4;
}

/* icons css */
.icons2 {
  z-index: 2;
  bottom: 24%;
  position: fixed;
  left: 2%;
}

.icons2 img.img-fluid {
  width: 30px;
  height: 30px;
}

.icons2 .vl {
  border-left: 1px solid #ee9723;
  height: 27px;
  position: absolute;
  left: 50%;
  margin-left: 0px;
  top: 104%;
}

.icons2 .follow {
  top: 72px;
  position: relative;
}

.icons2 .follow .us {
  font-weight: bold;
  font-size: 16px;
  padding: 5px 0px 0px 0px;
  text-align: center;
  left: -30px;
  transform: rotate(-90deg);
  width: 92px;
  height: 32px;
  background-color: white;
  position: absolute;
}

#container {
  margin-left: 3%;
  top: -70%;
  position: relative;
}

#circle {
  border-radius: 18px;
  position: relative;
  width: 121px;
  padding-bottom: 121px;
  overflow: hidden;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 8px;
}

#circle text {
  font-family: "Helvetica Neue", Arial;
}

#circle svg {
  position: absolute;
  left: -35px;
  top: -35px;
  width: 160%;
  height: 160%;
  font-size: 54px;
  color: black;
  animation: rotate 10s infinite linear;
  /* Combine animation properties */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Media Query started */

@media (min-width: 0px) and (max-width: 320px) {
  .About-Room .main-content {
    padding: 19px 13px 0px 13px;
  }

  .About-Room .main-content .welcome {
    font-size: 16px;
  }

  .About-Room .main-content .finest {
    font-size: 23px;
  }

  .About-Room .main-content p.sitting {
    text-align: justify;
    font-size: 14px;
  }

  .About-Room .main-content button.button-read {
    font-size: 14px;
  }

  .About-Room .image-2 {
    margin-top: 10%;
    margin-bottom: 5%;
  }

  .About-Room .image-2 img.young {
    height: 250px;
    width: 100%;
  }

  #container {
    margin-left: 0% !important;
    top: 5% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icons2 {
    bottom: 58%;
  }

  .About-Room .image-1 img.image-one {
    width: 100%;
    height: 250px;
  }

  .About-Room .image-sketch img.sketch {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .About-Room .main-content {
    padding: 13px 17px 0px 17px;
  }


  .About-Room .main-content button.button-read {
    font-size: 15px;
  }

  .About-Room .image-1 img.image-one {
    width: 100%;
    height: 300px;
  }

  .About-Room .main-content .welcome {
    font-size: 17px;
  }

  .About-Room .main-content .finest {
    font-size: 28px;
  }

  .About-Room .main-content p.sitting {
    text-align: justify;
    line-height: 26px;
    font-size: 15px;
  }

  .About-Room .image-2 {
    margin-top: 10%;
    margin-bottom: 5%;
  }

  .About-Room .image-2 img.young {
    width: 100%;
    height: 300px;
  }

  #container {
    margin-left: 0% !important;
    top: 5% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icons2 {
    bottom: 58%;
  }

  .About-Room .image-sketch img.sketch {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .About-Room .main-content {
    padding: 16px 20px 0px 20px;
  }

  .About-Room .image-1 img.image-one {
    width: 100%;
    height: 390px;
  }

  .About-Room .main-content .finest {
    font-size: 32px;
  }

  .About-Room .main-content p.sitting {
    text-align: justify;
    line-height: 29px;
    font-size: 15px;
  }

  .About-Room .image-2 {
    margin-top: 0%;
    margin-bottom: 5%;
  }

  .About-Room .image-2 img.young {
    height: 390px;
    width: 100%;
  }

  #circle {
    width: 106px !important;
    padding-bottom: 97px !important;
  }

  #circle svg {
    left: -33px !important;
    top: -31% !important;
  }

  .icons2 {
    bottom: 58%;
  }

  .About-Room .image-sketch img.sketch {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .About-Room .main-content {
    padding: 22px 20px 0px 20px;
  }

  .About-Room .main-content .finest {
    font-size: 35px;
  }

  .About-Room .main-content p.sitting {
    text-align: justify;
    font-size: 16px;
  }

  .About-Room .image-2 {
    margin-top: 0%;
    margin-bottom: 5%;
  }

  .About-Room .image-2 img.young {
    width: 536px;
    height: 468px;
  }

  #circle {
    width: 113px !important;
    padding-bottom: 92px !important;
  }

  #circle svg {
    left: -33px !important;
    top: -31% !important;
  }

  .icons2 {
    bottom: 58%;
  }

  .About-Room .image-sketch img.sketch {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .About-Room .main-content .finest {
    font-size: 27px;
  }

  .About-Room .image-1 img.image-one {
    width: 700px;
    height: 468px;
  }

  .About-Room .main-content p.sitting {
    text-align: justify;
    font-size: 15px;
  }

  .About-Room .image-2 {
    margin-top: 0%;
    margin-bottom: 5%;
  }

  .About-Room .image-2 img.young {
    width: 700px;
    height: 468px;
  }

  #container {
    margin-left: 6% !important;
  }

  .icons2 {
    bottom: 45%;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .About-Room .image-1 img.image-one {
    height: 390px;
    width: 450px;
  }

  .About-Room .main-content {
    padding: 48px 0px 0px 18px;
  }

  .About-Room .main-content .finest {
    font-size: 32px;
  }

  .About-Room .main-content p.sitting {
    text-align: justify;
    font-size: 15px;
  }

  .About-Room .image-2 img.young {
    height: 430px;
    width: 100%;
  }

  #circle {
    width: 92px !important;
    padding-bottom: 92px !important;
  }

  #circle svg {
    left: -27px !important;
    top: -27px !important;
  }

}

@media (min-width: 1025px) and (max-width: 1199px) {
  .About-Room .image-1 img.image-one {
    height: 400px;
  }

  .About-Room .main-content {
    padding: 41px 0px 0px 24px;
  }

  .About-Room .main-content .finest {
    font-size: 33px;
  }

  .About-Room .main-content p.sitting {
    text-align: justify;
    font-size: 15px;
  }

  .About-Room .image-2 img.young {
    height: 424px;
  }

  #circle {
    width: 92px !important;
    padding-bottom: 92px !important;
  }

  #circle svg {
    left: -29px !important;
    top: -30% !important;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .About-Room .main-content {
    padding: 41px 0px 0px 21px;
  }

  .About-Room .main-content .finest {
    font-size: 34px;
  }

  .About-Room .main-content p.sitting {
    text-align: justify;
    font-size: 16px;
  }

  .About-Room .image-2 img.young {
    height: 488px;
  }

}

@media (min-width: 1301px) and (max-width: 1399px) {
  .About-Room .image-2 {
    margin-top: -11%;
  }

  #container {
    top: -68% !important;
  }

  .About-Room .main-content {
    padding: 35px 0px 0px 35px;
  }
}

@media (min-width: 2000px) and (max-width: 26900px) {
  .icons2 img.img-fluid {
    width: 50px;
    height: 50px;
  }

  .icons2 .follow .us {
    font-size: 19px;
    height: 44px;
    left: -29px;
    padding: 10px 0 0;
    width: 110px;
  }
}