@font-face {
  font-family: Signatrue Font;
  src: url("/public/assets/Images/HomePage/Signatrue.ttf");
}

.Our-room-type {
  margin-top: 5%;
  margin-bottom: 0%;
}

.Our-room-type .blog-scroll {
  height: 465px;
  overflow-y: scroll;
  padding: 0px 12px 0px 21px;
}

.Our-room-type .blog-scroll::-webkit-scrollbar {
  display: none;
}

.Our-room-type .text-holder {
  position: relative;
  padding: 2% 10%;
  margin-bottom: 5%;
}

.Our-room-type .text-holder span {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  position: absolute;
  top: 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.Our-room-type .text-holder h1 {
  font-size: 40px;
  font-weight: 600;
  color: #000000;
  position: absolute;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  bottom: -25%;
}

.Our-room-type .text-holder h2 {
  font-size: 52px;
  font-weight: normal;
  font-family: Signatrue Font;
  letter-spacing: 8.32px;
  color: #000000;
  opacity: 0.15;
}

.Our-room-type .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #000000;
  background-color: var(--bs-border-color);
  border-radius: 0px;
  padding: 0px;
}


.Our-room-type .nav-pills .nav-link:hover {
  color: #000000;
  background-color: var(--bs-border-color);
  border-radius: 0px;
  padding: 0px;
}

.Our-room-type .nav-pills .nav-link:hover .card-title {
  color: #2EB0D1;
}



.Our-room-type .nav-pills .nav-link {
  padding: 0px;
}

.Our-room-type #v-pills-tabContent {
  text-align: end;
  right: 0;
}

.Our-room-type .card {
  background: none;
  border: none;
  padding: 12px;
  padding-left: 35px;
  /* height: 140px; */
}

.Our-room-type .card .card-body {
  text-align: left;
  padding: 22px 0px;
  padding-left: 50px;
  padding-right: 0px;
}

.Our-room-type .card img {
  border: 1px solid var(--bs-gray-500);
}

.Our-room-type .card .card-body .card-title {
  font-size: 25px;
  font-weight: medium;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.Our-room-type button.nav-link {
  margin-top: 3%;
}

.Our-room-type .nav-pills .nav-link.active .card-title {
  color: #2eb0d1;
}

.Our-room-type .card .card-body .card-text {
  font-size: 16px;
  font-weight: 500;
  color: #00000099;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.Our-room-type .card .card-body .card-text span {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.Our-room-type .tab-pane .img-holder img {
  height: 600px;
  width: 1125px;
  object-fit: cover;
}

.Our-room-type .back_img_holder img {
  height: 713px;
  width: 53%;
}

.Our-room-type .back_img_holder {
  position: relative;
  text-align: right;
  top: -50px;
}

.Our-room-type .tab-content {
  position: absolute;
  top: 0px;
  padding: 0px 0px 0px 10px;
}

.Our-room-type .change-img-content {
  position: relative;
}

.Our-room-type .blog-scroll .img-fluid {
  width: 100%;
  height: 128px;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Our-room-type .tab-pane .img-holder img {
    height: auto;
    width: 100%;
  }

  .Our-room-type .blog-scroll .img-fluid {
    max-width: 100%;
    height: 99px;
  }

  .Our-room-type .back_img_holder {
    display: none;
  }

  .Our-room-type .tab-content {
    position: inherit;
    margin: 16px 0px;
    padding: 0px 4px;
  }

  .Our-room-type .blog-scroll {
    height: 200px;
    padding-right: 0%;
  }

  .Our-room-type .text-holder h1 {
    font-size: 25px;
    bottom: 0;
  }

  .Our-room-type .card {
    padding: 10px 5px 10px 25px;
  }

  .Our-room-type .card .card-body {
    padding: 2px;
  }

  .Our-room-type .text-holder h2 {
    font-size: 35px;
  }

  .Our-room-type .card .card-body .card-title {
    font-size: 14px;
  }

  .Our-room-type .card .card-body .card-text {
    font-size: 11px;
  }

  .Our-room-type .card .card-body .card-text span {
    font-size: 10px;
  }

  .Our-room-type {
    margin-top: 15%;
  }
}

@media (min-width: 320px) and (max-width: 485px) {
  .Our-room-type .tab-pane .img-holder img {
    height: auto;
    width: 100%;
  }

  .Our-room-type .back_img_holder {
    display: none;
  }

  .Our-room-type .tab-content {
    position: inherit;
    margin: 37px 0px;
    padding: 0px 4px;
  }

  .Our-room-type .blog-scroll {
    height: 340px;
    padding: 0;
  }

  .Our-room-type .text-holder h1 {
    font-size: 25px;
    bottom: -15px;
  }

  .Our-room-type .card {
    padding: 12px 23px;
  }

  .Our-room-type .card .card-body {
    padding: 8px 13px;
  }

  .Our-room-type .text-holder h2 {
    font-size: 35px;
  }

  .Our-room-type .card .card-body .card-title {
    font-size: 18px;
  }

  .Our-room-type .card .card-body .card-text {
    font-size: 14px;
  }

  .Our-room-type .card .card-body .card-text span {
    font-size: 14px;
  }

  .Our-room-type .blog-scroll .img-fluid {
    max-width: 100%;
    height: 99px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Our-room-type .tab-pane .img-holder img {
    height: auto;
    width: 100%;
  }

  .Our-room-type .back_img_holder {
    display: none;
  }

  .Our-room-type .tab-content {
    position: inherit;
    margin: 5px 0px;
    padding: 14px 3px;
  }

  .Our-room-type .blog-scroll {
    height: 375px;
    padding-right: 0%;
  }

  .Our-room-type {
    margin-top: 12%;
  }

  .Our-room-type .text-holder h1 {
    font-size: 25px;
    bottom: -13px;
  }

  .Our-room-type .card {
    padding: 14px 27px;
  }

  .Our-room-type .card .card-body {
    padding: 26px 22px;
  }

  .Our-room-type .text-holder h2 {
    font-size: 35px;
  }

  .Our-room-type .card .card-body .card-title {
    font-size: 20px;
  }

  .Our-room-type .card .card-body .card-text {
    font-size: 14px;
  }

  .Our-room-type .card .card-body .card-text span {
    font-size: 14px;
  }

  .Our-room-type .blog-scroll .img-fluid {
    max-width: 100%;
    height: 139px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Our-room-type .tab-pane .img-holder img {
    height: auto;
    width: 100%;
  }

  .Our-room-type .blog-scroll .img-fluid {
    width: 100%;
    height: 100px;
  }

  .Our-room-type .back_img_holder {
    display: none;
  }

  .Our-room-type .tab-content {
    position: inherit;
    margin: 30px 0px;
    padding: 14px 4px;
  }

  .Our-room-type .blog-scroll {
    height: 350px;
    padding-right: 0%;
  }

  .Our-room-type .text-holder h1 {
    font-size: 25px;
    bottom: -11px;
  }

  .Our-room-type .card {
    padding: 15px 34px;
  }

  .Our-room-type .card .card-body {
    padding: 16px 28px;
  }

  .Our-room-type .text-holder h2 {
    font-size: 35px;
  }

  .Our-room-type .card .card-body .card-title {
    font-size: 22px;
  }

  .Our-room-type .card .card-body .card-text {
    font-size: 15px;
  }

  .Our-room-type .card .card-body .card-text span {
    font-size: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Our-room-type .tab-pane .img-holder img {
    height: auto;
    width: 100%;
  }

  .Our-room-type .back_img_holder {
    display: none;
  }

  .Our-room-type .tab-content {
    margin: 62px 0;
    padding: 24px 4px;
    position: inherit;
  }

  .Our-room-type .blog-scroll {
    height: 280px;
    padding-right: 0;
  }



  .Our-room-type .text-holder h1 {
    font-size: 25px;
    bottom: 0;
  }

  .Our-room-type .card {
    padding: 15px 25px;

  }

  .Our-room-type .card .card-body {
    padding: 12px 24px;
  }

  .Our-room-type .text-holder h2 {
    font-size: 35px;
  }

  .Our-room-type .card .card-body .card-title {
    font-size: 20px;
  }

  .Our-room-type .card .card-body .card-text {
    font-size: 15px;
  }

  .Our-room-type .card .card-body .card-text span {
    font-size: 15px;
  }

  .Our-room-type .blog-scroll .img-fluid {
    width: 100%;
    height: 108px;
  }
}

@media (min-width:992px) and (max-width: 1199px) {
  .Our-room-type .tab-pane .img-holder img {
    height: 419px;
    width: 1125px;
  }

  .Our-room-type .back_img_holder img {
    height: 524px;
    width: 73%;
  }

  .Plan-Your-Next-Staycation {
    margin-top: -7%;
  }

  .Our-room-type .back_img_holder img {
    height: 520px;
    width: 65%;
  }

  .Our-room-type .back_img_holder {
    top: -50px;
    right: -10px;
  }

  .Our-room-type .tab-content {
    bottom: 100px;
  }

  .Our-room-type .card {
    padding: 12px 38px;

  }

  .Our-room-type .card .card-body .card-title {
    font-size: 18px;
    margin: 0px;
  }

  .Our-room-type .card .card-body .card-text {
    font-size: 14px;
  }

  .Our-room-type .card .card-body .card-text span {
    font-size: 12px;
  }

  .Our-room-type .card .card-body {
    padding: 12px 23px;
  }

  .Our-room-type .blog-scroll {
    height: 300px;
  }
}

@media (min-width: 1200px) and (max-width:1399px) {
  .Our-room-type .tab-pane .img-holder img {
    /* height: 400px; */
    /* width: 100%; */
  }

  .Our-room-type .back_img_holder img {
    /* height: 505px; */
    width: 64%;
  }

  .Our-room-type .back_img_holder {
    top: -50px;
    right: -11px;
  }

  .Our-room-type .tab-content {
    bottom: 100px;
  }

  .Our-room-type .card .card-body .card-title {
    /* font-size: 17px; */
    margin: 0px;
  }

  .Our-room-type .card .card-body .card-text,
  .Our-room-type .card .card-body .card-text span {
    font-size: 15px;
  }

  .Our-room-type .card .card-body .card-text span {
    font-size: 12px;
  }

  .Our-room-type .card .card-body {
    padding: 28px 20px;
  }

  .Our-room-type .blog-scroll {
    /* height: 300px; */
  }

  .Plan-Your-Next-Staycation {
    margin-top: -5%;
  }
}

/* @media (min-width: 1025px) and (max-width: 1199px) {
  .Our-room-type .tab-pane .img-holder img {
    height: auto;
    width: 100%;
  }

  .Our-room-type .back_img_holder img {
    width: 71%;
    height: 685px;
  }

  .Our-room-type .back_img_holder {
    right: -12px;
    top: -50px;
  }
  .Our-room-type .tab-content {
    bottom: 100px;
  }

  .Our-room-type .tab-pane .img-holder img {
    height: 562px;
    width: 100%;
  }

  .Our-room-type .card .card-body .card-title {
    font-size: 20px;
    margin: 0px;
  }

  .Our-room-type .card .card-body .card-text {
    font-size: 15px;
  }

  .Our-room-type .card .card-body .card-text span {
    font-size: 15px;
  }
  .Our-room-type .card {
    padding: 12px;
    padding-left: 0px;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .Our-room-type .tab-pane .img-holder img {
    height: 680px;
    width: 100%;
  }
  .Our-room-type .card .card-body {
  padding: 27px 0px;
  padding-left: 23px;
   
}

  .Our-room-type .card {
    padding: 10px;
    padding-left: 12px;
  }

  .Our-room-type .back_img_holder {
    right: -12px;
    top: -50px;
  }

  .Our-room-type .tab-content {
    bottom: 100px;
  }

  .Our-room-type .tab-pane .img-holder img {
    height: 562px;
    width: 100%;
  }

  .Our-room-type .card .card-body .card-title {
    font-size: 20px;
    margin: 0px;
  }

  .Our-room-type .card .card-body .card-text {
    font-size: 15px;
  }

  .Our-room-type .card .card-body .card-text span {
    font-size: 15px;
  }

  .Our-room-type .back_img_holder img {
    height: 670px;
    width: 56%;
  }
}
@media (min-width: 1301px) and (max-width: 1400px) {
  .Our-room-type .back_img_holder img {
    height: 698px;
    width: 53%;
  }
  .Our-room-type .back_img_holder {
    top: -50px;
    right: -11px;
  }
  .Our-room-type .card .card-body {
 
    padding: 19px 0px;
    padding-left: 50px;
  
}
.Our-room-type .card {
 
  padding: 11px 0px;
  padding-left: 8px;
}
}
@media (min-width: 1401px) and (max-width: 1600px) {
  .Our-room-type .back_img_holder {
    right: -12px;
  }
  .Our-room-type .card .card-body {
   padding: 19px 0px;
  }
  .Our-room-type .card {
    background: none;
    border: none;
    padding: 14px 0px;
    padding-left: 11px;
}
.Our-room-type .card {
  padding: 14px 0px;
  padding-left: 11px;
}
} */
@media (min-width: 2600px) and (max-width: 2685px) {
  .Our-room-type .tab-pane .img-holder img {
    height: 911px;
    width: 1522px;
  }

  .Our-room-type .card .card-body {
    padding: 10px 0px;
    padding-left: 50px;
    padding-right: 0px;
  }

  .Our-room-type .blog-scroll {
    height: 676px;
  }

  .Our-room-type .card .card-body .card-title {
    font-size: 35px;
  }

  .Our-room-type .card .card-body .card-text span {
    font-size: 20px;
  }

  .Our-room-type .card .card-body .card-text {
    font-size: 20px;
  }

  .Our-room-type .text-holder span {
    font-size: 25px;
  }

  .Our-room-type .text-holder h1 {
    font-size: 70px;
    bottom: -35%;
  }

  .Our-room-type .text-holder h2 {
    font-size: 90px;
  }

  .Our-room-type .back_img_holder img {
    height: 1071px;
  }

  .Our-room-type .card {
    padding-left: 50px;
  }

  /* .Our-room-type .card .card-body {
    padding: 42px 0px;
    padding-left: 50px;
    padding-right: 0px;
  } */
}