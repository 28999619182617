.ReadMore .main-content .welcome {
  color: #000;
  font-size: 20px;
  font-family: "Poppins", "sans-serif" !important;
}

.ReadMore .main-content .finest {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  font-family: "Poppins", "sans-serif" !important;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1; */
}

.ReadMore .main-content .sitting {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: justify;
  /* overflow: scroll;
  height: 695px;
  min-height: 193px; */
}

.ReadMore {
  position: relative;
}

/* .ReadMore .main-content {
  position: absolute;
} */
.ReadMore .main-content .back-button {
  text-align: end;
}

.ReadMore .main-content img.img-fluid {
  width: 30px;
}

.ReadMore .main-content button.btn.btn-back {
  border: 1px solid #707070;
  font-size: 14px;
  border-radius: 0px;
  color: #000000;
}

.ReadMore .main-content button.btn.btn-back:hover {
  color: black;
}

.ReadMore {
  margin-top: 5%;
}

/* icons3 css */
.icons3 {
  z-index: 2;
  bottom: 24%;
  position: fixed;
  left: 2%;
}

.icons3 img.img-fluid {
  width: 30px;
  height: 30px;
}

.icons3 .vl {
  border-left: 1px solid #ee9723;
  height: 27px;
  position: absolute;
  left: 50%;
  margin-left: 0px;
  top: 104%;
}

.icons3 .follow {
  top: 72px;
  position: relative;
}

.icons3 .follow .us {
  font-weight: bold;
  font-size: 16px;
  padding: 5px 0px 0px 0px;
  text-align: center;
  left: -30px;
  transform: rotate(-90deg);
  width: 92px;
  height: 32px;
  background-color: white;
  position: absolute;
}

/* circle */
#circle2 {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  z-index: -1;
  /* position: absolute; */
  width: 50% !important;
  overflow: hidden;
  margin: auto;
  /* bottom: -19%; */
}

#circle2 text {
  font-family: "Helvetica Neue", Arial;
}

#circle2 svg {
  position: relative;
  top: 35%;
  width: 100%;
  height: 100%;
  color: black;
  animation-name: rotate;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
    /* Start from 0 degrees */
  }

  to {
    transform: rotate(360deg);
    /* Rotate to 360 degrees (clockwise) */
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  .ReadMore .main-content .sitting {
    text-align: justify;
    font-size: 13px;
  }

  #circle2 {
    display: none;
  }

  .ReadMore .main-content .finest {
    font-size: 17px;
  }

  .ReadMore .main-content .welcome {
    font-size: 16px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .ReadMore .main-content .welcome {
    font-size: 14px;
  }

  .ReadMore .main-content .finest {
    font-size: 18px;
  }

  .ReadMore .main-content .sitting {
    text-align: justify;
    font-size: 14px;
  }

  #circle2 {
    display: none;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .ReadMore .main-content .welcome {
    font-size: 14px;
  }

  .ReadMore .main-content .finest {
    font-size: 18px;
  }

  .ReadMore .main-content .sitting {
    text-align: justify;
    font-size: 14px;
  }

  #circle2 {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .ReadMore .main-content .welcome {
    font-size: 15px;
  }

  .ReadMore .main-content .finest {
    font-size: 18px;
  }

  .ReadMore .main-content .sitting {
    text-align: justify;
    font-size: 14px;
  }

  #circle2 {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #circle2 {
    width: 70% !important;
  }

  .ReadMore .main-content .welcome {
    font-size: 16px;
  }

  .ReadMore .main-content .finest {
    font-size: 18px;
  }

  .ReadMore .main-content .sitting {
    text-align: justify;
    font-size: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .ReadMore .main-content .welcome {
    font-size: 16px;
  }

  .ReadMore .main-content .finest {
    font-size: 18px;
  }

  .ReadMore .main-content .sitting {
    text-align: justify;
    font-size: 16px;
  }

  #circle2 {
    width: 70% !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .ReadMore .main-content .welcome {
    font-size: 16px;
  }

  .ReadMore .main-content .finest {
    font-size: 20px;
  }

  .ReadMore .main-content .sitting {
    text-align: justify;
    font-size: 16px;
  }

  #circle2 {
    width: 70% !important;
  }
}

/* media Query started */