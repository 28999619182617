.RoomsDetailsSlider {
  margin: 0% 10% 5% 10%;
}

.RoomsDetailsSlider .main-slider .slider-img {
  width: 100%;
  height: 326px;
  transition: 0.3s;
}

.RoomsDetailsSlider .main-slider .img-block {
  overflow: hidden;
}

.RoomsDetailsSlider .main-slider .slider-img:hover {
  transform: scale(1.1);
}

.RoomsDetailsSlider .main-slider .mySwiper {
  padding-bottom: 50px;
  margin-top: 3%;
}

.RoomsDetailsSlider .main-slider .mySwiper .swiper-pagination-bullet {
  margin: 0px 1px;
  width: 32px;
  height: 7px;
  border-radius: 5px;
}

.RoomsDetailsSlider .main-slider .back-next-btn {
  text-align: center;
  margin-top: 2%;
}

.RoomsDetailsSlider .main-slider .mySwiper .swiper-pagination-bullet-active {
  background-color: #2eb0d1;
  opacity: 1;
}

.RoomsDetailsSlider .main-slider .swiper-pagination-bullet {
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: 0.5;
}

.RoomsDetailsSlider .main-slider .back-next-btn .btn.btn-primary {
  width: 15%;
  background: #2eb0d1;
  border-radius: 0px 15px;
  border: navajowhite;
  padding: 10px 0px;
  margin-right: 3%;
}




@media (min-width:280px) and (max-width:320px) {
  .RoomsDetailsSlider .main-slider .back-next-btn .btn.btn-primary {
    width: 100%;
    margin-bottom: 5%;
  }

  .RoomsDetailsSlider .main-slider .slider-img {
    height: 200px;
}
}

@media (min-width:320px) and (max-width:480px) {
  .RoomsDetailsSlider .main-slider .back-next-btn .btn.btn-primary {
    width: 80%;
    margin-bottom: 5%;
  }

  .RoomsDetailsSlider .main-slider .slider-img {
    height: 250px;
}
}

@media (min-width:480px) and (max-width:575px) {
  .RoomsDetailsSlider .main-slider .back-next-btn .btn.btn-primary {
    width: 40%;
    margin-bottom: 5%;
  }
}

@media (min-width:575px) and (max-width:767px) {
  .RoomsDetailsSlider .main-slider .back-next-btn .btn.btn-primary {
    width: 40%;
    margin-bottom: 5%;
  }

  .RoomsDetailsSlider .main-slider .slider-img {
    height: 250px;
}
}

@media (min-width:767px) and (max-width:991px) {
  .RoomsDetailsSlider .main-slider .back-next-btn .btn.btn-primary {
    width: 40%;
    margin-bottom: 5%;
  }
  .RoomsDetailsSlider .main-slider .slider-img {
    height: 200px;
}
}

@media (min-width:991px) and (max-width:1024px) {
  .RoomsDetailsSlider .main-slider .back-next-btn .btn.btn-primary {
    width: 40%;
    margin-bottom: 5%;
  }

 
}

@media (min-width:1024px) and (max-width:1199px) {
  .RoomsDetailsSlider .main-slider .back-next-btn .btn.btn-primary {
    width: 30%;
    margin-bottom: 5%;
  }
}

@media (min-width:2000px) and (max-width:2685px) {
  .RoomsDetailsSlider .main-slider .back-next-btn .btn.btn-primary {
    width: 10%;
  }

  .RoomsDetailsSlider .main-slider .slider-img {
    height: 500px;
}
}