.form {
  padding: 3% 0% 5% 0%;
}

.form .form-content .text h3 {
  font-weight: bold;
  font-size: 25px;
}

.form .form-content .text p {
  font-size: 14px;
}

.form .form-content .text .para-two {
  font-size: 16px;
}

.form .btn {
  border: none !important;
}

.form .form-content .form-control {
  border-radius: 0;
  font-size: 14px;
  /* width: 98%; */
}

.form .form-content .form-control:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.form-check-input:checked {
  background-color: grey;
  border-color: grey;
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.form .form-content {
  width: 100%;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}


.form .date_img {
  width: 15px;
  height: 15px;
}

.form .form-content label {
  font-weight: bold;
  font-size: 16px;
}

.form .form-content .custum-btn {
  background: #2EB0D1 0% 0% no-repeat padding-box;
  border-radius: 0px 10px;
  background-color: #2eb0d1;
  /* border-radius: 0 7px 0 7px; */
  justify-content: center;
  margin: auto;
  text-align: center;
  width: 100px;
}

.form .all-content .DatePick input {
  width: 100% !important;
  border: 1px solid #ced4da;
  padding: 8px;
  font-size: 13px;
  color: #00000080;
}

.form .form-content .all-content .input-group {
  flex-wrap: unset;
  width: 100%;
  height: 38px;
}

.form .form-content .all-content .input-group .input-group-text {
  border-radius: 0px;
  padding: 10px;
}

.form .form-content .all-content {
  display: flex;
}

.form-select:focus {
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.rounds {
  width: 0.5em;
  height: 0.5em;
}

.rounds:checked {
  background-color: black;

}

p.text-danger {
  font-size: 12px;
}

/* input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  
} */


.form .form-check {

  padding-left: 15px;

}

.form .form-content .all-content .date-sec {
  margin: 8px 0px;
}

.form .form-content .all-content .checkbox {
  margin: 8px;
}

.form-select {
  color: #818589;
  font-size: 14px;
  border-radius: 0px;
}






@media screen and (min-width: 280px) and (max-width: 320px) {
  .form .form-content .all-content {
    display: inline;
  }

  .form .form-content .text h3 {
    font-size: 20px;
  }

  label.form-label.email-sec {
    padding-top: 7px;
  }

  .form {
    padding: 4% 0% 5% 0%;
  }

  .form .form-content .all-content .date-sec {
    margin: 8px;
  }

  .form .form-content .all-content .checkbox {
    margin: 8px 20px;
  }
}

@media screen and (min-width: 321px) and (max-width: 485px) {
  .form .form-content .all-content {
    display: inline;
  }

  .form {
    padding: 4% 0% 5% 0%;
  }

  .form .form-content .text h3 {
    font-size: 20px;
  }

  .form .form-content label {
    font-size: 14px;
  }

  label.form-label.email-sec {
    padding-top: 7px;
  }

  .form .form-content .all-content .date-sec {
    margin: 8px;
  }

  .form .form-content .all-content .checkbox {
    margin: 8px 20px;
  }




}

@media screen and (min-width: 486px) and (max-width: 576px) {

  .form .form-content .all-content {
    display: inline;
  }


  .form .form-content .text h3 {
    font-size: 20px;
  }

  .form .form-content label {
    font-size: 14px;
  }


  .form .all-content .DatePick input {
    padding-right: 80px;
  }

  label.form-label.email-sec {
    padding-top: 7px;
  }

  .form .form-content .all-content .date-sec {
    margin: 8px;
  }

  .form .form-content .all-content .checkbox {
    margin: 8px 20px;
  }

  .form {
    padding: 4% 0% 5% 0%;
  }
}



@media screen and (min-width: 577px) and (max-width: 768px) {

  .form .form-content label {
    font-size: 14px;
  }

  .form .form-content .text h3 {
    font-size: 20px;
  }

  .form {
    padding: 4% 0% 5% 0%;
  }


}

@media screen and (min-width: 768px) and (max-width: 992px) {

  .form .form-content label {
    font-size: 14px;
  }

  .form {
    padding: 5% 0% 5% 0%;
  }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
  /* lg */

}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  /* xl */

}