.main .gallery .text-holder .all-circles .circles {
  display: flex;
  align-items: center;
}

.main .gallery .text-holder .all-circles .circles .circle {
  width: 2px;
  height: 2px;
  background-color: #707070;
  border-radius: 50%;
  margin: 0 2px 2px 0;
}

.main .gallery .text-holder {
  padding-top: 20%;
}

.main .gallery .gallery-main-slider .img-holder {
  padding-top: 7%;
}

.main .gallery .text-holder .heading p {
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: 500;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.main .gallery .text-holder .heading h1 {
  font-size: 40px;
  font-weight: 600;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.main .gallery .gallery-main-slider {
  position: relative;
}

.main .gallery .gallery-main-slider .background-img .bg {
  position: absolute;
  right: 0;
  z-index: -1;
  padding-top: 3%;
}

.main .gallery .gallery-main-slider .bg-img {
  width: 335px;
  height: 575px;
}

/* .main .gallery .gallery-main-slider .img-holder .main-img {
  width: 100%;
  height: auto;
} */

.main .gallery .gallery-main-slider .swiper .slider-img {
  width: 100%;
  height: auto;
  aspect-ratio: 2/2;
}

.main .gallery .gallery-main-slider .swiper {
  margin-top: 3px;
}

.main .gallery .gallery-main-slider .swiper-button-prev {
  position: absolute;
  left: 0;
  top: 60%;
  background: #bd9007;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.main .gallery .gallery-main-slider .swiper-button-prev:after {
  font-weight: 600;
  color: #0c0c0c !important;
  font-size: 10px !important;
}

.main .gallery .gallery-main-slider .swiper-button-next {
  background: #bd9007;
  border-radius: 50%;
  height: 20px;
  position: absolute;
  right: 0;
  top: 60%;
  width: 20px;
}

.main .gallery .gallery-main-slider .swiper-button-next:after {
  font-weight: 600;
  color: #0c0c0c !important;
  font-size: 10px !important;
}

.main .gallery {
  position: relative;
}

.main .gallery .show-full-img .close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
}

.main .gallery .show-full-img .close svg {
  overflow: visible;
  box-sizing: content-box;
  transition: 0.1s;
  text-align: center;
  font-size: 20px;
}

.main .gallery .show-full-img {
  display: none;
  position: absolute;
  top: 0%;
  right: 0;
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.main .gallery .show-full-img.active {
  display: flex;
}

.main .gallery .show-full-img .close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #ffffff;
}

.main .gallery .full-main-img {
  width: 100%;
  height: 100%;
}

/* icons */
.icons-five {
  z-index: 2;
  bottom: 24%;
  position: fixed;
  left: 2%;
}

.icons-five img.img-fluid {
  width: 35px;
  height: 35px;
}

.icons-five .vl {
  border-left: 1px solid #ee9723;
  height: 27px;
  position: absolute;
  left: 50%;
  margin-left: 0px;
  top: 104%;
}

.icons-five .follow {
  top: 72px;
  position: relative;
}

.icons-five .follow .us {
  font-weight: bold;
  font-size: 16px;
  padding: 5px 0px 0px 0px;
  text-align: center;
  left: -30px;
  transform: rotate(-90deg);
  width: 92px;
  height: 32px;
  background-color: white;
  position: absolute;
}

@media (min-width: 280px) and (max-width: 320px) {
  .main .gallery .text-holder .heading h1 {
    font-size: 20px;
  }

  .main .gallery .text-holder .heading p {
    font-size: 10px;
    margin-bottom: 0px;
  }

  .main .gallery .text-holder {
    padding-top: 5%;
  }

  .main .gallery .gallery-main-slider .bg-img {
    display: none;
  }

  .main .gallery .gallery-main-slider .img-holder .main-img {
    width: 100%;
    height: 140px;
  }

  .main .gallery .gallery-main-slider .swiper .slider-img {
    width: 100%;
    height: 50px;
  }

  .main .gallery .gallery-main-slider .swiper-button-prev {
    top: 70%;
    height: 20px;
    width: 20px;
  }

  .main .gallery .gallery-main-slider .swiper-button-prev:after {
    font-size: 10px !important;
  }

  .main .gallery .gallery-main-slider .swiper-button-next {
    top: 70%;
    height: 20px;
    width: 20px;
  }

  .main .gallery .gallery-main-slider .swiper-button-next:after {
    font-size: 10px !important;
  }

  .heading {
    text-align: start;
  }

  .icons-five {
    bottom: 67%;
  }

  .icons-five img.img-fluid {
    height: 24px;
    width: 24px;
  }

  .main .gallery .full-main-img {
    width: 100%;
    height: 385px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .main .gallery .gallery-main-slider .bg-img {
    display: none;
  }

  .main .gallery .text-holder .heading h1 {
    font-size: 25px;
  }

  .main .gallery .text-holder {
    padding-top: 5%;
  }

  .main .gallery .gallery-main-slider .img-holder .main-img {
    width: 100%;
    height: 200px;
  }

  .main .gallery .gallery-main-slider .swiper-button-prev {
    top: 70%;
    height: 20px;
    width: 20px;
  }

  .main .gallery .gallery-main-slider .swiper-button-prev:after {
    font-size: 10px !important;
  }

  .main .gallery .gallery-main-slider .swiper-button-next {
    top: 70%;
    height: 20px;
    width: 20px;
  }

  .main .gallery .gallery-main-slider .swiper-button-next:after {
    font-size: 10px !important;
  }

  .main .gallery .gallery-main-slider .swiper .slider-img {
    width: 100%;
    height: 60px;
  }

  .icons_5 {
    bottom: 56%;
  }

  .heading {
    text-align: start;
  }

  .main .gallery .text-holder .heading p {
    margin-bottom: 0px;
  }

  .icons-five {
    bottom: 62%;
  }

  .icons-five img.img-fluid {
    height: 24px;
    width: 24px;
  }

  .main .gallery .full-main-img {
    width: 100%;
    height: 484px;
  }
}

@media (min-width: 486px) and (max-width: 574px) {
  .main .gallery .gallery-main-slider .bg-img {
    display: none;
  }

  .main .gallery .text-holder {
    padding-top: 5%;
  }

  .main .gallery .text-holder .heading h1 {
    font-size: 30px;
  }

  .main .gallery .gallery-main-slider .img-holder .main-img {
    width: 100%;
    height: 300px;
  }

  .main .gallery .gallery-main-slider .swiper .slider-img {
    width: 100%;
    height: 100px;
  }

  .icons_5 {
    bottom: 49%;
  }

  .main .gallery .text-holder .heading p {
    margin-bottom: 0px;
  }

  .heading {
    text-align: start;
  }

  .icons-five {
    bottom: 62%;
  }

  .icons-five img.img-fluid {
    height: 24px;
    width: 24px;
  }

  .main .gallery .full-main-img {
    height: 639px;
    width: 100%;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .main .gallery .gallery-main-slider .bg-img {
    display: none;
  }

  .main .gallery .gallery-main-slider .img-holder .main-img {
    width: 100%;
    height: 240px;
  }

  .main .gallery .gallery-main-slider .swiper .slider-img {
    width: 100%;
    height: 80px;
  }

  .main .gallery .text-holder .heading h1 {
    text-align: start;
    font-size: 25px;
  }

  .main .gallery .text-holder .heading p {
    text-align: start;
    font-size: 12px;
    margin-bottom: 0px;
  }

  .icons-five {
    bottom: 62%;
  }

  .icons-five img.img-fluid {
    height: 24px;
    width: 24px;
  }

  .main .gallery .full-main-img {
    width: 100%;
    height: 435px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main .gallery .gallery-main-slider .bg-img {
    display: none;
  }

  .main .gallery .gallery-main-slider .img-holder .main-img {
    width: 100%;
    height: 285px;
  }

  .main .gallery .text-holder .heading h1 {
    font-size: 23px;
  }

  .main .gallery .gallery-main-slider .swiper .slider-img {
    width: 100%;
    height: 80px;
  }

  .icons_5 {
    bottom: 59%;
  }

  .main .gallery .text-holder .heading p {
    margin-bottom: 0px;
    font-size: 13px;
  }

  .heading {
    text-align: start;
  }

  .icons-five {
    bottom: 62%;
  }

  .icons-five img.img-fluid {
    height: 30px;
    width: 30px;
  }

  .main .gallery .full-main-img {
    width: 100%;
    height: 498px;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .main .gallery .gallery-main-slider .bg-img {
    display: none;
  }

  .main .gallery .gallery-main-slider .img-holder .main-img {
    width: 100%;
    height: 395px;
  }

  .main .gallery .gallery-main-slider .swiper .slider-img {
    width: 100%;
    height: 100px;
  }

  .icons-five {
    bottom: 44%;
  }

  .main .gallery .full-main-img {
    width: 100%;
    height: 626px;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .main .gallery .gallery-main-slider .bg-img {
    display: none;
  }

  .main .gallery .gallery-main-slider .img-holder .main-img {
    width: 100%;
    height: 390px;
  }

  .main .gallery .gallery-main-slider .swiper .slider-img {
    width: 100%;
    height: 80px;
  }

  .main .gallery .text-holder .heading p {
    font-size: 15px;
  }

  .main .gallery .text-holder .heading h1 {
    font-size: 31px;
  }

  .icons-five {
    bottom: 44%;
  }

  .main .gallery .full-main-img {
    width: 100%;
    height: 605px;
  }
}


@media (min-width: 1200px) and (max-width: 1399px) {

  .main .gallery .gallery-main-slider .swiper .slider-img {
    width: 100%;
    height: 100px;
  }

  .main .gallery .full-main-img {
    width: 100%;
    height: 645px;
  }

}

@media (min-width: 1400px) and (max-width: 1500px) {
  .main .gallery .full-main-img {
    width: 100%;
    height: 655px;
  }
}

@media (min-width: 1501px) and (max-width: 1600px) {
  .main .gallery .full-main-img {
    width: 100%;
    height: 655px;
  }
}

@media (min-width: 1601px) and (max-width: 1700px) {
  .main .gallery .full-main-img {
    width: 100%;
    height: 655px;
  }
}

@media (min-width: 1701px) and (max-width: 1880px) {
  .main .gallery .full-main-img {
    width: 100%;
    height: 675px;
  }
}

@media (min-width: 1881px) and (max-width: 1898px) {
  .main .gallery .full-main-img {
    width: 100%;
    height: 671px;
  }
}

@media (min-width: 1899px) and (max-width: 2000px) {
  .main .gallery .full-main-img {
    width: 100%;
    height: 670px;
  }
}

@media (min-width: 2001px) and (max-width: 2300px) {
  .main .gallery .full-main-img {
    width: 100%;
    height: 768px;
  }
}

@media (min-width: 2301px) and (max-width: 2685px) {
  .main .gallery .gallery-main-slider .img-holder .main-img {
    height: 720px;
  }

  .main .gallery .gallery-main-slider .swiper .slider-img {
    width: 100%;
    height: 150px;
  }


  .main .gallery .gallery-main-slider .bg-img {
    width: 100%;
    height: 835px;
  }

  .main .gallery .text-holder .heading p {
    font-size: 30px;
  }

  .main .gallery .text-holder .heading h1 {
    font-size: 75px;
  }

  .main .gallery .text-holder .all-circles .circles .circle {
    width: 4px;
    height: 4px;
  }

  .main .gallery .gallery-main-slider .swiper-button-prev {
    top: 50%;
    height: 50px;
    width: 50px;
  }

  .main .gallery .gallery-main-slider .swiper-button-prev:after {
    font-size: 25px !important;
  }

  .main .gallery .gallery-main-slider .swiper-button-next {
    top: 50%;
    height: 50px;
    width: 50px;
  }

  .main .gallery .gallery-main-slider .swiper-button-next:after {
    font-size: 25px !important;
  }

  .main .gallery .full-main-img {
    width: 100%;
    height: 1185px;
  }
}