.contact {
  background-color: black;
  padding: 10px 0px 0px 0px;
  margin: 0px;
  position: relative;
}

.contact .vdo-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact .vdo-section .video-element {
  width: 100%;
  background-color: black;
  opacity: 0.5;
}

.middle-content {
  padding: 0% 20% 3% 20%;
}

.contact .vdo-section .overlay-form {
  position: absolute;
  top: 15%;
  background-color: rgb(46 176 209 / 38%);
  opacity: 0.9;
  text-align: center;
  color: #fff;
  width: 60%;
  padding: 3%;
}

.contact .form-control {
  font-size: 21px;
}

.contact .vdo-section .overlay-form h3 {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 45px;
}

.contact .vdo-section .overlay-form .form-control {
  border-radius: 0px;
}

.contact .form-footer .info h6 {
  font-weight: initial;
  font-size: 20px;
  font-weight: 500;
}

.contact .form-footer .info p {
  font-size: 15px;
  margin-top: 20px;
  font-weight: initial;
}

.contact .form-footer .info .dashed {
  bottom: 0px;
  display: block;
  border-bottom: 3px dashed white;
  height: 1px;
  position: relative;
  width: 50px;
  margin: 0px auto;
}

.contact .overlay-form-two {
  background-color: rgba(46, 176, 209, 0.9);
  opacity: 0.9;
  text-align: center;
  color: #fff;
  width: 100%;
  padding: 3%;
  display: none;
}

.contact button.btn.btn-submit {
  color: white;
  font-size: 20px;
  background-color: rgb(30 178 209);
  border-radius: 0px;
  width: 100%;
}

.footer {
  display: none;
}

.modal .modal-body h1.successfully {
  text-align: center;
  font-size: 20px;
}

.modal .modal-body h1.Thanks {
  font-weight: 700;
  text-align: center;
  font-size: 25px;
}

.modal-body {
  padding: 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media screen and (min-width: 0px) and (max-width: 320px) {
  .overlay-form {
    display: none;
  }

  .contact {
    padding: 0px 0px 0px 0px;
  }

  .contact .overlay-form-two h3 {
    font-size: 25px;
  }

  .middle-content {
    padding: 5% 15%;
  }

  .contact .overlay-form-two .form-control {
    border-radius: 0px;
  }

  .contact .overlay-form-two {
    display: block;
  }

  .contact .form-control {
    font-size: 16px;
  }

  .contact .form-footer .info h6 {
    font-size: 15px;
  }

  .contact button.btn.btn-submit {
    font-size: 17px;
    background-color: rgb(0 120 145);
    width: auto;
  }

  .contact .vdo-section .video-element {
    display: none;
  }
}

@media screen and (min-width: 321px) and (max-width: 486px) {
  .overlay-form {
    display: none;
  }

  .contact {
    padding: 0px 0px 0px 0px;
  }

  .contact button.btn.btn-submit {
    font-size: 16px;
    background-color: rgb(0 120 145);
  }

  .contact .overlay-form-two h3 {
    font-size: 30px;
  }

  .middle-content {
    padding: 5% 15%;
  }

  .contact .overlay-form-two {
    display: block;
  }

  .contact .overlay-form-two .form-control {
    border-radius: 0px;
  }

  .contact .form-control {
    font-size: 16px;
  }

  .contact .form-footer .info h6 {
    font-size: 16px;
  }

  .contact .vdo-section .video-element {
    display: none;
  }
}

@media screen and (min-width: 487px) and (max-width: 576px) {
  .overlay-form {
    display: none;
  }

  .contact {
    padding: 0px 0px 0px 0px;
  }

  .contact .overlay-form-two {
    display: block;
  }

  .contact .overlay-form-two h3 {
    font-size: 30px;
  }

  .middle-content {
    padding: 5% 15%;
  }

  .contact .form-control {
    font-size: 16px;
  }

  .contact .overlay-form-two .form-control {
    border-radius: 0px;
  }

  .contact .form-footer .info h6 {
    font-size: 18px;
  }

  .contact button.btn.btn-submit {
    font-size: 16px;
    background-color: rgb(0 120 145);
  }

  .contact .vdo-section .video-element {
    display: none;
  }
}

@media screen and (min-width: 577px) and (max-width: 769px) {
  .overlay-form {
    display: none;
  }

  .contact {
    padding: 0px 0px 0px 0px;
  }

  .contact .overlay-form-two {
    display: block;
  }

  .contact .overlay-form-two h3 {
    font-size: 40px;
  }

  .middle-content {
    padding: 5% 15%;
  }

  .contact .overlay-form-two .form-control {
    border-radius: 0px;
  }

  .info h6 {
    font-size: 15px;
  }

  .info p {
    font-size: 12px;
  }

  .contact .form-control {
    font-size: 17px;
  }

  .contact button.btn.btn-submit {
    font-size: 18px;
    background-color: rgb(0 120 145);
  }

  .contact .vdo-section .video-element {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .contact .vdo-section .overlay-form h3 {
    margin-bottom: 0px;
    font-size: 30px;
  }

  .contact .vdo-section .overlay-form {
    top: 4%;
  }

  .overlay-form-two {
    display: none;
  }

  .contact .form-footer .info h6 {
    font-size: 15px;
  }

  .contact .form-footer .info p {
    font-size: 12px;
  }

  .contact .form-control {
    font-size: 15px;
  }

  .contact button.btn.btn-submit {
    font-size: 15px;
  }

  .contact .vdo-section .video-element {
    display: none;
  }

  .submit {
    margin-top: 2%;
  }

  .contact button.btn.btn-submit {
    font-size: 18px;
  }

  .overlay-form {
    display: none;
  }

  .contact .overlay-form-two {
    display: block;
  }

  .contact {
    padding: 0px 0px 0px 0px;
  }

  .contact button.btn.btn-submit {
    font-size: 18px;
    background-color: rgb(0 120 145);
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .contact .vdo-section .overlay-form {
    padding: 0px 0px;
    top: 64px;
    width: 70%;
    height: 480px;
  }

  .contact button.btn.btn-submit {
    font-size: 15px;
  }

  .overlay-form-two {
    display: none;
  }

  .contact .form-footer .info h6 {
    font-size: 17px;
  }

  .contact .form-footer .info p {
    font-size: 13px;
  }

  .contact .form-control {
    font-size: 16px;
  }

  .contact .vdo-section .overlay-form h3 {
    font-size: 32px;
  }

  .overlay-form {
    display: block;
  }

  .contact .overlay-form-two {
    display: none;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .overlay-form-two {
    display: none;
  }

  .info h6 {
    font-size: 25px;
  }

  .info p {
    font-size: 20px;
  }

  .contact .vdo-section .overlay-form {
    top: auto;
  }

  .contact .vdo-section .overlay-form h3 {
    font-size: 32px;
  }

  .contact .form-control {
    font-size: 16px;
  }

  .contact button.btn.btn-submit {
    font-size: 16px;
  }

  .contact .form-footer .info h6 {
    font-size: 18px;
  }

  .contact .form-footer .info p {
    font-size: 14px;
  }

  .middle-content {
    padding: 4% 20% 3% 20%;
  }
}

@media screen and (min-width: 1401px) and (max-width: 1800px) {
  .overlay-form-two {
    display: none;
  }

  .contact .vdo-section .overlay-form h3 {
    margin: 0;
    font-size: 45px;
    font-weight: 600;
  }

  .contact .vdo-section .overlay-form .form-control {
    font-size: 20px;
  }

  .contact .vdo-section .overlay-form {
    top: 10%;

    padding: 5%;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1900px) {
  .overlay-form-two {
    display: none;
  }

  .contact .vdo-section .overlay-form h3 {
    margin: 0;
    font-size: 45px;
    font-weight: 600;
  }

  .contact .vdo-section .overlay-form .form-control {
    font-size: 17px;
  }

  .contact .vdo-section .overlay-form {
    top: 15%;

    padding: 5%;
  }
}

@media screen and (min-width: 2400px) and (max-width: 2685px) {
  .contact .vdo-section .overlay-form h3 {
    font-size: 5rem;
  }


  .contact .vdo-section .overlay-form {
    top: 15%;

    padding: 5%;
  }

  .contact .form-footer .info h6 {
    font-size: 2rem;
  }

  .contact .form-footer .info p {
    font-size: 22px;
  }

  .overlay-form-two {
    display: none;
  }

  .contact .form-footer .info .dashed {
    border-bottom: 6px dashed white;

    width: 30%;
  }

  .contact .vdo-section .overlay-form .form-control {
    font-size: 24px;
  }
}