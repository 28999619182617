/* images hover css started */

.Luxury_Section .images-hover {
  text-align: -webkit-center;
  position: relative;
}

.Luxury_Section .images-hover .background {
  height: 740px;
  right: 74px;
  top: -15px;
}

.Luxury_Section .images-hover img.inner-img1 {
  width: 65%;
  height: auto;
  transition: 0.3s;
}

.Luxury_Section .images-hover img.inner-img2 {
  height: 549px;
  transition: 0.3s;
  width: 370px;
}

.Luxury_Section .images-hover .img-wrapper {
  left: 22%;
  overflow: hidden;
  position: absolute;
  top: 17%;
}

.Luxury_Section .images-hover .inner-img1:hover {
  transform: scale(1.1);
}

.Luxury_Section .images-hover .inner-img2:hover {
  transform: scale(1.1);
}

.Luxury_Section .images-hover .img-wrapper1 {
  height: 543px;
  left: 44%;
  overflow: hidden;
  position: absolute;
  top: 7%;
  width: 370px;
}

/* images hover css end */

/* text css started */

.Luxury_Section .heading-holder .overlay {
  position: absolute;
  top: -41%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.Luxury_Section .heading-holder {
  top: 68%;
  text-align: center;
  position: relative;
}

.Luxury_Section .images-hover img.inner-img1 {
  height: 568px;
  transition: 0.3s;
  width: 364px;
  object-fit: cover;
}

.Luxury_Section .heading-holder h1.luxury {
  font-size: 40px;
  font-weight: 600;
  margin-top: 7%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.Luxury_Section .heading-holder h1.about {
  font-family: Signatrue Font;
  font-size: 70px;
  color: #b7b7b7;
  opacity: 50%;
  letter-spacing: 7.1px;
}

.Luxury_Section h4.sitting {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  line-clamp: 5;
  /* text-align: justify; */
  font-weight: 400;
  line-height: 33px;
  text-align: center;
  color: #000000;
  font-size: 18px;
  margin-top: 12%;
  font-weight: 500;
  padding: 0px 68px;
}

.Luxury_Section .about-btn {
  text-align: center;
  margin-top: 4%;
}

.Luxury_Section .about-btn button.btn.btn-primary {
  font-size: 19px;
  border: none;
  background: #2eb0d1 0% 0% no-repeat padding-box;
  width: 30%;
  height: 55px;
  border-radius: 0px;
}

section.Luxury_Section {
  margin-top: -4%;
  padding: 2%;
}

.Luxury_Section p.services {
  font-weight: 500;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

/* media Query started */

@media (min-width: 0px) and (max-width: 320px) {
  .Luxury_Section .images-hover .img-wrapper {
    height: auto;
    left: 9%;
    top: 6%;
    width: 84%;
  }

  .Luxury_Section .images-hover .img-wrapper1 {
    position: absolute;
    left: 18%;
    top: 53%;
    width: 68%;
    height: auto;
    overflow: hidden;
  }

  .Luxury_Section .heading-holder .services {
    font-size: 12px;
  }

  .Luxury_Section .heading-holder h1.luxury {
    /* line-height: 0px; */
    font-size: 21px;
    /* margin-top: 18%; */
  }

  .Luxury_Section .heading-holder h1.about {
    font-size: 51px;
  }

  .Luxury_Section .about-btn button.btn.btn-primary {
    font-size: 14px;
    width: 100%;
    height: 40px;
  }

  .Luxury_Section .heading-holder {
    left: 0;
    top: 45%;
  }

  .Luxury_Section .about-btn {
    margin-left: 0%;
  }

  .Luxury_Section .images-hover .background {
    height: 682px;
    width: 100%;
  }

  .Luxury_Section .images-hover img.inner-img {
    width: 100%;
  }

  .Luxury_Section h4.sitting {
    font-size: 12px;
    margin-left: 0;
    margin-top: 8%;
    padding: 0;
    text-align: justify;
    width: 100%;
  }

  section.Luxury_Section {
    margin-top: 65%;
  }

  .Luxury_Section .images-hover {
    right: 0%;
  }

  .Luxury_Section .images-hover img.inner-img1 {
    width: 80%;
    height: 290px;
  }

  .Luxury_Section .images-hover img.inner-img2 {
    width: 100%;
    height: 290px;
  }
}

@media (min-width: 321px) and (max-width: 390px) {
  .Luxury_Section .images-hover .background {
    position: relative;
    top: 225px;
    right: 0px;
    width: 100%;
  }

  .Luxury_Section .images-hover .img-wrapper {
    left: 0%;
    width: 100%;
    top: 37%;
  }

  .Luxury_Section .heading-holder .services {
    font-size: 12px;
  }

  .Luxury_Section .heading-holder h1.about {
    font-size: 57px;
  }

  .Luxury_Section h4.sitting {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 81%;
    padding: 0 22px;
    text-align: justify;
    width: 100%;
  }

  .Luxury_Section .images-hover .img-wrapper1 {
    left: 0%;
    width: 100%;
    top: 84%;
  }

  .Luxury_Section .heading-holder h1.luxury {
    /* line-height: 24px; */
    font-size: 23px;
  }

  .Luxury_Section .about-btn button.btn.btn-primary {
    font-size: 13px;
    width: 56%;
    height: auto;
  }

  .Luxury_Section .heading-holder {
    top: 331%;
  }

  .Luxury_Section .images-hover img.inner-img {
    margin-top: 12%;
    width: 281px;
  }

  .Luxury_Section .images-hover img.inner-img1 {
    width: 60%;
    height: 290px;
  }

  .Luxury_Section .images-hover img.inner-img2 {
    width: 60%;
    height: 290px;
  }

  section.Luxury_Section {
    margin-top: 3%;
  }

  .Luxury_Section .heading-holder .overlay {
    top: -21%;
  }
}

@media (min-width: 391px) and (max-width: 460px) {
  .Luxury_Section .images-hover .background {
    height: auto;
    position: relative;
    top: 225px;
    right: 0px;
    width: 100%;
  }

  .Luxury_Section .images-hover .img-wrapper {
    left: 0%;
    width: 100%;
    top: 42%;
  }

  .Luxury_Section .images-hover {
    top: 8%;
  }

  .Luxury_Section .heading-holder .services {
    font-size: 12px;
  }

  .Luxury_Section .heading-holder h1.about {
    font-size: 57px;
  }

  .Luxury_Section h4.sitting {
    margin-left: 0%;
    width: 100%;
    font-size: 15px;
    margin-top: 75%;
    padding: 0px 17px;
    text-align: justify;
  }

  .Luxury_Section .images-hover .img-wrapper1 {
    left: 0%;
    width: 100%;
    top: 90%;
  }

  .Luxury_Section .heading-holder h1.luxury {
    /* line-height: 11px; */
    font-size: 29px;
    /* margin-top: 12%; */
  }

  .Luxury_Section .about-btn button.btn.btn-primary {
    font-size: 16px;
    width: 43%;
  }

  .Luxury_Section .heading-holder {
    top: 353%;
  }

  .Luxury_Section .images-hover img.inner-img {
    margin-top: 12%;
    width: 281px;
  }

  .Luxury_Section .images-hover img.inner-img1 {
    width: 50%;
    height: 253px;
  }

  .Luxury_Section .images-hover img.inner-img2 {
    width: 55%;
    height: 257px;
  }

  section.Luxury_Section {
    margin-top: -9%;
  }
}

@media (min-width: 401px) and (max-width: 485px) {
  .Luxury_Section .images-hover .background {
    height: auto;
    position: relative;
    top: 230px;
    right: 0px;
    width: 100%;
  }

  .Luxury_Section .images-hover .img-wrapper {
    left: 0%;
    width: 100%;
    top: 40%;
  }

  .Luxury_Section .heading-holder .services {
    font-size: 12px;
  }

  .Luxury_Section .heading-holder h1.about {
    font-size: 57px;
  }

  .Luxury_Section h4.sitting {
    font-size: 15px;
    margin-left: 0;
    margin-top: 68%;
    padding: 0 17px;
    text-align: justify;
    width: 100%;
  }

  .Luxury_Section .images-hover .img-wrapper1 {
    left: 0%;
    width: 100%;
    top: 89%;
  }

  .Luxury_Section .heading-holder h1.luxury {
    /* line-height: 11px; */
    font-size: 29px;
    /* margin-top: 14%; */
  }

  .Luxury_Section .about-btn button.btn.btn-primary {
    font-size: 16px;
    width: 43%;
    height: auto;
  }

  .Luxury_Section .heading-holder {
    top: 331%;
  }

  .Luxury_Section .images-hover img.inner-img {
    margin-top: 12%;
    width: 281px;
  }

  .Luxury_Section .images-hover img.inner-img1 {
    width: 50%;
    height: 290px;
  }

  .Luxury_Section .images-hover img.inner-img2 {
    width: 50%;
    height: 290px;
  }

  section.Luxury_Section {
    margin-top: -17px;
  }

  .Luxury_Section .images-hover {
    top: 3%;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Luxury_Section .images-hover .img-wrapper1 {
    top: 9%;
    width: 78%;
    left: 24%;
  }

  .Luxury_Section .heading-holder .services {
    font-size: 14px;
  }

  .Luxury_Section .heading-holder h1.about {
    font-size: 55px;
  }

  .Luxury_Section .heading-holder h1.luxury {
    font-size: 25px;
    margin-top: 12%;
  }

  .Luxury_Section h4.sitting {
    font-size: 16px;
    margin-top: -14%;
    padding: 0;
    text-align: center;
  }

  .Luxury_Section .about-btn button.btn.btn-primary {
    width: 46%;
    height: 43px;
    font-size: 16px;
  }

  section.Luxury_Section {
    margin-top: 66%;
  }

  .Luxury_Section .images-hover .background {
    width: 74%;
    top: -187px;
    right: -7px;
    height: 606px;
  }

  .Luxury_Section .images-hover img.inner-img2 {
    width: 73%;
    height: 405px;
  }

  .Luxury_Section .heading-holder {
    top: -172%;
  }

  .Luxury_Section .images-hover {
    padding: 0px 7px 0px 7px;
    top: -21%;
  }

  .Luxury_Section .images-hover img.inner-img1 {
    width: 302px;
    height: 447px;
  }

  .Luxury_Section .images-hover .img-wrapper {
    left: 9%;
    top: 18%;
  }
}

@media (min-width: 576px) and (max-width: 620px) {
  .Luxury_Section .images-hover .img-wrapper {
    left: -9%;
  }

  .Luxury_Section .heading-holder .overlay {
    top: -24%;
  }

  .Luxury_Section .images-hover .img-wrapper1 {
    left: 32%;
  }

  .Luxury_Section .heading-holder .services {
    font-size: 16px;
  }

  .Luxury_Section .heading-holder h1.luxury {
    /* line-height: 0px; */
    font-size: 40px;
    /* margin-top: 13%; */
  }

  .Luxury_Section h4.sitting {
    text-align: center;
    font-size: 17px;
    margin-top: 27%;
    padding: 0px 0px;
  }

  .Luxury_Section .heading-holder h1.luxury {
    /* line-height: 4px; */
    font-size: 25px;
    margin-top: 13%;
  }

  .Luxury_Section .images-hover .img-wrapper {
    left: 10%;
  }

  section.Luxury_Section {
    margin-top: 30%;
  }

  .Luxury_Section .images-hover img.inner-img1 {
    width: 302px;
    height: 478px;
  }

  .Luxury_Section .images-hover img.inner-img2 {
    width: 76%;
    height: 439px;
  }

  .Luxury_Section .images-hover .background {
    height: 625px;
    right: 64px;
  }

  .Luxury_Section .heading-holder {
    top: 130%;
  }

  .Luxury_Section .images-hover {
    top: 15%;
  }

  .Luxury_Section .about-btn button.btn.btn-primary {
    height: 45px;
  }
}

@media (min-width: 621px) and (max-width: 767px) {
  .Luxury_Section .images-hover .img-wrapper {
    left: -9%;
  }

  .Luxury_Section .images-hover {
    top: 10%;
  }

  .Luxury_Section .images-hover .img-wrapper1 {
    left: 39%;
  }

  .Luxury_Section .heading-holder .services {
    font-size: 16px;
  }

  .Luxury_Section h4.sitting {
    text-align: center;
    font-size: 17px;
    margin-top: 13%;
    padding: 0px 0px;
  }

  .Luxury_Section .heading-holder h1.luxury {
    /* line-height: 4px; */
    font-size: 32px;
    /* margin-top: 11%; */
  }

  section.Luxury_Section {
    margin-top: 24%;
  }

  .Luxury_Section .images-hover .img-wrapper {
    left: 18%;
    margin-top: 0%;
  }

  .Luxury_Section .images-hover img.inner-img1 {
    width: 278px;
    height: 472px;
  }

  .Luxury_Section .images-hover img.inner-img2 {
    width: 76%;
    height: 461px;
  }

  .Luxury_Section .images-hover .background {
    height: 591px;
    right: 64px;
  }

  .Luxury_Section .heading-holder {
    top: 77%;
  }

  .Luxury_Section .about-btn button.btn.btn-primary {
    height: 45px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Luxury_Section h4.sitting {
    font-size: 15px;
    line-height: 33px;
    margin-top: 10%;
  }

  section.Luxury_Section {
    margin-top: 13%;
  }

  .Luxury_Section p.services {
    font-size: 21px;
  }

  .Luxury_Section .heading-holder h1.luxury {
    /* line-height: 0px; */
    font-size: 33px;
    /* margin-top: 9%; */
  }

  section.Luxury_Section {
    margin-top: 29%;
  }

  .Luxury_Section .images-hover .img-wrapper {
    left: 11%;
  }

  .Luxury_Section .images-hover .img-wrapper1 {
    left: 39%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .Luxury_Section h4.sitting {
    line-height: 33px;
    margin-top: 8%;
  }

  .Luxury_Section .images-hover .background {
    width: 480px;
  }

  .Luxury_Section .images-hover .img-wrapper {
    left: 22%;
  }

  .Luxury_Section .images-hover .img-wrapper1 {
    left: 43%;
  }

  .Luxury_Section .heading-holder .services {
    font-size: 15px;
  }

  .Luxury_Section .heading-holder h1.about {
    font-size: 66px;
  }

  .Luxury_Section .heading-holder h1.luxury {
    /* line-height: 7px; */
    font-size: 27px;
  }

  .Luxury_Section .heading-holder h1.luxury {
    /* line-height: 1px; */
    font-size: 42px;
    margin-top: 5%;
  }

  section.Luxury_Section {
    margin-top: 0%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .Luxury_Section .heading-holder .services {
    font-size: 16px;
  }

  .Luxury_Section .heading-holder h1.luxury {
    /* line-height: 1px; */
    font-size: 34px;
    /* margin-top: 10%; */
  }

  .Luxury_Section .heading-holder h1.about {
    font-size: 55px;
  }

  .Luxury_Section h4.sitting {
    font-size: 16px;
    margin-top: 14%;
  }

  .Luxury_Section .images-hover .img-wrapper {
    left: 14%;
  }

  .Luxury_Section .images-hover img.inner-img1 {
    width: 300px;
    height: 477px;
  }

  .Luxury_Section .images-hover .img-wrapper1 {
    left: 39%;
  }

  .Luxury_Section .images-hover img.inner-img2 {
    width: 78%;
    height: 467px;
  }

  .Luxury_Section .images-hover .background {
    height: 614px;
  }

  section.Luxury_Section {
    margin-top: -4%;
  }

  .Luxury_Section .about-btn button.btn.btn-primary {
    font-size: 16px;
    width: 30%;
    height: 48px;
  }

  .Luxury_Section .about-btn {
    margin-top: 0%;
  }
}

@media (min-width: 1401px) and (max-width: 1500px) {
  .Luxury_Section .images-hover .img-wrapper {
    left: -8%;
    top: 10%;
  }

  .Luxury_Section .images-hover img.inner-img1 {
    width: 292px;
  }

  .Luxury_Section .images-hover img.inner-img2 {
    width: 87%;
  }

  .Luxury_Section .images-hover img.inner-img1 {
    height: 604px;
    width: 56%;
  }

  .Luxury_Section .heading-holder h1.luxury {
    font-size: 40px;
    /* margin-top: 10%; */
  }

  .Luxury_Section h4.sitting {
    padding: 38px 50px;
  }

  section.Luxury_Section {
    margin-top: -1%;
  }

  section.Luxury_Section {
    margin-top: -3%;
    padding: 2%;
  }
}

@media (min-width: 1501px) and (max-width: 1700px) {
  .Luxury_Section .images-hover .img-wrapper {
    left: -3%;
    top: 10%;
  }

  .Luxury_Section .images-hover img.inner-img1 {
    width: 292px;
  }

  .Luxury_Section .images-hover img.inner-img2 {
    width: 87%;
  }

  .Luxury_Section .images-hover img.inner-img1 {
    height: 604px;
    width: 58%;
  }

  .Luxury_Section .heading-holder h1.luxury {
    font-size: 40px;
    /* margin-top: 10%; */
  }

  .Luxury_Section h4.sitting {
    padding: 35px 50px;
  }

  section.Luxury_Section {
    margin-top: -1%;
  }

  section.Luxury_Section {
    margin-top: -3%;
    padding: 2%;
  }
}

@media (min-width: 2400px) and (max-width: 2685px) {
  .Luxury_Section .images-hover .background {
    width: 44%;
    height: 1002px;
  }

  .Luxury_Section .images-hover img.inner-img1 {
    height: 818px;
    width: 35%;
  }

  .Luxury_Section .images-hover img.inner-img2 {
    height: 866px;
    width: 92%;
  }

  .Luxury_Section .images-hover .img-wrapper1 {
    left: 37%;
    top: 5%;
    width: 45%;
    height: auto;
  }

  .Luxury_Section .images-hover .img-wrapper {
    left: -9%;
    top: 14%;
  }

  .Luxury_Section .heading-holder h1.about {
    font-size: 100px;
  }

  .Luxury_Section .heading-holder h1.luxury {
    font-size: 54px;
  }

  .Luxury_Section h4.sitting {
    font-weight: 400;
    line-height: 49px;
    font-size: 31px;
  }

  .Luxury_Section .about-btn button.btn.btn-primary {
    font-size: 25px;
    width: 31%;
    height: 66px;
  }

  .Luxury_Section p.services {
    font-size: 21px;
  }

  section.Luxury_Section {
    margin-top: -2%;
  }
}