@font-face {
  font-family: Signatrue Font;
  src: url("/public/assets/Images/HomePage/Signatrue.ttf");
}

/* text-holder css start */
.main .Rooms-info {
  margin-top: 12%;
}

.main .Rooms-info .heading-section .text-holder {
  padding: 0% 20%;
}

.main .Rooms-info .heading-section .text-holder h1 {
  font-size: 45px;
  font-family: "Signatrue Font";
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  margin-bottom: 4%;
}

.main .Rooms-info .heading-section .text-holder h3 {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 4%;
}

.main .Rooms-info .heading-section .text-holder p {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

/* text-holder css end */

/* info-table css start */
.main .Rooms-info .info-table {
  position: relative;
  text-align: center;
  margin: 2% 0% 8% 0%;
}

.main .Rooms-info .info-table .side-plat-img .flower-img {
  position: absolute;
  bottom: -145%;
  left: 0%;
}

.main .Rooms-info .info-table .side-plat-img .plan-img {
  width: 320px;
}

.main .Rooms-info .info-table .side-plat-img .flower-img-2 {
  position: absolute;
  bottom: -145%;
  right: 0%;
}

.main .Rooms-info .info-table .side-plat-img .plan-img-2 {
  width: 327px;
}

/* .main .Rooms-info .info-table #box {
  padding: 1% 0%;
} */
.main .Rooms-info .info-table .box-1 {
  border-bottom: 1px solid #80808080;
  border-top: 1px solid #80808080;
  border-right: 1px solid #80808080;
  padding: 2% 0% 1% 10%;
}

.main .Rooms-info .info-table .box-2 {
  border-bottom: 1px solid #80808080;
  border-top: 1px solid #80808080;
  border-right: 1px solid #80808080;
  padding: 2% 0% 0% 0%;
}

.main .Rooms-info .info-table .box-3 {
  border-bottom: 1px solid #80808080;
  border-top: 1px solid #80808080;
  /* border-right: 1px solid #80808080; */
  padding: 2% 0% 0% 0%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

/* .main .Rooms-info .info-table .box-4 {
  border-bottom: 1px solid #80808080;
  border-top: 1px solid #80808080;
  padding: 2% 5% 1% 0%;
} */

.main .Rooms-info .info-table #box .text-holder {
  padding: 0px;
}

.main .Rooms-info .info-table #box .text-holder h6 {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

.main .Rooms-info .info-table #box .text-holder p {
  font-size: 18px;
  font-weight: 500;
  color: #7e7e7e;
}

/* info-table css end */

/* double-room  css start */
.main .Rooms-info .double-room {
  text-align: center;
}

.main .Rooms-info .double-room h2 {
  font-size: 26px;
  font-weight: 600;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.main .Rooms-info .double-room .text-holder {
  text-align: center;
}

.main .Rooms-info .double-room .no-bullets {
  list-style-type: none;
  padding-left: 43%;
}

.main .Rooms-info .double-room .no-bullets ul,
.no-bullets li {
  padding: 0;
  margin: 0;
  display: flex;
}

.main .Rooms-info .double-room span {
  margin-right: 5%;
  font-size: 20px;
  color: #000000;
}

.main .Rooms-info .double-room p {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}


/* double-room  css end */

@media (min-width: 280px) and (max-width: 991px) {
  .main .Rooms-info .info-table .side-plat-img .flower-img {
    display: none;
  }

  .main .Rooms-info .info-table .side-plat-img .flower-img-2 {
    display: none;
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  .main .Rooms-info .heading-section .text-holder {
    padding: 0% 5%;
  }

  .main .Rooms-info {
    margin-top: 20%;
  }

  .main .Rooms-info .heading-section .text-holder h1 {
    font-size: 30px;
    margin-bottom: 10%;
  }

  .main .Rooms-info .heading-section .text-holder h3 {
    font-size: 20px;
  }

  .main .Rooms-info .heading-section .text-holder p {
    font-size: 15px;
  }

  .main .Rooms-info .info-table .box-1 {
    border-bottom: none;
    border-top: 1px solid #80808080;
    border-right: none;
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-2 {
    border-bottom: none;
    border-top: 1px solid #80808080;
    border-right: none;
  }

  .main .Rooms-info .info-table .box-3 {
    border-bottom: 1px solid #80808080;
    border-top: 1px solid #80808080;
    border-right: none;
  }

  .main .Rooms-info .info-table .box-4 {
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table #box .text-holder h6 {
    font-size: 15px;
  }

  .main .Rooms-info .info-table #box .text-holder p {
    font-size: 15px;
  }

  .main .Rooms-info .double-room h2 {
    font-size: 20px;
    margin-bottom: 10%;
  }

  .main .Rooms-info .double-room p {
    font-size: 15px;
    font-weight: 500;
  }

  .main .Rooms-info .double-room span {
    font-size: 15px;
  }

  .main .Rooms-info .double-room .text-holder {
    text-align: center;
  }

  .main .Rooms-info .double-room .no-bullets {
    padding-left: 5%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .main .Rooms-info .heading-section .text-holder {
    padding: 0% 5%;
  }

  .main .Rooms-info {
    margin-top: 10%;
  }

  .main .Rooms-info .heading-section .text-holder h1 {
    font-size: 30px;
    margin-bottom: 5%;
  }

  .main .Rooms-info .heading-section .text-holder h3 {
    font-size: 20px;
  }

  .main .Rooms-info .heading-section .text-holder p {
    font-size: 15px;
  }

  .main .Rooms-info .info-table .box-1 {
    border-bottom: none;
    border-top: 1px solid #80808080;
    border-right: none;
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-2 {
    border-bottom: none;
    border-top: 1px solid #80808080;
    border-right: none;
  }

  .main .Rooms-info .info-table .box-3 {
    border-bottom: 1px solid #80808080;
    border-top: 1px solid #80808080;
    border-right: none;
  }

  .main .Rooms-info .info-table .box-4 {
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table #box .text-holder h6 {
    font-size: 15px;
  }

  .main .Rooms-info .info-table #box .text-holder p {
    font-size: 15px;
  }

  .main .Rooms-info .double-room h2 {
    font-size: 20px;
    margin-bottom: 10%;
  }

  .main .Rooms-info .double-room p {
    font-size: 15px;
    font-weight: 500;
  }

  .main .Rooms-info .double-room span {
    font-size: 15px;
  }

  .main .Rooms-info .double-room .text-holder {
    text-align: center;
  }

  .main .Rooms-info .double-room .no-bullets {
    padding-left: 20%;
  }
}

@media (min-width: 481px) and (max-width: 575px) {
  .main .Rooms-info .heading-section .text-holder {
    padding: 0% 5%;
  }

  .main .Rooms-info {
    margin-top: 10%;
  }

  .main .Rooms-info .heading-section .text-holder h1 {
    font-size: 30px;
    margin-bottom: 5%;
  }

  .main .Rooms-info .heading-section .text-holder h3 {
    font-size: 20px;
  }

  .main .Rooms-info .heading-section .text-holder p {
    font-size: 15px;
  }

  .main .Rooms-info .info-table .box-1 {
    border-bottom: none;
    border-top: 1px solid #80808080;
    border-right:none;
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-4 {
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-2 {
    border-bottom: none;
    border-top: 1px solid #80808080;
    border-right: none;
  }

  .main .Rooms-info .info-table .box-3 {
    border-bottom: 1px solid #80808080;
    border-top: 1px solid #80808080;
    border-right: none;
  }

  .main .Rooms-info .info-table #box .text-holder h6 {
    font-size: 15px;
  }

  .main .Rooms-info .info-table #box .text-holder p {
    font-size: 15px;
  }

  .main .Rooms-info .double-room h2 {
    font-size: 20px;
    margin-bottom: 10%;
  }

  .main .Rooms-info .double-room p {
    font-size: 15px;
    font-weight: 500;
  }

  .main .Rooms-info .double-room span {
    font-size: 15px;
  }

  .main .Rooms-info .double-room .text-holder {
    text-align: center;
  }

  .main .Rooms-info .double-room .no-bullets {
    padding-left: 20%;
  }
}

@media (min-width: 575px) and (max-width: 767px) {

  .RoomsDetailsSlider .main-slider .slider-img {
    height: 250px;
  }


  .main .Rooms-info .heading-section .text-holder {
    padding: 0% 5%;
  }

  .main .Rooms-info {
    margin-top: 10%;
  }

  .main .Rooms-info .heading-section .text-holder h1 {
    font-size: 30px;
    margin-bottom: 5%;
  }

  .main .Rooms-info .heading-section .text-holder h3 {
    font-size: 20px;
  }

  .main .Rooms-info .heading-section .text-holder p {
    font-size: 15px;
  }

  .main .Rooms-info .info-table .box-1 {
    border-bottom: none;
    border-top: 1px solid #80808080;
    border-right: none;
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-2 {
    border-bottom: none;
    border-top: 1px solid #80808080;
    border-right: none;
  }

  .main .Rooms-info .info-table .box-3 {
    border-bottom: 1px solid #80808080;
    border-top: 1px solid #80808080;
    border-right: none;
  }

  .main .Rooms-info .info-table .box-4 {
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table #box .text-holder h6 {
    font-size: 15px;
  }

  .main .Rooms-info .info-table #box .text-holder p {
    font-size: 15px;
  }

  .main .Rooms-info .double-room h2 {
    font-size: 20px;
    margin-bottom: 10%;
  }

  .main .Rooms-info .double-room p {
    font-size: 15px;
    font-weight: 500;
  }

  .main .Rooms-info .double-room span {
    font-size: 15px;
  }

  .main .Rooms-info .double-room .text-holder {
    text-align: center;
  }

  .main .Rooms-info .double-room .no-bullets {
    padding-left: 20%;
  }
}

@media (min-width:767px) and (max-width: 991px) {
  .main .Rooms-info .heading-section .text-holder {
    padding: 0% 5%;
  }

  .main .Rooms-info {
    margin-top: 10%;
  }

  .main .Rooms-info .heading-section .text-holder h1 {
    font-size: 30px;
    margin-bottom: 5%;
  }

  .main .Rooms-info .heading-section .text-holder h3 {
    font-size: 20px;
  }

  .main .Rooms-info .heading-section .text-holder p {
    font-size: 15px;
  }

  .main .Rooms-info .info-table #box .text-holder h6 {
    font-size: 15px;
  }

  .main .Rooms-info .info-table #box .text-holder p {
    font-size: 15px;
  }

  .main .Rooms-info .double-room h2 {
    font-size: 20px;
    margin-bottom: 10%;
  }

  .main .Rooms-info .double-room p {
    font-size: 15px;
    font-weight: 500;
  }

  .main .Rooms-info .double-room span {
    font-size: 15px;
  }

  .main .Rooms-info .info-table .box-1 {
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-2 {
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-3 {
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-4 {
    padding: 1% 0%;
  }

  .main .Rooms-info .double-room .no-bullets {
    padding-left: 18%;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .main .Rooms-info .info-table #box .text-holder p {
    font-size: 16px;
  }

  .main .Rooms-info .heading-section .text-holder p {
    font-size: 15px;
  }

  .main .Rooms-info .info-table .side-plat-img .plan-img {
    width: 195px;
  }

  .main .Rooms-info .info-table .side-plat-img .plan-img-2 {
    width: 195px;
  }

  .main .Rooms-info .info-table .side-plat-img .flower-img {
    bottom: -87%;
  }

  .main .Rooms-info .info-table .side-plat-img .flower-img-2 {
    bottom: -87%;
  }

  .main .Rooms-info .double-room h2 {
    font-size: 19px;
  }

  .main .Rooms-info .double-room p {
    font-size: 14px;
    ;
  }

  .main .Rooms-info .double-room span {
    font-size: 15px;
  }

  .main .Rooms-info .info-table .box-1 {
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-2 {
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-3 {
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-4 {
    padding: 1% 0%;
  }

  .main .Rooms-info .double-room .no-bullets {
    padding-left: 30%;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .main .Rooms-info .info-table .side-plat-img .plan-img {
    width: 240px;
  }

  .main .Rooms-info .info-table .side-plat-img .plan-img-2 {
    width: 240px;
  }

  .main .Rooms-info .info-table .side-plat-img .flower-img {
    bottom: -103%;
  }

  .main .Rooms-info .info-table .side-plat-img .flower-img-2 {
    bottom: -107%;
  }

  .main .Rooms-info .info-table .box-4 {
    padding: 2% 5% 1% 0%;
  }

  .main .Rooms-info .double-room .no-bullets {
    padding-left: 18%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .main .Rooms-info .info-table .side-plat-img .plan-img {
    width: 290px;
  }

  .main .Rooms-info .info-table .side-plat-img .plan-img-2 {
    width: 290px;
  }

  .main .Rooms-info .info-table .side-plat-img .flower-img {
    bottom: -145%;
  }

  .main .Rooms-info .info-table .side-plat-img .flower-img-2 {
    bottom: -145%;
  }

  .main .Rooms-info .info-table .box-1 {
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-2 {
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-3 {
    padding: 1% 0%;
  }

  .main .Rooms-info .info-table .box-4 {
    padding: 1% 0%;
  }

  .main .Rooms-info .double-room .no-bullets {
    padding-left: 30%;
  }
}

@media (min-width: 2000px) and (max-width: 2685px) {
  .main .Rooms-info .info-table .side-plat-img .plan-img {
    width: 420px;
  }

  .main .Rooms-info .info-table .side-plat-img .plan-img-2 {
    width: 420px;
  }

  .main .Rooms-info .info-table {
    margin: 2% 0% 5% 0%;
  }

  .main .Rooms-info {
    margin-top: 3%;
  }
}