.Footer-lottie {
margin: auto;
/* padding: 0px -3px 0px 94px; */
width: 168px;
height: 168px;
}
@media (min-width:0px) and (max-width:320px) {
}
@media (min-width:768px) and (max-width:991px) {
.Footer-lottie {
margin: auto;
width: 147px;
height: 147px;
}
}