.main .header .navbar {
  padding: 0% 1%;
  box-shadow: 0px 0px 9px -2px grey;
}

.main .header .navbar #navbarSupportedContent {
  align-items: flex-start;
}
/* section.header {
    height: 63px;
} */
.main .header .navbar .navbar-brand .hostel-logo {
  width: 190px;
}

.main .header .navbar .navbar-toggler {
  border: none;
  border-radius: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.main .header .navbar .nav-item {
  margin: 0px 18px;
}

.main .header .navbar .nav-link {
  font-size: 15px;
  font-weight: 500;
  color: #707070;
  padding-top: 20px;
  width: 74px;
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
}

.main .header .navbar .nav-item.active .nav-link {
  background: #dee2e62b;
  text-align: start;
  width: 74px;
  text-align: center;
  box-shadow: 0px 0px 5px 0px rgb(221, 216, 216);
}

.main .header .navbar .nav-item.active .nav-link {
  background: #ffffff;
  color: #2eb0d1;
}

.main .header .navbar .nav-item.active:after {
  content: "";
  border-width: 20px 37px 17px;
  border-style: solid;
  border-color: #ffffff #ffffff transparent #ffffff;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  filter: drop-shadow(0px 2px 1px rgb(221, 216, 216));
  top: 40px;
}
.main .header .navbar .nav-link.dropdown-toggle:hover {
  box-shadow: none;
}

.main .header .navbar .nav-item.dropdown:hover {
  box-shadow: none;
}

.main .header .navbar .nav-link:hover {
  width: 74px;
  color: #2eb0d1;
  text-align: center;
  box-shadow: 0px 0px 5px 0px rgb(221, 216, 216);
}

.main .header .navbar .nav-item:hover {
  background: #ffffff;
  color: #2eb0d1;
  box-shadow: 0px 0px 3px -1px rgb(221, 216, 216);
}
.main .header .navbar .nav-item:hover::after {
  content: "";
  border-width: 20px 37px 17px;
  border-style: solid;
  border-color: #ffffff #ffffff transparent #ffffff;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  filter: drop-shadow(0px 2px 1px rgb(221, 216, 216));
  top: 40px;
}

.main .header .navbar .nav-item.dropdown {
  background: none;
}

.main .header .navbar .dropdown-toggle::after {
  display: none;
}

.main .header .navbar .dropdown-toggle button {
  border: none;
  background: none;
  color: var(--bs-gray);
}

.dropdown .show .button {
  -webkit-transform: rotate(180deg);
  display: inline-block;
}

.main .header .navbar .nav-item.dropdown:after {
  display: none;
}

.main .header .navbar .dropdown-menu {
  border-radius: 0px;
  background: #2eb0d1;
  border-bottom-right-radius: 10px;
  top: 55px;
  margin: 0;
  padding: 0px 0px 32px 19px;
}

.main .header .navbar .dropdown-menu .dropdown-item {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  --bs-dropdown-link-hover-bg: none;
  --bs-dropdown-link-active-bg: none;
  border-bottom: 1px solid white;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 0px 2px 0px;
}

.main .header .navbar .dropdown-menu .dropdown-item:hover {
  border-bottom: 1px solid white;
  width: 35px;
}

.main .header .navbar .call svg {
  font-size: 20px;
}

.main .header .navbar .call span {
  font-size: 14px;
  font-weight: 500;
  color: #707070;
}

.main .header .navbar .call {
  margin: 16px 43px 16px 3px;
}

.main .header form {
  border: 11px solid #2eb0d1;
  width: fit-content;
}

.main .header .btn {
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  background: #2eb0d1;
  border-radius: 0px;
  border: 1px dashed #e0e0e0;
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .main .header .navbar .nav-item {
    margin: 0px 6px;
    text-align: center;
  }
  .main .header .navbar .call {
    margin: 19px 29px 0px 0px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .main .header .navbar .nav-item {
    margin: -5px 5px;
    text-align: center;
}

  .main .header .navbar .nav-link {
    font-size: 14px;
  }

  .main .header .navbar .call span {
    font-size: 14px;
  }
  .main .header .navbar .call {
    margin: 15px 32px 0px 3px;
}
  .main .header .btn {
    font-size: 12px;
  }
}

@media (min-width: 0px) and (max-width: 991px) {
  .main .header .navbar .nav-item {
    margin: 0px;
  }

  .main .header .navbar .navbar-brand .hostel-logo {
    width: 120px;
  }

  .main .header form {
    margin-top: 5%;
    border: 3px solid #2eb0d1;
  }

  .main .header .navbar .nav-link {
    text-align: left;
  }
  .main .header .navbar .nav-item.active:after {
    display: none;
  }

  .main .header .navbar .nav-item:hover {
    width: fit-content;
  }

  .main .header .navbar .nav-item:hover::after {
    display: none;
  }

  .main .header .navbar .call {
    margin: 1% 0%;
    text-align: start;
  }
  .main .header .navbar {
    padding: 2% 1%;
}

.main .header .btn {
  font-size: 12px;
}


}
@media (min-width: 992px) and (max-width: 1024px) {
  .main .header .navbar .navbar-brand .hostel-logo {
    width: 129px;
  }
  /* .main .header .navbar .call {
    margin: 2% 0%;
  } */
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .main .header .navbar .call {
    margin: 2% 9px;
  }
  .main .header .navbar .call {
    /* font-size: 11px; */
    margin: 19px 25px 0px 0px;
}
.main .header .navbar .call span {
  font-size: 12px;
}
.main .header .navbar .nav-link {
  font-size: 14px;
}
}
@media (min-width: 1201px) and (max-width: 1900px) {
  .main .header .navbar .nav-link {
    padding-top: 20px !important;
  }
  .main .header .navbar .call {
    margin: 19px 44px 0px 0px;
  }
}

@media (min-width: 2000px) and (max-width: 2690px) {
  .main .header .navbar .navbar-brand .hostel-logo {
    width: 250px;
  }
  .main .header form {
    border: 11px solid #2eb0d1;
  }

  .main .header .btn {
    font-size: 25px;
    border: 3px dashed #e0e0e0;
    padding: 15px 20px;
  }

  .main .header .navbar .call span {
    font-size: 20px;
  }

  .main .header .navbar .nav-item {
    margin: 0px 50px;
  }

  .main .header .navbar .nav-link {
    font-size: 20px;
    width: 85px;
    padding-top: 35px;
  }

  .main .header .navbar .nav-item:hover::after {
    border-width: 32px 42px 20px;
    top: 62px;
  }

  .main .header .navbar .call {
    margin: 25px 2%;
  }

  .main .header .navbar .nav-item.active .nav-link {
    width: 85px;
    padding-top: 35px;
  }

  .main .header .navbar .nav-link:hover {
    width: 85px;
  }

  .main .header .navbar .nav-item.active:after {
    border-width: 32px 42px 20px;
    top: 62px;
  }

  .main .header .navbar .call svg {
    font-size: 30px;
  }

  .main .header .navbar .call span {
    font-size: 20px;
  }

  .main .header .navbar {
    padding: 0px 20px 0px 20px;
  }

  .main .header .navbar .dropdown-menu .dropdown-item {
    font-size: 25px;
    padding: 40px 0px 2px 0px;
  }

  .main .header .navbar .dropdown-menu {
    padding: 0px 30px 50px 23px;
    border-bottom-right-radius: 20px;
    top: 95px;
  }
}






.conttt-mod-sec .modal-body{
padding: 20px;
}

.conttt-mod-sec .btn-submit{
    background-color: #2eb0d1;
    color: #fff;
    margin-top: 20px;
} 