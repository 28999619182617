@font-face {
  font-family: Signature Font;
  src: url("/public/assets/Images/BlogPage/Signatrue.ttf");
}

/* banner-section css */
.blog-details .banner {
  background-image: url(/public/assets/Images/BlogDetails/banner.png);
  height: 475px;
  width: 100%;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blog-details .banner .text-holder {
  height: 475px;
  background-color: #00000073;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-details .banner .text-holder h1 {
  font-family: "Signatrue Font";
  font-size: 60px;
  letter-spacing: 8.32px;
  color: #ffffff;
}


/* details-section css */
.details {
  margin: 0% 4%;
  padding: 5%;
}

.details .complete-sec .head-sec .all-circles .circles {
  display: flex;
  align-items: center;
}

.details .complete-sec .head-sec .all-circles .circles .circle {
  width: 2px;
  height: 2px;
  background-color: #707070;
  border-radius: 50%;
  margin: 0 2px 2px 0;
}

.details .complete-sec .head-sec .butn {
  background-color: white;
  padding: 0px 15px;
}

.details .complete-sec .head-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details .complete-sec .heading h4 {
  font-size: 25px;
  font-weight: bold;
}

.details .complete-sec .text-icons {
  display: flex;
}

.details .complete-sec .text-icons .icon {
  height: 16px;
  width: 16px;
}

.details .complete-sec .text-icons .space {
  margin: 10px 10px 0px 0px;
}

.details .complete-sec p {
  font-size: 14px;
  font-weight: 500;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5; */
}

.details .complete-sec .image-one .main-img {
  height: 340px;
  width: 100%;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 0 5px grey, 0 0 10px gray, 0 0 15px grey;
}

.details .complete-sec .text {
  padding: 30px 0px 0px 0px;
}


/* footer-section css */
.footer-sec .blog-icons {
  display: flex;
  justify-content: end;
  margin-right: 20px;
}

.footer-sec .blog-icons .image {
  height: 30px;
  width: 30px;
  margin: 0px 3px;
}

.footer-sec .blog-icons .line {
  margin: 10px;
  height: 2px;
  width: 25px;
  background-color: #ee9723;
}

.footer-sec .blog-icons .icon-text p {
  font-weight: bold;
  margin-right: 10px;
}


/* media query */

@media screen and (min-width: 0px) and (max-width: 320px) {
  .details {
    margin: 0% 1%;
  }

  .details .complete-sec .text-icons {
    display: unset;
  }

  .details .complete-sec p {
    font-size: 12px;
    margin-bottom: 0px;
  }

  .details .complete-sec .head-sec .butn {
    padding: 2px;
    font-size: 10px;
  }

  .details .complete-sec .heading h4 {
    font-size: 20px;
  }

  .blog-details .banner {
    height: 200px;
  }

  .blog-details .banner .text-holder {
    height: 200px;
  }

  .details .complete-sec .image-one .main-img {
    margin-top: 10px;
  }
}

@media screen and (min-width: 321px) and (max-width: 486px) {
  .details {
    margin: 0% 1%;
  }

  .details .complete-sec .text-icons .space {
    margin: 10px 7px 0px 0px;
  }

  .details .complete-sec .text-icons {
    display: unset;
  }

  .details .complete-sec p {
    font-size: 12px;
    margin-bottom: 0px;
  }

  .details .complete-sec .head-sec .butn {
    padding: 2px;
    font-size: 10px;
  }

  .details .complete-sec .heading h4 {
    font-size: 20px;
  }

  .blog-details .banner {
    height: 200px;
  }

  .blog-details .banner .text-holder {
    height: 200px;
  }

  .details .complete-sec .image-one .main-img {
    margin-top: 10px;
    height: 200px;
  }
}

@media screen and (min-width: 487px) and (max-width: 576px) {
  .blog-details .banner {
    height: 200px;
  }

  .blog-details .banner .text-holder {
    height: 200px;
  }

  .details {
    margin: 0% 1%;
  }

  .details .complete-sec .image-one .main-img {
    margin-top: 10px;
  }

  .details .complete-sec .head-sec .butn {

    padding: 0px 10px;
  }
}

@media screen and (min-width: 577px) and (max-width: 769px) {
  .blog-details .banner {
    height: 200px;
  }

  .details .complete-sec .image-one .main-img {

    height: 200px;
  }

  .blog-details .banner .text-holder {
    height: 200px;
  }

  .details .complete-sec .head-sec .butn {

    padding: 0px 10px;
  }

}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .blog-details .banner {
    height: 300px;
  }

  .blog-details .banner .text-holder {
    height: 300px;
  }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .details {
    padding: 5% 3%;
  }
}

@media screen and (min-width: 1401px) and (max-width: 1800px) {
  .details {
    padding: 5% 3%;
  }
}

@media screen and (min-width: 1801px) and (max-width: 2200px) {
  .details .complete-sec .heading h4 {
    font-size: 30px;
  }

  .details .complete-sec p {
    font-size: 20px;
  }

  .details {
    padding: 5% 4%;
  }
}

@media screen and (min-width: 2000px) and (max-width: 2685px) {
  .details {
    padding: 4%;
  }

  .details .complete-sec .image-one .main-img {
    height: 600px;
  }

  .details .complete-sec .heading h4 {
    font-size: 40px;
  }

  .details .complete-sec p {
    font-size: 25px;
  }

  .details .complete-sec .text-icons .icon {
    height: 25px;
    width: 25px;
  }

  .details .complete-sec .text-icons .space {
    margin: 10px 25px 0px 0px;
  }

  .details .complete-sec .text {
    padding: 40px 0px 0px;
  }

  .footer-sec .blog-icons {
    margin: 20px;
  }

  .footer-sec .blog-icons .line {
    margin: 20px 0px;
    height: 2px;
    width: 45px;
  }

  .footer-sec .blog-icons .icon-text p {
    font-size: 25px;
  }

  .footer-sec .blog-icons .image {
    height: 45px;
    width: 45px;
    margin: 0px 10px;
  }
}