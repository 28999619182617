@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

.BlogFeed .img-section {
  background-image: url("/public/assets/Images/Blog/background-image.png");
  background-position: center;
  background-repeat: no-repeat;
  height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 4px -6px #b3b3b3, 0 -1px 4px 1px #b3b3b3;
}

.BlogFeed .img-section .text {
  text-align: center;
  padding: 0px;
}

.BlogFeed {
  margin-top: 3%;
}

.BlogFeed .img-section .swiper-section .main-img {
  height: 349px;
  object-fit: cover;
  width: 100%;
}

.BlogFeed .img-section .swiper-section .slider-section .inner-content {
  background-color: white;
  height: 269px;
  width: 98%;
  padding: 83px 7px 0px 37px;
}

.fix-editor1 {
  width: 100%;
  height: 269px;
}

.BlogFeed .img-section .first-heading p {
  font-size: 21px;
  color: #000000;
}

.BlogFeed .img-section .second-heading {
  font-family: "Poppins", "sans-serif";
  font-size: 40px;
  font-weight: bold;
  color: #000000;
}

.BlogFeed .inner-content .heading {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  font-weight: 600;
  font-size: 20px;
  font-family: "Poppins", "sans-serif";
}

.BlogFeed .imgs:hover {
  box-shadow: 0px 0px 15px 0px #2eb0d1;
}

.BlogFeed .swiper-section .swiper {
  padding-bottom: 30px;
}

.BlogFeed .slider-section {
  padding: 20px;
}

.BlogFeed .swiper-section {
  padding: 20px;
}

.BlogFeed .img-section .slider-section .imgs .inner-content .icon-sec .icon-size {
  height: 40px;
  width: 40px;
}

.BlogFeed .img-section .slider-section .imgs .inner-content .icon-sec .msg-icon {
  padding-right: 25px;
}

.mySwiper .swiper-pagination-bullet-active {
  background-color: #2eb0d1;
}

.BlogFeed .inner-content p {
  color: #000000;
  font-size: 18px;

}

.BlogFeed .swiper-pagination-bullet {
  height: 10px;
  border: 0.5px solid #2eb0d1;
  border-radius: 100%;
  display: inline-block;
  background: white;
  width: 10px;
  opacity: 3;
}

.BlogFeed .img-section span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #2eb0d1;
}

@media screen and (min-width: 280px) and (max-width: 320px) {
  .swiper-section {
    padding: 20px;
  }

  .fix-editor1 {
    width: 100%;
    height: 269px;
  }

  .BlogFeed .inner-content {
    height: 100%;
  }

  .BlogFeed .img-section .second-heading {
    font-size: 24px;
  }

  .BlogFeed .img-section .first-heading p {
    font-size: 17px;
  }

  .BlogFeed .img-section {
    height: auto;
  }

  .BlogFeed .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
  }

  .BlogFeed .img-section .swiper-section .main-img {
    height: auto;
  }

  .BlogFeed .img-section .swiper-section .slider-section .inner-content {
    height: 238px;
    width: 100%;
    padding: 41px 7px 0px 34px;
  }

  .BlogFeed .inner-content .heading {
    font-size: 18px;
  }

  .BlogFeed .img-section .slider-section .imgs .inner-content .icon-sec .icon-size {
    height: 30px;
    width: 30px;
  }

  .BlogFeed .swiper-section {
    padding: 0px 6px 0px 6px;
  }

  .blog-card {
    justify-content: center;
  }

  .BlogFeed .inner-content p {
    font-size: 14px;
  }
}

@media screen and (min-width: 321px) and (max-width: 390px) {
  .swiper-section {
    padding: 20px;
    margin-top: -4%;
  }


  .BlogFeed .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
  }

  .BlogFeed .inner-content p {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .BlogFeed .img-section .first-heading p {
    font-size: 17px;
  }

  .BlogFeed .img-section .text {
    padding: 5px;
  }

  .BlogFeed .img-section .second-heading {
    font-size: 26px;
    font-weight: bold;
  }

  section.BlogFeed {
    margin-top: 9%;
  }

  .BlogFeed .img-section {
    height: auto;
  }

  .BlogFeed .img-section .swiper-section .main-img {
    height: 272px;
    object-fit: cover;
    width: 100%;
  }

  .BlogFeed .img-section .swiper-section .slider-section .inner-content {
    height: 229px;
    width: 100%;
    padding: 46px 7px 0px 48px;
  }

  .icon-sec {
    margin-left: 0%;
  }

  .BlogFeed .inner-content .heading {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .blog-card {
    justify-content: center;
  }

  .BlogFeed .img-section .slider-section .imgs .inner-content .icon-sec .icon-size {
    height: 34px;
    width: 34px;
  }

  .fix-editor1 {
    width: 100%;
    height: 269px;
  }
}

@media screen and (min-width: 391px) and (max-width: 485px) {
  .fix-editor1 {
    width: 100%;
  }

  .BlogFeed .inner-content p {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .swiper-section {
    padding: 20px;
    margin-top: -5%;
  }

  .BlogFeed .img-section .text {
    padding: 5px;
  }

  .BlogFeed .img-section .second-heading {
    font-size: 26px;
  }

  .BlogFeed {
    margin-top: 9%;
  }

  .BlogFeed .swiper-section .swiper {
    padding-bottom: 36px;
  }

  .BlogFeed .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
  }

  .BlogFeed .img-section {
    height: auto;
  }

  .BlogFeed .img-section .swiper-section .main-img {
    height: 272px;
    object-fit: cover;
    width: 100%;
  }

  .BlogFeed .img-section .swiper-section .slider-section .inner-content {
    height: 262px;
    width: 100%;
    padding: 52px 7px 0px 48px;
  }

  .icon-sec {
    margin-left: 0%;
  }

  .BlogFeed .inner-content .heading {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .blog-card {
    justify-content: center;
  }

  .BlogFeed .img-section .slider-section .imgs .inner-content .icon-sec .icon-size {
    height: 34px;
    width: 34px;
  }

  .BlogFeed .img-section .first-heading p {
    font-size: 17px;
  }
}

@media screen and (min-width: 486px) and (max-width: 575px) {
  .swiper-section {
    padding: 20px;
    margin-top: -4%;
  }

  .BlogFeed .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
  }

  .BlogFeed .img-section .text {
    padding: 5px;
  }

  .BlogFeed .inner-content {
    height: 100%;
  }

  .BlogFeed .img-section .second-heading {
    font-size: 30px;
  }

  .BlogFeed .inner-content .heading {
    font-size: 20px;
  }

  .BlogFeed .img-section {
    height: auto;
  }

  .BlogFeed {
    margin-top: 10%;
  }

  .BlogFeed .img-section .swiper-section .slider-section .inner-content {
    width: 100%;
  }

  .BlogFeed .img-section .swiper-section .slider-section .inner-content {
    padding: 48px 7px 0px 37px;
  }

  .BlogFeed .inner-content p {
    font-size: 16px;
    margin-bottom: 11px;
  }
}

@media screen and (min-width: 576px) and (max-width: 650px) {
  .BlogFeed {
    margin-top: 10%;
  }

  .BlogFeed .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
  }

  .BlogFeed .img-section .swiper-section .slider-section .inner-content {
    background-color: white;
    width: 100%;
    padding: 58px 7px 0px 37px;
    height: 240px;
  }

  .BlogFeed .img-section .swiper-section .main-img {
    height: 208px;
  }

  .BlogFeed .inner-content p {
    font-size: 15px;
  }

  .BlogFeed .inner-content .heading {
    font-size: 22px;
  }

  .BlogFeed .img-section .slider-section .imgs .inner-content .icon-sec .icon-size {
    height: 30px;
    width: 30px;
  }

  .BlogFeed .img-section {
    height: auto;
  }

  .fix-editor1 {
    width: 100%;
    height: 241px;
  }

  .BlogFeed .img-section .second-heading {
    font-size: 32px;
  }
}

@media screen and (min-width: 651px) and (max-width: 767px) {
  .BlogFeed {
    margin-top: 10%;
  }

  .BlogFeed .img-section .second-heading {
    font-size: 32px;
  }

  .BlogFeed .img-section .swiper-section .slider-section .inner-content {
    height: 235px;
    width: 98%;
    padding: 63px 7px 0px 37px;
    /* width: 130%; */
  }

  .BlogFeed .img-section .swiper-section .main-img {
    height: 301px;
  }

  .BlogFeed .img-section {
    height: auto;
  }

  .fix-editor1 {
    width: 100%;
    height: 234px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .BlogFeed .img-section {
    height: auto;
  }

  .fix-editor1 {
    width: 100%;
    height: 269px;
  }

  .BlogFeed .img-section .swiper-section .slider-section .inner-content {
    padding: 60px 7px 0px 37px;
  }

  .BlogFeed .img-section .second-heading {
    font-size: 36px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .BlogFeed .img-section .swiper-section .slider-section .inner-content {
    padding: 53px 7px 0px 44px;
  }

  .BlogFeed .inner-content .heading {
    font-size: 25px;
  }

  .BlogFeed .inner-content p {
    font-size: 20px;
  }

  .BlogFeed {
    margin-top: 5%;
  }

  .BlogFeed .img-section {
    height: auto;
  }

  .fix-editor1 {
    width: 100%;
    height: 269px;
  }

  .BlogFeed .img-section .second-heading {
    font-size: 38px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1299px) {
  .BlogFeed {
    margin-top: 5%;
  }

  .BlogFeed .inner-content .heading {
    font-size: 17px;
  }

  .BlogFeed .img-section .second-heading {
    font-family: "Poppins", "sans-serif";
    font-size: 42px;
    font-weight: bold;
    color: #000000;
  }

  .BlogFeed .img-section .swiper-section .main-img {
    height: 269px;
    object-fit: cover;
    width: 100%;
  }

  .BlogFeed .img-section .swiper-section .slider-section .inner-content {
    background-color: white;
    height: 270px;
    width: 98%;
    padding: 60px 36px 60px 37px;
  }

  .BlogFeed .img-section .swiper-section .main-img {
    height: 269px;
  }

  .BlogFeed .img-section .slider-section .imgs .inner-content .icon-sec .icon-size {
    height: 30px;
    width: 30px;
  }

  .BlogFeed .img-section {
    height: auto;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 0px);
  }

  .BlogFeed .img-section .first-heading p {
    font-size: 19px;
  }

  .BlogFeed .img-section {
    height: auto;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1440px) {
  .BlogFeed {
    margin-top: 5%;
  }

  .BlogFeed .img-section {
    height: auto;
  }

  .BlogFeed .img-section .second-heading {
    font-size: 40px;
  }

  .BlogFeed .img-section .swiper-section .main-img {
    height: 269px;
    object-fit: cover;
    width: 100%;
  }

  .BlogFeed .img-section .swiper-section .slider-section .inner-content {
    background-color: white;
    height: 270px;
    width: 98%;
    padding: 47px 23px 0px 37px;
  }

  .fix-editor1 {
    width: 100%;
    height: 269px;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1880px) {
  .BlogFeed .img-section .swiper-section .slider-section .inner-content {
    padding: 54px 23px 0px 37px;
  }
}

@media (min-width: 2400px) and (max-width: 2690px) {
  .BlogFeed .img-section .second-heading {
    font-size: 52px;
  }

  .BlogFeed {
    margin-top: 5%;
  }

  .BlogFeed .inner-content .heading {
    font-size: 30px;
  }

  .BlogFeed .img-section .slider-section .imgs .inner-content .icon-sec .icon-size {
    height: 55px;
    width: 55px;
  }

  .BlogFeed .inner-content p {
    font-size: 21px;
  }

  .BlogFeed .img-section .swiper-section .slider-section .inner-content {
    height: 360px;
  }

  .fix-editor1 {
    width: 100%;
    height: 360px;
  }
}