.Plan-Your-Next-Staycation {
  margin-top: -3%;
  background-image: url('../../../../public/assets/Images/HomePage/Banner.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 640px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Plan-Your-Next-Staycation .text h1.heading.text-center {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  font-weight: 600;
  font-size: 40px;
  color: #FFF;
}

z.Plan-Your-Next-Staycation .underline {
  border-bottom: 5px solid black;
  margin: 0px auto;
  width: 229px;
}

.Plan-Your-Next-Staycation .text {
  position: absolute;
}

.Plan-Your-Next-Staycation .play-button {
  box-sizing: border-box;
  display: block;
  width: 80px;
  height: 80px;
  padding: 7px 23px;
  line-height: 50px;
  border: 8px solid #fff;
  border-radius: 50%;
  font-size: 32px;
  margin: 5% auto;
  filter: drop-shadow(0px 3px 6px #000000bd);
}

.Plan-Your-Next-Staycation .play-button svg.svg-inline--fa.fa-play {
  color: white;
  box-shadow: 0px 0px 0px #000000BD;
}

.Plan-Your-Next-Staycation .blur {
  position: absolute;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(10px);
}

.Plan-Your-Next-Staycation video.video-class-grid {
  width: 100%;
  height: 100%;
}

.Plan-Your-Next-Staycation .video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 50%;
  height: auto;
  z-index: 1;
}

.Plan-Your-Next-Staycation .close {
  position: absolute;
  top: 8px;
  right: 2%;
}

.Plan-Your-Next-Staycation svg.svg-inline--fa.fa-xmark {
  border-radius: 100%;
  background-color: white;
  height: 20px;
  width: 20px;
}

.Plan-Your-Next-Staycation svg.svg-inline--fa.fa-xmark {
  height: 25px;
  width: 25px;
}

@media (min-width:280px) and (max-width:320px) {
  .Plan-Your-Next-Staycation {
    height: 320px;
  }

  .Plan-Your-Next-Staycation .text h1.heading.text-center {
    font-size: 20px;
  }

  .Plan-Your-Next-Staycation .underline {
    width: 88px;
  }

  /* .Plan-Your-Next-Staycation .text {
    left: 27%;
} */
  .Plan-Your-Next-Staycation .play-button {
    width: 60px;
    height: 60px;
    padding: 1px 15px;
    font-size: 25px;
  }

  .Plan-Your-Next-Staycation .video {
    width: 95%;
  }

  .Plan-Your-Next-Staycation svg.svg-inline--fa.fa-xmark {
    height: 15px;
    width: 15px;
  }

  .Plan-Your-Next-Staycation .close {
    top: 0px;
  }

}

@media (min-width:321px) and (max-width:485px) {
  .Plan-Your-Next-Staycation {
    height: 400px;
  }

  .Plan-Your-Next-Staycation .text h1.heading.text-center {
    font-size: 30px;
  }

  /* .Plan-Your-Next-Staycation .text {
    left: 28%;
} */
  .Plan-Your-Next-Staycation .underline {
    width: 123px;
  }

  .Plan-Your-Next-Staycation .play-button {
    width: 70px;
    height: 70px;
    padding: 3px 18px;
    font-size: 26px;
  }

  .Plan-Your-Next-Staycation .video {
    width: 96%;
  }

  .Plan-Your-Next-Staycation svg.svg-inline--fa.fa-xmark {
    height: 16px;
    width: 16px;
  }

  .Plan-Your-Next-Staycation .close {
    top: 2px;
  }
}

@media (min-width:486px) and (max-width:575px) {
  .Plan-Your-Next-Staycation {
    height: 400px;
  }

  .Plan-Your-Next-Staycation .text h1.heading.text-center {
    font-size: 32px;
  }

  /* .Plan-Your-Next-Staycation .text {
   left: 31%;
   } */
  .Plan-Your-Next-Staycation .underline {
    width: 126px;
  }

  .Plan-Your-Next-Staycation .play-button {
    width: 70px;
    height: 70px;
    padding: 5px 19px;
    font-size: 25px;
  }

  .Plan-Your-Next-Staycation .video {
    width: 95%;
  }

  .Plan-Your-Next-Staycation svg.svg-inline--fa.fa-xmark {
    height: 20px;
    width: 20px;
  }
}

@media (min-width:576px) and (max-width:767px) {
  .Plan-Your-Next-Staycation {
    height: 400px;
  }

  .Plan-Your-Next-Staycation .underline {
    width: 160px;
  }

  .Plan-Your-Next-Staycation .video {
    width: 80%;
  }

  .Plan-Your-Next-Staycation svg.svg-inline--fa.fa-xmark {
    height: 20px;
    width: 20px;
  }

  .Plan-Your-Next-Staycation .text h1.heading.text-center {
    font-size: 31px;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .Plan-Your-Next-Staycation .text h1.heading.text-center {
    font-size: 45px;
  }

  .Plan-Your-Next-Staycation .underline {
    width: 175px;
  }

  .Plan-Your-Next-Staycation .video {
    width: 90%;
  }

  .Plan-Your-Next-Staycation {
    margin-top: -7%;

  }
}

@media (min-width:769px) and (max-width:921px) {
  .Plan-Your-Next-Staycation {
    margin-top: -6%;
  }
}

@media (min-width:922px) and (max-width:1199px) {


  .Plan-Your-Next-Staycation .video {
    width: 85%;
  }

  .Plan-Your-Next-Staycation {
    margin-top: -5%;
  }

  .Plan-Your-Next-Staycation .text h1.heading.text-center {
    font-size: 40px;
  }
}

@media (min-width:1200px) and (max-width:1300px) {
  .Plan-Your-Next-Staycation .video {
    width: 75%;
  }

  .Plan-Your-Next-Staycation {
    margin-top: -4%;
  }
}

@media (min-width:1301px) and (max-width:1400px) {
  .Plan-Your-Next-Staycation .video {
    width: 75%;
  }

  .Plan-Your-Next-Staycation {
    margin-top: -4%;
  }
}

@media (min-width:1401px) and (max-width:1600px) {
  .Plan-Your-Next-Staycation .video {
    width: 65%;
  }

  .Plan-Your-Next-Staycation {
    margin-top: -4%;
  }
}

@media (min-width:2400px) and (max-width:2690px) {
  .Plan-Your-Next-Staycation {
    height: 950px;
  }

  .Plan-Your-Next-Staycation .text h1.heading.text-center {
    font-size: 81px;
  }

  .Plan-Your-Next-Staycation .play-button {
    width: 120px;
    height: 120px;
    padding: 22px 36px;
    line-height: 50px;
    border: 12px solid #fff;
    font-size: 41px;
  }

  /* .Plan-Your-Next-Staycation {
  margin-top: -4%;
} */
}