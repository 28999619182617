/* .Discover {
    background-image: url('../../../../public/assets/Images/AboutUs/Banner/play.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 640px;
    margin-top: 5%;
    width: 100%;
}

.Discover .content {
    padding: 95px 0px;
}

.Discover .content h5.explore {
    font-size: 16px;
    color: #000000;
}

.Discover .content h1.place {
    line-height: 58px;
    font-weight: 600;
    font-size: 49px;
    color: #000000;
}

.Discover .blur {
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(10px);
}

.Discover video.video-class-grid {
    width: 100%;
    height: 100%;
}

.Discover .video {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 50%;
    height: auto;
    z-index: 1;
}

.Discover .close {
    position: absolute;
    top: 8px;
    right: 2%;
}

.Discover svg.svg-inline--fa.fa-xmark {
    border-radius: 100%;
    background-color: white;
    height: 20px;
    width: 20px;
}

.Discover svg.svg-inline--fa.fa-xmark {
    height: 25px;
    width: 25px;
}

@media (min-width:0px) and (max-width:320px) {
    .Discover {
        height: auto;
    }

    .Discover .content h1.place {
        line-height: 29px;
        font-size: 20px;
    }
}

@media (min-width:321px) and (max-width:485px) {
    .Discover {
        height: auto;
    }

    .Discover .content h1.place {
        line-height: 38px;
        font-size: 23px;
    }
}

@media (min-width:486px) and (max-width:575px) {
    .Discover {
        height: auto;
    }

    .Discover .content h1.place {
        line-height: 41px;
        font-size: 27px;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .Discover {
        height: auto;
    }

    .Discover .content h1.place {
        line-height: 50px;
        font-size: 34px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .Discover {
        height: auto;
    }

    .Discover .content h1.place {
        line-height: 54px;
        font-size: 37px;
    }
}

@media (min-width:992px) and (max-width:1199px) {} */

.discover .content h5.explore {
  font-size: 16px;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.discover .text .place p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.discover {
  margin-top: 5%;
  background-image: url('/public/assets/Images/HomePage/play.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 640px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.discover .text h1.heading.text-center {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  font-weight: 600;
  font-size: 40px;
  color: #000;
}

.discover .underline {
  border-bottom: 5px solid black;
  margin: 0px auto;
  width: 229px;
}

.discover .text {
  position: absolute;
}

.discover .play-button svg.svg-inline--fa.fa-play {
  color: white;
  box-shadow: 0px 0px 0px #000000BD;
}

.discover .blur {
  position: absolute;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(10px);
}

.discover video.video-class-grid {
  width: 100%;
  height: 100%;
}

.discover .video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 50%;
  height: auto;
  z-index: 1;
}

.discover .close {
  position: absolute;
  top: 8px;
  right: 2%;
}

.discover svg.svg-inline--fa.fa-xmark {
  border-radius: 100%;
  background-color: white;
  height: 20px;
  width: 20px;
}

.discover svg.svg-inline--fa.fa-xmark {
  height: 25px;
  width: 25px;
}

@media (min-width:280px) and (max-width:320px) {
  .discover {
    height: 320px;
  }

  .discover .text h1.heading.text-center {
    font-size: 20px;
  }

  .discover .underline {
    width: 88px;
  }

  /* .discover .text {
      left: 27%;
  } */
  .discover .play-button {
    margin: 0px 87px;
    width: 60px;
    height: 60px;
    /* padding: 1px 15px; */
    font-size: 25px;
  }

  .discover .video {
    width: 95%;
  }

  .discover svg.svg-inline--fa.fa-xmark {
    height: 15px;
    width: 15px;
  }

  .discover .close {
    top: 0px;
  }

}

@media (min-width:321px) and (max-width:485px) {
  .discover {
    height: 400px;
  }

  .discover .text h1.heading.text-center {
    font-size: 30px;
  }

  /* .discover .text {
      left: 28%;
  } */
  .discover .underline {
    width: 123px;
  }

  .discover .play-button {
    width: 70px;
    height: 70px;
    /* padding: 3px 18px; */
    font-size: 26px;
    margin: 0px 94px;
  }

  .discover .video {
    width: 96%;
  }

  .discover svg.svg-inline--fa.fa-xmark {
    height: 16px;
    width: 16px;
  }

  .discover .close {
    top: 2px;
  }
}

@media (min-width:486px) and (max-width:575px) {
  .discover {
    height: 400px;
  }

  .discover .text h1.heading.text-center {
    font-size: 32px;
  }

  /* .discover .text {
     left: 31%;
     } */
  .discover .underline {
    width: 126px;
  }

  .discover .play-button {
    width: 70px;
    height: 70px;
    margin: 1px 78px;
    font-size: 25px;
  }

  .discover .video {
    width: 95%;
  }

  .discover svg.svg-inline--fa.fa-xmark {
    height: 20px;
    width: 20px;
  }

  .play .play-lottie {
    width: 96px;
    height: 96px;

  }
}

@media (min-width:576px) and (max-width:767px) {
  .discover {
    height: 400px;
  }

  .discover .text h1.heading.text-center {
    font-size: 40px;
  }

  .discover .underline {
    width: 160px;
  }

  .discover .video {
    width: 80%;
  }

  .discover svg.svg-inline--fa.fa-xmark {
    height: 20px;
    width: 20px;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .discover .text h1.heading.text-center {
    font-size: 45px;
  }

  .discover .underline {
    width: 175px;
  }

  .discover .video {
    width: 90%;
  }

}

@media (min-width:769px) and (max-width:921px) {}

@media (min-width:922px) and (max-width:1199px) {


  .discover .video {
    width: 85%;
  }

  .discover .text h1.heading.text-center {
    font-size: 40px;
  }
}

@media (min-width:1200px) and (max-width:1300px) {
  .discover .video {
    width: 75%;
  }

}

@media (min-width:1301px) and (max-width:1400px) {
  .discover .video {
    width: 75%;
  }

}

@media (min-width:1401px) and (max-width:1600px) {
  .discover .video {
    width: 65%;
  }

}

@media (min-width:2400px) and (max-width:2690px) {
  .discover {
    height: 950px;
  }

  .discover .text h1.heading.text-center {
    font-size: 81px;
  }

  .discover .play-button {
    width: 120px;
    height: 120px;
    padding: 22px 36px;
    line-height: 50px;
    /* border: 12px solid #fff; */
    font-size: 41px;
  }

  .play .play-lottie {
    margin: 0px 99px !important;
  }

  .discover .content h5.explore {
    font-size: 23px;

  }
}