.main .PrivacyPolicyTabs {
    margin-top: 5%;
}

.main .PrivacyPolicyTabs .text-holder .head-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main .PrivacyPolicyTabs .text-holder .all-circles .circles {
    display: flex;
    align-items: center;
}

.main .PrivacyPolicyTabs .text-holder .all-circles .circles .circle {
    width: 2px;
    height: 2px;
    background-color: #707070;
    border-radius: 50%;
    margin: 0 2px 2px 0;
}

.main .PrivacyPolicyTabs .text-holder .heading h6 {
    font-size: 17px;
    font-weight: 500;
    color: #000000;
}

.main .PrivacyPolicyTabs .text-holder .heading h5 {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 2%;
}

.main .PrivacyPolicyTabs .text-holder .heading h1 {
    font-size: 40px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 3%;
}

.main .PrivacyPolicyTabs .text-holder .heading p {
    font-size: 17px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 3%;
    text-align: justify;
    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3; */
}

.main .PrivacyPolicyTabs h5.facebook {
    margin-top: 7%;
}

.main .PrivacyPolicyTabs .name-tabs #myTab {
    justify-content: center;
    border-bottom: none;
}

.main .PrivacyPolicyTabs h5.general {
    margin-top: 6%;
}

.main .PrivacyPolicyTabs .name-tabs .nav-item {
    margin: 1% 3%;
}

.main .PrivacyPolicyTabs .name-tabs .nav-item {
    margin: 1% 1%;
}

.main .PrivacyPolicyTabs .name-tabs .btn {
    width: 100%;
    height: 49px;
    border-radius: 0px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    border: 2px solid #7070707d;
}

.main .PrivacyPolicyTabs .name-tabs .nav-item .btn p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    margin-bottom: 0px;
}

.main .PrivacyPolicyTabs .name-tabs .btn:hover {
    background: #2eb0d1;
    color: #ffffff;
    border: 2px solid #2eb0d1;
}

.main .PrivacyPolicyTabs .name-tabs .active {
    background: #2eb0d1;
    color: #ffffff;
    border: 2px solid #2eb0d1;
}



.main .PrivacyPolicyTabs .name-tabs .btn .active {
    background: #2eb0d1;
    color: #ffffff;
    border: 2px solid #2eb0d1;
}

.main .PrivacyPolicyTabs .privacybackBtn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
}

@media (min-width: 280px) and (max-width: 320px) {
    .main .PrivacyPolicyTabs .text-holder .heading h1 {
        font-size: 20px;
    }

    .main .PrivacyPolicyTabs .text-holder .heading h6 {
        font-size: 12px;
    }

    .main .PrivacyPolicyTabs .text-holder .heading p {
        text-align: justify;
        font-size: 12px;
    }

    .main .PrivacyPolicyTabs .name-tabs .btn {
        font-size: 12px !important;
        width: 100%;
    }
}

@media (min-width: 320px) and (max-width: 485px) {
    .main .PrivacyPolicyTabs .text-holder .heading h1 {
        font-size: 20px;
    }

    .main .PrivacyPolicyTabs .text-holder .heading h6 {
        font-size: 12px;
    }

    .main .PrivacyPolicyTabs .text-holder .heading p {
        text-align: justify;
        font-size: 13px;
    }

    .main .PrivacyPolicyTabs .name-tabs .btn {
        width: 100%;
        font-size: 14px;
    }
}

@media (min-width: 486px) and (max-width: 574px) {
    .main .PrivacyPolicyTabs .text-holder .heading p {
        text-align: justify;
        font-size: 14px;
    }

    .main .PrivacyPolicyTabs .text-holder .heading h1 {
        font-size: 30px;
    }

    .main .PrivacyPolicyTabs .text-holder .heading h6 {
        font-size: 15px;
    }

    .main .PrivacyPolicyTabs .name-tabs .btn {
        width: 100%;
        font-size: 14px;
    }
}

@media (min-width: 575px) and (max-width: 767px) {
    .main .PrivacyPolicyTabs .name-tabs .btn {
        width: 100%;
        font-size: 14px;
    }

    .main .PrivacyPolicyTabs .text-holder .heading h1 {
        font-size: 30px;
    }

    .main .PrivacyPolicyTabs .text-holder .heading h6 {
        font-size: 15px;
    }

    .main .PrivacyPolicyTabs .text-holder .heading p {
        font-size: 14px;
        text-align: justify;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .main .PrivacyPolicyTabs .name-tabs .btn {
        font-size: 14px;
        width: 100%;
    }

    .main .PrivacyPolicyTabs .text-holder .heading p {
        text-align: justify;
        font-size: 15px;
    }
}

@media (min-width: 993px) and (max-width: 1024px) {
    .main .PrivacyPolicyTabs .name-tabs .nav-item {
        margin: 1% 1%;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
    .main .PrivacyPolicyTabs .name-tabs .nav-item {
        margin: 1% 1%;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .main .PrivacyPolicyTabs .name-tabs .btn {
        font-size: 12px;
    }
}

@media (min-width: 2000px) and (max-width: 2685px) {
    .main .PrivacyPolicyTabs .name-tabs .btn {
        font-size: 13px;
    }


    .main .PrivacyPolicyTabs .text-holder .heading p {
        font-size: 21px;
        text-align: justify;

    }

    .main .PrivacyPolicyTabs .text-holder .heading h6 {
        font-size: 20px;

    }
}