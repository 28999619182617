@font-face {
    font-family: Signatrue Font;
    src: url("/public/assets/Images/HomePage/Signatrue.ttf");
}

.main .banner {
    /* background-image: url("../../../../public/assets/Images/AboutUs/Banner/Rooms_banner_img.png"); */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    position: relative;
    height: 483px;
}

.main .banner {
    /* background-image: url("../../../../public/assets/Images/AboutUs/Banner/Rooms_banner_img.png"); */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    position: relative;
    height: 483px;
}

.main .banner .text-holder {
    height: 483px;
    width: 100%;
    top: 45;
    z-index: 2;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main .banner .text-holder h1 {
    font-family: "Signatrue Font";
    font-size: 60px;
    letter-spacing: 8.32px;
    color: #ffffff;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-weight: bold;
    font-size: 31px;
    color: white;
}

.main .banner .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 1 !important;
    background-color: transparent !important;
}

/* media Query Started */

@media (min-width:0px) and (max-width:320px) {
    .main .banner .bg-img .banner-img {
        height: 150px;
    }

    .main .banner {
        height: 200px;
    }

    .main .banner .text-holder h1 {
        font-size: 20px;
    }

    .main .banner .text-holder {
        height: 100%;
        width: 100%;
        /* top: 48px; */
        font-size: 25px;
        text-align: center;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {

        font-size: 20px !important;

    }
}

@media (min-width:321px) and (max-width:767px) {

    .swiper-button-prev:after,
    .swiper-button-next:after {

        font-size: 20px !important;

    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .main .banner .text-holder {
        height: 300px;
    }

    .main .banner {
        height: 300px;
    }

    .main .banner .text-holder h1 {
        font-size: 30px;
    }
}

@media (min-width: 2000px) and (max-width: 2685px) {
    .main .banner {
        height: 680px;
    }

    .main .banner .text-holder {
        height: 680px;
    }

    .main .banner .text-holder h1 {
        font-size: 90px;
    }
}

/* media Query end */