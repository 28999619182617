.about-verified p.cur {
  color: #000000;
  font-size: 16px;
  text-align: center;
  margin: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.about-verified h5.verified {
  font-weight: 600;
  font-size: 20px;
  margin: auto;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

section.about-verified {
  margin-top: 2%;
}

/* Meadia Query Started */

@media (min-width:0px) and (max-width:320px) {
  .about-verified h5.verified {
    font-size: 16px;
  }
}

@media (min-width:321px) and (max-width:485px) {
  .about-verified h5.verified {
    font-size: 20px;
  }
}

@media (min-width:486px) and (max-width:575px) {
  .about-verified h5.verified {
    font-size: 20px;
  }

  .about-verified p.cur {
    font-size: 17px;
  }
}

@media (min-width:576px) and (max-width:767px) {
  .about-verified p.cur {
    font-size: 18px;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .about-verified h5.verified {
    font-size: 16px;
  }

  .about-verified p.cur {
    font-size: 13px;
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .about-verified h5.verified {
    font-size: 18px;
  }

  .about-verified p.cur {
    font-size: 14px;
  }
}

/* Meadia Query end*/

@media screen and (min-width: 2000px) and (max-width: 2685px) {
  .about-verified p.cur {

    font-size: 22px;

  }

  .about-verified h5.verified {

    font-size: 25px;

  }

  .About-Room .main-content .description-div p {

    -webkit-line-clamp: 3;
  }

  .About-Room .main-content p.sitting {

    font-size: 21px;

  }

  .About-Room .main-content button.button-read {

    font-size: 22px;

  }

  .About-Room .main-content .welcome {

    font-size: 24px;

  }

  .About-Room img.img-fluid {
    width: 35px;
    height: 19px;
  }

  .discover .content h5.explore {
    font-size: 24px;

  }

  h1.place.text-center {
    font-size: 59px;
  }

  .Team .content p.our {
    font-size: 32px;
    color: #000000;
  }

  .Team .content h1.meet {

    font-size: 50px;

  }

  .Team h5.sean {
    font-size: 27px;

  }

  .text-center {
    font-size: 25px;

  }


}