.main .Rooms-Card {
  margin: 2% 5%;
}

.main .Rooms-Card .card {
  /* width: 352px; */
  border-radius: 0px;
  margin-bottom: 5%;
  border: none;
  box-shadow: 0px 0px 6px #0000004f;
  overflow: hidden;
  height: 540px;
  width: 100%;
}

.main .Rooms-Card .card img {
  transition: 0.3s;
}

.main .Rooms-Card .card img:hover {
  transform: scale(1.1);
}

.main .Rooms-Card .text-holder h1 {
  font-size: 40px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  margin-bottom: 1%;
}

.main .Rooms-Card .card .card-body {
  padding: 0px;
  /* height: 250px;
  width: 400px; */
}

.main .Rooms-Card .card .card-body .card-title {
  margin-bottom: 0px;
}

.main .Rooms-Card .card .card-body .card-title .card-link {
  text-decoration: none;
}

.main .Rooms-Card .card .card-body .card-title .text-holder {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  background: #ffffff;
}


.main .Rooms-Card .card .card-body .card-title .text-holder h5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 0px;
  background: #000000;
  width: 88%;
  padding: 15px 25px;
}

.main .Rooms-Card .card .card-body .card-title:hover .text-holder h5 {
  background: #000000;
  width: 100%;
  transition: 0.5s ease;
}

.main .Rooms-Card .card .card-body .card-title:hover svg {
  color: #ffffff;
}


.main .Rooms-Card .card .card-body .card-title svg {
  color: #000;
  font-size: 25px;
  padding-right: 3%;
  position: absolute;
  right: 0;
}

.main .Rooms-Card .card .card-body .card-text {
  background-color: #ececec;
  padding: 3% 0% 0% 3%;
}

.main .Rooms-Card .card .card-body .card-text .icon {
  width: 25px;
  height: 25px;
  margin-right: 5%;
}

.main .Rooms-Card .card .card-body .card-text svg {
  margin-right: 4%;
  font-size: 20px;
}

.main .Rooms-Card .card .card-body .card-text span {
  font-size: 17px;
  font-weight: 500;
  color: #7e7e7e;
  /* display: -webkit-box; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.main .Rooms-Card .card .card-body .card-text .icon-text-holder {
  margin-bottom: 3%;
  text-align: left;
}

.main .Rooms-Card .card .card-body .stars {
  padding: 4% 6%;
}

.main .Rooms-Card .card .card-body .stars span {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin-right: 2%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.main .Rooms-Card .card .card-body .stars svg {
  color: #000000;
  margin-right: 1%;
  font-size: 20px;
}

.main .Rooms-Card .card .card-body .stars .yellow-star {
  color: #fec004;
}

.main .Rooms-Card .card .rooms-images {
  height: 326px;
}

@media (min-width: 280px) and (max-width: 320px) {
  .main .Rooms-Card .card .card-body .card-title .text-holder h5 {
    font-size: 15px;
    padding: 5% 0% 5% 8%;
  }

  .main .Rooms-Card .text-holder h1 {
    font-size: 30px;
  }

  .main .Rooms-Card .card .card-body .card-text span {
    font-size: 15px;
  }

  .main .Rooms-Card {
    margin: 2% 5%;
  }

  .main .Rooms-Card .card .card-body .card-text .icon {
    width: 20px;
    height: 20px;
  }

  .main .Rooms-Card .card .card-body .stars {
    padding: 3% 0% 3% 8%;
  }

  .main .Rooms-Card .card .card-body .stars span {
    font-size: 14px;
  }

  .main .Rooms-Card .card .card-body .card-text svg {
    margin-right: 7%;
  }

  .main .Rooms-Card .card .card-body .stars svg {
    font-size: 15px;
  }

  .main .Rooms-Card .card {
    height: 450px;
    width: 100%;
  }
}

@media (min-width: 321px) and (max-width: 375px) {
  .main .Rooms-Card .card .card-body .card-title .text-holder h5 {
    font-size: 17px;
    padding: 5% 0% 5% 7%;
  }

  .main .Rooms-Card .text-holder h1 {
    font-size: 30px;
  }

  .main .Rooms-Card .card .card-body .card-text span {
    font-size: 15px;
  }

  .main .Rooms-Card {
    margin: 2% 5%;
  }

  .main .Rooms-Card .card .card-body .card-text .icon {
    width: 20px;
    height: 20px;
    margin-right: 3%;
  }

  .main .Rooms-Card .card .card-body .card-text svg {
    margin-right: 4%;
  }

  .main .Rooms-Card .card .card-body .stars span {
    font-size: 15px;
  }

  .main .Rooms-Card .card .card-body .stars svg {
    font-size: 15px;
  }

  .main .Rooms-Card .card {
    height: 495px;
    width: 100%;
  }
}

@media (min-width: 376px) and (max-width: 485px) {
  .main .Rooms-Card .card .card-body .card-title .text-holder h5 {
    font-size: 18px;
    padding: 3% 0% 3% 6%;
  }

  .main .Rooms-Card .text-holder h1 {
    font-size: 30px;
  }

  .main .Rooms-Card .card .card-body .stars svg {
    font-size: 15px;
  }

  .main .Rooms-Card .card .card-body .stars span {
    font-size: 15px;
  }

  .main .Rooms-Card .card .card-body .card-text span {
    font-size: 15px;
  }

  .main .Rooms-Card {
    margin: 2% 5%;
  }

  .main .Rooms-Card .card .card-body .card-text .icon {
    width: 20px;
    height: 20px;
    margin-right: 3%;
  }

  .main .Rooms-Card .card {
    height: 510px;
    width: 100%;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .main .Rooms-Card .card .card-body .card-title .text-holder h5 {
    font-size: 18px;
    padding: 3% 0% 3% 6%;
  }

  .main .Rooms-Card .text-holder h1 {
    font-size: 30px;
  }

  .main .Rooms-Card .card .card-body .stars svg {
    font-size: 17px;
  }

  .main .Rooms-Card .card .card-body .stars span {
    font-size: 16px;
  }

  .main .Rooms-Card .card .card-body .card-text span {
    font-size: 17px;
  }

  .main .Rooms-Card {
    margin: 2% 5%;
  }

  .main .Rooms-Card .card .card-body .card-text .icon {
    width: 20px;
    height: 20px;
    margin-right: 3%;
  }

  .main .Rooms-Card .card .card-body .card-text svg {
    margin-right: 4%;
    font-size: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .main .Rooms-Card .card .card-body .card-title .text-holder h5 {
    font-size: 14px;
    padding: 5% 0% 5% 8%;
  }

  .main .Rooms-Card .card {
    height: 495px;
  }

  .main .Rooms-Card .card .card-body .card-title svg {
    padding-right: 4%;
    font-size: 18px;
  }

  .main .Rooms-Card .text-holder h1 {
    font-size: 30px;
  }

  .main .Rooms-Card .card .card-body .card-text svg {
    margin-right: 6%;
  }

  .main .Rooms-Card .card .card-body .card-text span {
    font-size: 15px;
  }

  .main .Rooms-Card {
    margin: 2% 5%;
  }

  .main .Rooms-Card .card .card-body .stars span {
    font-size: 13px;
  }

  .main .Rooms-Card .card .card-body .stars svg {
    font-size: 13px;
  }

  .main .Rooms-Card .card .card-body .card-text .icon {
    width: 20px;
    height: 20px;
  }

  .main .Rooms-Card .card .card-body .stars {
    padding: 3% 0% 3% 8%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .main .Rooms-Card .card .card-body .card-title .text-holder h5 {
    font-size: 17px;
    padding: 3% 0% 3% 7%;
  }


  .main .Rooms-Card .card .card-body .card-title svg {
    font-size: 20px;
    padding-right: 4%;
  }

  .main .Rooms-Card .card .card-body .stars span {
    font-size: 15px;
  }

  .main .Rooms-Card .card .card-body .stars svg {
    color: #000000;
    margin-right: 1%;
    font-size: 15px;
  }

  .main .Rooms-Card .card .card-body .card-text .icon {
    width: 20px;
    height: 20px;
  }

  .main .Rooms-Card .text-holder h1 {
    font-size: 30px;
  }

  .main .Rooms-Card .card .card-body .card-text span {
    font-size: 17px;
  }

  .main .Rooms-Card {
    margin: 2% 5%;
  }

  .main .Rooms-Card .card .card-body .card-text svg {
    margin-right: 3%;
    font-size: 20px;
  }

  .main .Rooms-Card .card {
    height: 510px;
  }

  /* .main .Rooms-Card .card .rooms-images {
  height: 260px;
} */

}

@media (min-width: 993px) and (max-width: 1023px) {
  .main .Rooms-Card .card .card-body .card-title svg {
    font-size: 25px;
    padding-right: 4%;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .main .Rooms-Card {
    margin: 2% 5%;
  }

  .main .Rooms-Card .card .card-body .card-title svg {
    font-size: 25px;
    padding-right: 4%;
  }

  .main .Rooms-Card .card .card-body .stars span {
    font-size: 17px;
  }

  .main .Rooms-Card .card {
    height: 540px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .main .Rooms-Card {
    margin: 2% 5%;
  }

  .main .Rooms-Card .card .card-body .card-title svg {
    padding-right: 3%;
    font-size: 22px;
  }

  .main .Rooms-Card .card .card-body .stars span {
    font-size: 17px;
  }

  .main .Rooms-Card .card {
    height: 520px;
  }
}

@media (min-width: 1400px) and (max-width: 1459px) {
  .main .Rooms-Card .card .rooms-images {
    height: 302px;
  }
}

@media (min-width: 2000px) and (max-width: 2685px) {
  .main .Rooms-Card .card .card-body .card-title .text-holder h5 {
    font-size: 30px;
    padding: 4% 0% 4% 5%;
  }

  .main .Rooms-Card .card .card-body .card-title svg {
    padding-right: 4%;
    font-size: 40px;
    position: absolute;
    right: 0;
  }
}

@media (min-width: 2000px) and (max-width: 2685px) {
  .main .Rooms-Card .text-holder h1 {
    font-size: 70px;
  }

  .main .Rooms-Card .card .rooms-images {
    height: 500px;
  }

  .main .Rooms-Card .card {
    height: 835px;
    width: 550px;
  }

  .main .Rooms-Card .card .card-body .card-text .icon {
    width: 35px;
    height: 35px;
  }

  .main .Rooms-Card .card .card-body .card-text span {
    font-size: 25px;
  }

  .main .Rooms-Card .card .card-body .card-text svg {
    font-size: 35px;
    margin-right: 3%;
  }

  .main .Rooms-Card .card .card-body .stars svg {
    font-size: 25px;
  }

  .main .Rooms-Card .card .card-body .stars span {
    font-size: 25px;
  }

}