@font-face {
  font-family: Signatrue Font;
  src: url("../../../../public/assets/Images/banner/Signatrue.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

/* homepage banner css started */

.Hostel_HomePage_Banner .video-class-grid {
  height: 658px;
  width: 100%;
  object-fit: cover;
}

.Hostel_HomePage_Banner .hostel-main .heading-holder .overlay .room {
  color: #fff;
  font-family: Poppins, "sans-serif" !important;
  font-size: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

section.Hostel_HomePage_Banner {
  position: relative;
}

.Hostel_HomePage_Banner .swiper-button-next {
  opacity: 0.6;
  border-radius: 100% !important;
  width: 65px !important;
  height: 65px !important;
  background-color: #000000;
}

.calender {}

.Hostel_HomePage_Banner .hostel-main .swiper-button-prev:after,
.swiper-button-next:after {
  font-weight: bold;
  color: #ffff !important;
  font-size: 30px !important;
}

.Hostel_HomePage_Banner .hostel-main .swiper-button-prev {
  opacity: 0.6;
  border-radius: 100% !important;
  width: 65px !important;
  height: 65px !important;
  background-color: #000000;
}

.Hostel_HomePage_Banner .hostel-main .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.6;
  border-radius: 100% !important;
  width: 65px !important;
  height: 65px !important;
  background-color: #000000;
}

.Hostel_HomePage_Banner .hostel_banner_slider .img-overlay {
  position: absolute;
  top: 20%;
  left: 10%;
}

.Hostel_HomePage_Banner .hostel_banner_slider .text {
  position: absolute;
  top: 51%;
  left: 45%;
}

.Hostel_HomePage_Banner .hostel_banner_slider {
  position: relative;
  background-color: #000;
  height: auto;
}

.Hostel_HomePage_Banner .hostel_banner_slider .overley {
  background-image: linear-gradient(46deg,
      rgb(0 0 0 / 50%) 111%,
      #00000073 -65%);
}


/* .Hostel_HomePage_Banner .hostel_banner_slider .vedio {
  opacity: 0.6;
  display: block;
} */

/* .Hostel_HomePage_Banner .hostel_banner_slider .vedio2 {
  opacity: 0.6;
  display: none;
}

.Hostel_HomePage_Banner .hostel_banner_slider .vedio3 {
  display: none;
  opacity: 0.6;
}

.Hostel_HomePage_Banner .hostel_banner_slider .vedio4 {
  display: none;
  opacity: 0.6;
} */

.Hostel_HomePage_Banner .DatePick {
  display: flex;
  width: 100%;
}

.Hostel_HomePage_Banner .form-select:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.Hostel_HomePage_Banner .react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.DatePick input:focus-visible {
  border: 1px solid #b7b7b7 !important;
  outline: none;
}

/* text section on banner css started*/
.Hostel_HomePage_Banner .heading-holder .overlay {
  /* left: -66px; */
  left: 0px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  top: -100%;
}

.Hostel_HomePage_Banner .heading-holder h2.book {
  color: transparent;
  font-family: Signatrue Font;
  font-size: 95px;
  letter-spacing: 16.1px;
  opacity: 50%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* overflow: hidden; */
  -webkit-line-clamp: 3;
  /* display: none; */
}

.Hostel_HomePage_Banner .heading-holder {
  bottom: 352%;
  position: relative;
  text-align: center;
}

.Hostel_HomePage_Banner .heading-holder .rooms {
  font-family: "Poppins", "sans-serif" !important;
  font-size: 20px;
  color: white;
}

.Hostel_HomePage_Banner .heading-holder h1.hostel {
  line-height: 149px;
  font-size: 80px;
  font-weight: bold;
  color: white;
  font-family: "Poppins", "sans-serif" !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.Hostel_HomePage_Banner .room-btn button.btn.btn-primary {
  border-radius: 0px;
  width: 196px;
  height: 47px;
  background-color: transparent;
  border: 1px solid white;
  font-size: 16px;
}

.Hostel_HomePage_Banner .room-btn {
  position: absolute;
  text-align: center;
  top: 63%;
}

/* text section on banner css end */

.icons {
  z-index: 2;
  bottom: 24%;
  position: fixed;
  left: 2%;
}

.icons img.img-fluid {
  width: 40px;
  height: 40px;
}

.icons .vl {
  border-left: 1px solid #ee9723;
  height: 27px;
  position: absolute;
  left: 50%;
  margin-left: 0px;
  top: 104%;
}

.icons .follow {
  top: 72px;
  position: relative;
}

.icons .follow .us {
  font-weight: bold;
  font-size: 16px;
  padding: 5px 0px 0px 0px;
  text-align: center;
  left: -30px;
  transform: rotate(-90deg);
  width: 92px;
  height: 32px;
  background-color: white;
  position: absolute;
}

/* Book Your Stay css started */
.book-main {
  justify-content: space-evenly;
  padding: 20px 0 0;
}

.Hostel_HomePage_Banner .book_your_stay .book_stay {
  position: relative;
  width: 100%;
  height: 130px;
  background: #ffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.Hostel_HomePage_Banner .book_your_stay {
  position: absolute;
  width: 100%;
  top: 76%;
  z-index: 1;
}

.Hostel_HomePage_Banner .book_your_stay h4.book1 {
  /* text-align: center; */
  font-weight: 600;
  color: #00000099;
  font-size: 21px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.Hostel_HomePage_Banner .book_your_stay .book_stay .form-control {
  margin-top: 11%;
  height: 60px;
}

.Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
  width: 100%;
  height: 60px;
  border-radius: 0px !important;
  border: 1px solid #b7b7b7 !important;
}

.Hostel_HomePage_Banner .book_your_stay .book_stay .check button.btn.btn-primary {
  font-size: 17px;
  border: none;
  background: #2eb0d1 0% 0% no-repeat padding-box;
  height: 58px;
  width: 100%;
  border-radius: 0px;
}

.Hostel_HomePage_Banner .hostel-main .swiper-button-prev:hover {
  background-image: url("/public/assets/Images/banner/button.png");
  border-radius: 100% !important;
  width: 65px !important;
  height: 65px !important;
  opacity: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.Hostel_HomePage_Banner .hostel-main .swiper-button-next:hover {
  background-image: url("/public/assets/Images/banner/button.png");
  border-radius: 100% !important;
  width: 65px !important;
  height: 65px !important;
  opacity: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.Hostel_HomePage_Banner .book_your_stay .book_stay span#basic-addon1 {
  width: 40px;
  border-radius: 0px !important;
  background-color: transparent;
  border-right: 1px solid #b7b7b7;
  border-bottom: 1px solid #b7b7b7;
  border-top: 1px solid #b7b7b7;
  border-left: 1px solid #b7b7b7;
  border-right: none;
}

.Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled,
.Hostel_HomePage_Banner .hostel-main .swiper-button-prev.swiper-button-disabled {
  opacity: none;
  cursor: none;
  pointer-events: auto !important;
}

.Hostel_HomePage_Banner .g-slide::after {
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 90px solid #ffffffbf;
  content: "";
  position: absolute;
  top: 1%;
  bottom: 42%;
  left: -41px;
  right: 93%;
  z-index: -1;
}

.Hostel_HomePage_Banner .g-slide::before {
  z-index: -1;
  position: absolute;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 90px solid #ffffffbf;
  border-radius: 0px;
  bottom: 41%;
  height: 0;
  left: auto;
  right: -41px;
  top: 0;
  content: "";
}

.Hostel_HomePage_Banner .hostel-main .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  top: 40% !important;
  left: var(--swiper-navigation-sides-offset, 50px);
  right: auto;
}

.Hostel_HomePage_Banner .hostel-main .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  top: 40%;
  right: var(--swiper-navigation-sides-offset, 50px);
  left: auto;
}

.Hostel_HomePage_Banner .book_your_stay .book_stay .input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
}

.Hostel_HomePage_Banner .book_your_stay .book_stay .input-group .check_in_out {
  position: absolute;
  z-index: 1;
  font-size: 12px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #000;
}

.Hostel_HomePage_Banner .book_your_stay .book_stay .input-group-text {
  background-color: #cfcfcf;
  border-left: none;
  border-radius: 10px 0 0 10px;
}

.Hostel_HomePage_Banner .book_your_stay .book_stay .react-datepicker__input-container {
  display: inline-block;
  position: relative;
  width: 100%;
}

.Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
  border-right: 1px solid #b7b7b7;
  border-bottom: 1px solid #b7b7b7;
  border-top: 1px solid #b7b7b7;
  border-left: 1px solid transparent;
  color: #00000080;
  font-size: 16px;
  height: 60px;
  padding: 7px;
  width: 100%;
}

.Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled:hover {
  background-image: url("/public/assets/Images/banner/button.png");
  border-radius: 100% !important;
  width: 65px !important;
  height: 65px !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
}

.Hostel_HomePage_Banner .hostel-main .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
  padding: 0px;
}

.Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled {
  opacity: 0.6;
  border-radius: 100% !important;
  width: 65px !important;
  height: 65px !important;
  background-color: #000000;
}

.Hostel_HomePage_Banner .book_stay .book .form-select {
  background-position: left 13px center !important;
  /* text-align: right; */
}

.Hostel_HomePage_Banner .book_stay .book .form-select {
  padding-left: 35px;
}


.Hostel_HomePage_Banner .adult_select {
  width: 100%;
}

.Hostel_HomePage_Banner .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none;
}

/* home banner Media Query Started */
@media (min-width: 0px) and (max-width: 320px) {
  .Hostel_HomePage_Banner .heading-holder .rooms {
    font-size: 15px;
    margin-top: 3%;
  }

  /* .Hostel_HomePage_Banner .video-class-grid {
    height: 400px;
} */

  .Hostel_HomePage_Banner .heading-holder {
    bottom: 460%;
  }

  .Hostel_HomePage_Banner .heading-holder h1.book {
    font-size: 22px;
    letter-spacing: 2.1px;
  }

  .Hostel_HomePage_Banner .heading-holder .overlay {
    top: -40px;
    left: 0px;
  }

  .Hostel_HomePage_Banner .hostel-main .heading-holder .overlay .room {
    font-size: 15px;
  }

  .Hostel_HomePage_Banner .heading-holder h1.hostel {
    line-height: 33px;
    font-size: 17px;
  }

  .Hostel_HomePage_Banner .room-btn button.btn.btn-primary {
    width: 116px;
    height: 26px;
    font-size: 11px;
  }

  .Hostel_HomePage_Banner .room-btn {
    top: 43%;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next {
    width: 35px !important;
    height: 35px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev {
    width: 35px !important;
    height: 35px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 15px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next:hover {
    width: 35px !important;
    height: 35px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev:hover {
    width: 35px !important;
    height: 35px !important;
  }

  .icons {
    bottom: 62%;
  }

  .Hostel_HomePage_Banner .heading-holder h2.book {
    font-size: 25px;
    letter-spacing: 4.1px;
  }

  .Hostel_HomePage_Banner .video-class-grid {
    height: 166px;
    width: 100%;
  }

  /* .Hostel_HomePage_Banner .video-class-grid {
    height: 332px;
} */
  /* .Hostel_HomePage_Banner .hostel_banner_slider .vedio {
    display: none;
  } */

  .Hostel_HomePage_Banner .hostel_banner_slider .vedio2 {
    display: block;
  }

  .Hostel_HomePage_Banner .hostel_banner_slider .vedio3 {
    display: none;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 26% !important;
    right: var(--swiper-navigation-sides-offset, 12px);
    left: auto;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 26% !important;
    left: var(--swiper-navigation-sides-offset, 12px);
  }


  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    width: 35px !important;
    height: 35px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled:hover {
    width: 35px !important;
    height: 35px !important;
  }

  .icons img.img-fluid {
    width: 25px;
    height: 25px;
  }

  .icons .follow .us {
    font-size: 13px;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled {
    width: 35px !important;
    height: 35px !important;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Hostel_HomePage_Banner .video-class-grid {
    height: 200px;
    width: 100%;
  }

  .Hostel_HomePage_Banner .heading-holder {
    bottom: 150px;
  }

  .Hostel_HomePage_Banner .heading-holder h2.book {
    font-size: 25px;
    letter-spacing: 4.1px;
  }

  .Hostel_HomePage_Banner .heading-holder .rooms {
    font-size: 13px;
  }

  .book-main {
    padding: 14px 0px 0px 0px;
  }

  .Hostel_HomePage_Banner .heading-holder h1.book {
    font-size: 22px;
    letter-spacing: 8.1px;
  }

  .Hostel_HomePage_Banner .heading-holder h1.hostel {
    line-height: 35px;
    font-size: 23px;
  }

  .Hostel_HomePage_Banner .heading-holder .overlay {
    left: 0px;
    top: -226%;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 33% !important;
    left: var(--swiper-navigation-sides-offset, 23px);
  }


  .Hostel_HomePage_Banner .hostel-main .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 33%;
    right: var(--swiper-navigation-sides-offset, 20px);
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next {
    width: 35px !important;
    height: 35px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev {
    width: 35px !important;
    height: 35px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 15px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next:hover {
    width: 35px !important;
    height: 35px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev:hover {
    width: 35px !important;
    height: 35px !important;
  }

  .icons {
    bottom: 53%;
  }

  .icons img.img-fluid {
    width: 25px;
    height: 25px;
  }

  .Hostel_HomePage_Banner .hostel_banner_slider .vedio {
    display: block;
  }

  /* .Hostel_HomePage_Banner .hostel_banner_slider .vedio2 {
    display: block;
  } */

  .Hostel_HomePage_Banner .hostel_banner_slider .vedio3 {
    display: block;
  }




  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    width: 35px !important;
    height: 35px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled:hover {
    width: 35px !important;
    height: 35px !important;
  }

  .icons img.img-fluid {
    width: 25px;
    height: 25px;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled {
    width: 35px !important;
    height: 35px !important;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Hostel_HomePage_Banner .video-class-grid {
    height: 250px;
    width: 100%;
  }

  .Hostel_HomePage_Banner .heading-holder {
    bottom: 200px;
  }

  .Hostel_HomePage_Banner .heading-holder h2.book {
    font-size: 25px;
    letter-spacing: 4.1px;
  }

  .Hostel_HomePage_Banner .heading-holder .rooms {
    font-size: 13px;
  }

  .Hostel_HomePage_Banner .room-btn {
    top: 55%;
  }

  .Hostel_HomePage_Banner .heading-holder .rooms {
    font-size: 14px;
  }

  .Hostel_HomePage_Banner .heading-holder .overlay {
    top: -280%;
    left: 0px;
  }

  .Hostel_HomePage_Banner .heading-holder h1.hostel {
    line-height: 50px;
    font-size: 31px;
  }

  .Hostel_HomePage_Banner .room-btn button.btn.btn-primary {
    width: 116px;
    height: 36px;
    font-size: 11px;
  }

  .icons img.img-fluid {
    width: 25px;
    height: 25px;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next {
    width: 35px !important;
    height: 35px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev {
    width: 35px !important;
    height: 35px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next:hover {
    width: 35px !important;
    height: 35px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev:hover {
    width: 35px !important;
    height: 35px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 15px !important;
  }

  .icons {
    bottom: 56%;
  }

  /* .Hostel_HomePage_Banner .hostel_banner_slider .vedio {
    display: none;
  } */

  .Hostel_HomePage_Banner .hostel_banner_slider .vedio2 {
    display: block;
  }


  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 35% !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 35% !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev.swiper-button-disabled,
  .Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled {
    width: 35px !important;
    height: 35px !important;
  }


  .Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled:hover {
    width: 35px !important;
    height: 35px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Hostel_HomePage_Banner .video-class-grid {
    height: 350px;
    width: 100%;
  }

  .Hostel_HomePage_Banner .heading-holder {
    bottom: 265px;
  }

  .Hostel_HomePage_Banner .heading-holder h2.book {
    font-size: 45px;
    letter-spacing: 8.1px;
  }

  .Hostel_HomePage_Banner .heading-holder .rooms {
    font-size: 17px;
  }

  .Hostel_HomePage_Banner .room-btn {
    top: 55%;
  }

  .Hostel_HomePage_Banner .heading-holder h1.hostel {
    line-height: 65px;
    font-size: 38px;
  }

  .Hostel_HomePage_Banner .heading-holder .overlay {
    top: -170%;
    left: 0px;
  }

  .Hostel_HomePage_Banner .room-btn {
    top: 55%;
  }

  .Hostel_HomePage_Banner .room-btn button.btn.btn-primary {
    width: 136px;
    height: 37px;
    font-size: 12px;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev {
    width: 50px !important;
    height: 50px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next:hover {
    width: 50px !important;
    height: 50px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next {
    width: 50px !important;
    height: 50px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev:hover {
    width: 50px !important;
    height: 50px !important;
  }

  .icons {
    bottom: 50%;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 35% !important;
    left: var(--swiper-navigation-sides-offset, 25px);
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 35%;
    right: var(--swiper-navigation-sides-offset, 25px);
  }

  /* .Hostel_HomePage_Banner .hostel_banner_slider .vedio {
    display: none;
  } */

  .Hostel_HomePage_Banner .hostel_banner_slider .vedio2 {
    display: block;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev.swiper-button-disabled,
  .Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled {
    width: 50px !important;
    height: 50px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled:hover {
    width: 50px !important;
    height: 50px !important;
  }

  .icons img.img-fluid {
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Hostel_HomePage_Banner .video-class-grid {
    height: 350px;
    width: 100%;
  }

  .Hostel_HomePage_Banner .heading-holder {
    bottom: 270px;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 25px !important;
  }

  .Hostel_HomePage_Banner .heading-holder h2.book {
    font-size: 45px;
    letter-spacing: 8.1px;
  }

  .Hostel_HomePage_Banner .heading-holder .rooms {
    font-size: 17px;
  }

  .Hostel_HomePage_Banner .room-btn {
    top: 55%;
  }

  .Hostel_HomePage_Banner .heading-holder h1.hostel {
    line-height: 65px;
    font-size: 38px;
  }

  .Hostel_HomePage_Banner .heading-holder .overlay {
    top: -160%;
    left: 0px;
  }

  .Hostel_HomePage_Banner .room-btn button.btn.btn-primary {
    width: 158px;
    height: 43px;
    font-size: 13px;
  }

  .Hostel_HomePage_Banner .room-btn {
    top: 55%;
  }

  .icons {
    bottom: 48%;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev {
    width: 50px !important;
    height: 50px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next {
    width: 50px !important;
    height: 50px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 37% !important;
    left: var(--swiper-navigation-sides-offset, 27px);
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 37% !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev:hover {
    width: 53px !important;
    height: 53px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next:hover {
    width: 53px !important;
    height: 53px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev.swiper-button-disabled,
  .Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled {
    width: 50px !important;
    height: 50px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled:hover {
    width: 50px !important;
    height: 50px !important;
  }

  .Hostel_HomePage_Banner .g-slide::after {
    display: none;
  }

  .icons img.img-fluid {
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Hostel_HomePage_Banner .video-class-grid {
    height: 422px;
    width: 100%;
  }

  .book-main {
    padding: 8px 0px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay {
    height: auto;
  }

  .Hostel_HomePage_Banner .heading-holder {
    bottom: 515%;
  }

  .Hostel_HomePage_Banner .heading-holder h1.hostel {
    line-height: 65px;
    font-size: 40px;
  }

  .Hostel_HomePage_Banner .room-btn {
    top: 45%;
  }



  .Hostel_HomePage_Banner .heading-holder .overlay {
    left: 0;
    right: 0;
    top: -150%;
  }

  .Hostel_HomePage_Banner .heading-holder h2.book {
    font-size: 50px;
    letter-spacing: 18.1px;
  }

  .Hostel_HomePage_Banner .book_your_stay h4.book1 {
    font-size: 15px;
  }

  .Hostel_HomePage_Banner .book_your_stay {
    top: 61%;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay span#basic-addon1 {
    width: 48px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
    width: 100%;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .check button.btn.btn-primary {
    font-size: 14px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
    font-size: 13px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Hostel_HomePage_Banner .book_your_stay {
    top: 64%;
  }

  .Hostel_HomePage_Banner .heading-holder h2.book {
    font-size: 60px;
    letter-spacing: 8.1px;
  }



  .Hostel_HomePage_Banner .heading-holder {
    bottom: 430%;
  }

  .Hostel_HomePage_Banner .video-class-grid {
    height: 422px;
    width: 100%;
  }

  .Hostel_HomePage_Banner .heading-holder h1.book {
    font-size: 65px;
    letter-spacing: 11.1px;
  }

  .Hostel_HomePage_Banner .heading-holder h1.hostel {
    line-height: 80px;
    font-size: 40px;
  }

  .Hostel_HomePage_Banner .heading-holder .overlay {
    top: -128%;
    left: 0;
  }

  .Hostel_HomePage_Banner .room-btn {
    top: 52%;
  }

  .Hostel_HomePage_Banner .room-btn button.btn.btn-primary {
    width: 160px;
    height: 41px;
    font-size: 13px;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 33% !important;
    left: var(--swiper-navigation-sides-offset, 24px);
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 33%;
    right: var(--swiper-navigation-sides-offset, 24px);
  }

  .icons {
    bottom: 40%;
    left: 1%;
  }

  .Hostel_HomePage_Banner .heading-holder .rooms {
    font-size: 18px;
  }

  .book-main {
    padding: 9px 0px 9px 0px;
    justify-content: space-evenly;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay {
    height: auto;
  }

  .icons img.img-fluid {
    width: 30px;
    height: 30px;
  }

  .Hostel_HomePage_Banner .g-slide::after {
    bottom: 56%;
    left: -41px;
    right: 93%;
    z-index: -1;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .Hostel_HomePage_Banner .book_your_stay {
    top: 59%;
  }

  .Hostel_HomePage_Banner .heading-holder h2.book {
    font-size: 55px;
    letter-spacing: 8.1px;
  }



  .Hostel_HomePage_Banner .heading-holder {
    bottom: 430%;
  }

  .Hostel_HomePage_Banner .video-class-grid {
    height: 568px;
    width: 100%;
  }

  .Hostel_HomePage_Banner .heading-holder h1.book {
    font-size: 65px;
    letter-spacing: 11.1px;
  }

  .Hostel_HomePage_Banner .heading-holder h1.hostel {
    line-height: 80px;
    font-size: 50px;
  }

  .Hostel_HomePage_Banner .heading-holder .overlay {
    top: -128%;
    left: 0;
  }

  .Hostel_HomePage_Banner .room-btn {
    top: 54%;
  }

  .Hostel_HomePage_Banner .room-btn button.btn.btn-primary {
    width: 189px;
    height: 47px;
    font-size: 14px;
  }

  .Hostel_HomePage_Banner .heading-holder {
    bottom: 532%;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
    font-size: 12px;
  }

  .icons img.img-fluid {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 1301px) and (max-width: 1400px) {
  .Hostel_HomePage_Banner .room-btn {
    top: 57%;
  }


  .Hostel_HomePage_Banner .heading-holder {
    bottom: 390%;
  }

  .Hostel_HomePage_Banner .heading-holder h1.hostel {
    font-size: 50px;
    line-height: 106px;
  }

  .Hostel_HomePage_Banner .heading-holder .rooms {
    font-size: 18px;
  }

  .Hostel_HomePage_Banner .heading-holder h1.book {
    font-size: 75px;
    letter-spacing: 5.1px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
    font-size: 12px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .check button.btn.btn-primary {
    font-size: 14px;
  }

  .icons img.img-fluid {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 1401px) and (max-width: 1500px) {
  .Hostel_HomePage_Banner .room-btn {
    top: 58%;
  }

  /* .Hostel_HomePage_Banner .heading-holder {
    bottom: 520%;
  } */

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 34% !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 34%;
  }

  .Hostel_HomePage_Banner .heading-holder h1.book {
    font-size: 65px;
    letter-spacing: 11.1px;
  }

  .Hostel_HomePage_Banner .heading-holder .rooms {
    font-size: 16px;
  }

  .Hostel_HomePage_Banner .heading-holder .overlay {
    left: -31px;
    top: -111%;
  }

  .Hostel_HomePage_Banner .heading-holder h1.hostel {
    line-height: 103px;
    font-size: 63px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
    font-size: 12px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .check button.btn.btn-primary {
    font-size: 14px;
  }

  .icons img.img-fluid {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 1501px) and (max-width: 1600px) {
  .Hostel_HomePage_Banner .room-btn {
    top: 58%;
  }

  .Hostel_HomePage_Banner .heading-holder {
    bottom: 414%;
  }

  .Hostel_HomePage_Banner .heading-holder h1.book {
    font-size: 73px;
    letter-spacing: 10.1px;
  }

  .Hostel_HomePage_Banner .heading-holder h1.hostel {
    line-height: 110px;
    font-size: 60px;
  }

  .Hostel_HomePage_Banner .heading-holder .overlay {
    left: 0;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
    font-size: 14px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .check button.btn.btn-primary {
    font-size: 14px;
  }

  .icons img.img-fluid {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 1601px) and (max-width: 1880px) {
  .Hostel_HomePage_Banner .room-btn {
    top: 60%;
  }

  .icons {
    bottom: 17%;
    left: 1%;
  }
}

@media (min-width: 2400px) and (max-width: 2690px) {
  .Hostel_HomePage_Banner .room-btn {
    top: 59%;
  }

  .Hostel_HomePage_Banner .heading-holder {
    bottom: 452%;
  }

  /* .Hostel_HomePage_Banner .hostel_banner_slider .vedio {
    opacity: 0.6;
  }

  .Hostel_HomePage_Banner .hostel_banner_slider .vedio2 {
    opacity: 0.6;
    display: none;
  }

  .Hostel_HomePage_Banner .hostel_banner_slider .vedio3 {
    display: none;
    opacity: 0.6;
  }

  .Hostel_HomePage_Banner .hostel_banner_slider .vedio4 {
    display: block;
    opacity: 0.6;
  } */

  .Hostel_HomePage_Banner .book_your_stay {
    top: 75%;
  }

  .Hostel_HomePage_Banner .room-btn button.btn.btn-primary {
    width: 267px;
    height: 72px;
    font-size: 22px;
  }

  .Hostel_HomePage_Banner .book_your_stay h4.book1 {
    font-size: 26px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay {
    height: 160px;
  }

  .book-main {
    padding: 34px 0px;
  }

  .icons img.img-fluid {
    width: 50px;
    height: 50px;
  }
}

/* home banner Media Query end */

/* Media Query book you stay started */
@media (min-width: 0px) and (max-width: 320px) {
  .Hostel_HomePage_Banner .book_your_stay .book_stay {
    height: auto;
    padding: 0px 9px 20px;
  }

  .Hostel_HomePage_Banner .book_your_stay {
    top: 66%;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
    font-size: 12px;
    width: 100%;
    height: 45px;
    /* margin-top: 13%; */
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .check button.btn.btn-primary {
    height: 44px;
    width: 100%;
    font-size: 10px;
  }

  .Hostel_HomePage_Banner .book_your_stay h4.book1 {
    text-align: center;
  }

  .DatePick {
    margin: auto;
  }

  .adult_select {
    text-align: -webkit-center;
  }

  .check {
    text-align: center;
  }

  .Hostel_HomePage_Banner .g-slide::before {
    display: none;
  }

  .Hostel_HomePage_Banner .g-slide::after {
    display: none;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
    font-size: 18px;
    height: 45px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
    font-size: 15px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Hostel_HomePage_Banner .hostel-main .heading-holder .overlay .room {

    font-size: 16px;

  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay {
    height: auto;
    padding: 0px 10px 20px;
  }

  .Hostel_HomePage_Banner .room-btn button.btn.btn-primary {
    width: 89px;
    height: 24px;
    font-size: 9px;
  }

  .Hostel_HomePage_Banner .room-btn {
    top: 55%;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
    font-size: 14px;
    width: 100%;
    height: 45px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .check button.btn.btn-primary {
    font-size: 13px;
    height: 45px;
    width: 100%;
  }

  .Hostel_HomePage_Banner .book_your_stay {
    top: 70%;
  }

  .Hostel_HomePage_Banner .book_your_stay h4.book1 {
    text-align: center;
  }

  .Hostel_HomePage_Banner .book_your_stay h4.book1 {
    text-align: center;
  }

  .adult_select {
    text-align: -webkit-center;
  }

  .check {
    text-align: center;
  }

  .Hostel_HomePage_Banner .g-slide::before {
    display: none;
  }

  .Hostel_HomePage_Banner .g-slide::after {
    display: none;
  }

  .DatePick {
    justify-content: center;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
    font-size: 15px;
    height: 45px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Hostel_HomePage_Banner .hostel-main .heading-holder .overlay .room {

    font-size: 15px;

  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay {
    height: auto;
    padding: 0px 10px;
  }

  .Hostel_HomePage_Banner .book_your_stay {
    top: 74%;
  }

  .book-main {
    padding: 11px 0px 11px 0px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
    font-size: 13px;
    width: 100%;
    margin-top: 6%;
    height: 45px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .check button.btn.btn-primary {
    font-size: 13px;
    width: 100%;
    margin: 6px 0px 0px 0px;
    height: 45px;
  }

  .Hostel_HomePage_Banner .book_your_stay h4.book1 {
    text-align: center;
  }

  .Hostel_HomePage_Banner .book_your_stay h4.book1 {
    text-align: center;
  }

  .adult_select {
    text-align: -webkit-center;
  }

  .check {
    text-align: center;
  }

  .Hostel_HomePage_Banner .g-slide::before {
    display: none;
  }

  .Hostel_HomePage_Banner .g-slide::after {
    display: none;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
    font-size: 15px;
    height: 45px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Hostel_HomePage_Banner .book_your_stay .book_stay {
    height: auto;
    padding: 8px 8px;
  }

  .Hostel_HomePage_Banner .book_your_stay h4.book1 {
    text-align: center;
    font-size: 20px;
  }

  .Hostel_HomePage_Banner .book_your_stay {
    top: 77%;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
    width: 100%;
    font-size: 13px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
    font-size: 12px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .check button.btn.btn-primary {
    font-size: 15px;
    height: 58px;
    width: 100%;
  }

  .Hostel_HomePage_Banner .g-slide::before {
    display: none;
  }

  .Hostel_HomePage_Banner .g-slide::after {
    display: none;
  }

  .book-main {
    padding: 8px 0px 0px 0px;
    justify-content: space-evenly;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .Hostel_HomePage_Banner .hostel-main {
    height: 490px;
  }

  .Hostel_HomePage_Banner .book_your_stay h4.book1 {
    text-align: center;
    font-size: 20px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
    width: 100%;
    height: 50px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .check button.btn.btn-primary {
    font-size: 12px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay {
    height: auto;
  }

  .g-slide::after {
    bottom: 42%;
  }

  .Hostel_HomePage_Banner .book_your_stay {
    top: 75%;
  }

  .Hostel_HomePage_Banner .g-slide:before {
    display: none;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
    font-size: 15px;
    height: 50px;
  }

  .book-main {
    padding: 15px 8px 15px 8px;
    justify-content: space-evenly;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .Hostel_HomePage_Banner .book_your_stay h4.book1 {
    font-size: 19px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .check button.btn.btn-primary {
    font-size: 13px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
    width: 100%;
    height: 60px;
  }


  .Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
    font-size: 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .Hostel_HomePage_Banner .book_your_stay {
    top: 71%;
  }


  .Hostel_HomePage_Banner .book_your_stay h4.book1 {
    font-size: 20px;
  }

  .Hostel_HomePage_Banner .book_your_stay .Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
    margin-top: 16%;
    width: 100%;
  }


  .Hostel_HomePage_Banner .heading-holder .overlay {
    left: 0;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .check button.btn.btn-primary {
    font-size: 14px;
    height: 56px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
    width: 100%;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 33% !important;
    left: var(--swiper-navigation-sides-offset, 37px);
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 33%;
    right: var(--swiper-navigation-sides-offset, 37px);
  }

  .icons {
    left: 1%;
    bottom: 31%;
  }
}

@media (min-width: 1400px) and (max-width: 1500px) {
  .Hostel_HomePage_Banner .book_your_stay {
    top: 70%;
  }



  .Hostel_HomePage_Banner .book_your_stay h4.book1 {
    font-size: 20px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
    width: 100%;
  }

  .icons {
    bottom: 32%;
    left: 1%;
  }
}

@media (min-width: 1501px) and (max-width: 1600px) {
  .Hostel_HomePage_Banner .book_your_stay {
    top: 72%;
  }

  .Hostel_HomePage_Banner .heading-holder h2.book {
    font-size: 80px;
    letter-spacing: 16.1px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
    width: 100%;
  }

  .Hostel_HomePage_Banner .book_your_stay h4.book1 {
    font-size: 23px;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 35% !important;
    left: var(--swiper-navigation-sides-offset, 37px);
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 35%;
    right: var(--swiper-navigation-sides-offset, 37px);
  }

  .icons {
    bottom: 32%;
    left: 1%;
  }
}

@media (min-width: 1601px) and (max-width: 1880px) {
  .Hostel_HomePage_Banner .book_your_stay {
    top: 71%;
  }

  .Hostel_HomePage_Banner .heading-holder {
    bottom: 358%;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
    width: 100%;
  }

  .Hostel_HomePage_Banner .heading-holder h1.hostel {
    line-height: 142px;

  }
}

@media (min-width: 1881px) and (max-width: 2399px) {
  .Hostel_HomePage_Banner .heading-holder {
    bottom: 450%;
  }

  .Hostel_HomePage_Banner .room-btn {
    top: 60%;
  }

  /* .Hostel_HomePage_Banner .video-class-grid {
    height: 580px;
  } */

  .Hostel_HomePage_Banner .heading-holder h1.hostel {
    line-height: 120px;
    font-size: 65px;
  }

  .Hostel_HomePage_Banner .heading-holder h2.book {
    font-size: 75px;
    letter-spacing: 16.1px;
  }

  .Hostel_HomePage_Banner .book_your_stay {
    top: 70%;
  }
}

@media (min-width: 2400px) and (max-width: 2690px) {
  .Hostel_HomePage_Banner .book_your_stay .book_stay .form-select {
    font-size: 17px;
    width: 100%;
    height: 73px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .DatePick input {
    font-size: 19px;
    height: 75px;
  }

  .Hostel_HomePage_Banner .book_your_stay .book_stay .check button.btn.btn-primary {
    height: 70px;
    width: 100%;
    font-size: 16px;
  }

  .icons {
    bottom: 28%;
    left: 1%;
  }

  .icons img.img-fluid {
    width: 50px;
    height: 50px;
  }

  .icons .follow .us {
    font-size: 19px;
    padding: 10px 0px 0px 0px;
    left: -29px;
    width: 110px;
    height: 44px;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    width: 85px !important;
    height: 85px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next {
    width: 85px !important;
    height: 85px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-prev {
    width: 85px !important;
    height: 85px !important;
  }

  .Hostel_HomePage_Banner .hostel-main .swiper-button-next.swiper-button-disabled {
    width: 85px !important;
    height: 85px !important;
  }

  .icons img.img-fluid {
    width: 50px;
    height: 50px;
  }

  .Hostel_HomePage_Banner .video-class-grid {
    height: 859px;
    width: 100%;
  }
}

@media (min-width: 2400px) and (max-width: 2690px) {
  .icons img.img-fluid {
    width: 50px;
    height: 50px;
  }

}

/* Media Query book you stay end */