@font-face {
  font-family: Signature Font;
  src: url("/public/assets/Images/BlogPage/Signatrue.ttf");
}

.blog-page .header-img {
  background-image: url(/public/assets/Images/BlogPage/main-img.png);
  height: 475px;
  width: 100%;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blog-page .header-img .text-holder {
  height: 475px;
  background-color: #00000073;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-page .header-img .text-holder h1 {
  font-family: "Signatrue Font";
  font-size: 60px;
  letter-spacing: 8.32px;
  color: #ffffff;
}

.blog-page .complete-sec {
  padding-top: 40px;
}

.blog-page .complete-sec .coloums {
  padding: 0px 15px 0px 30px;
}

.blog-page .complete-sec .all-circles .circles {
  display: flex;
  align-items: center;
}

.blog-page .complete-sec .all-circles .circles .circle {
  width: 2px;
  height: 2px;
  background-color: #707070;
  border-radius: 50%;
  margin: 0 2px 2px 0;
}

.blog-page .complete-sec .content-one {
  padding: 42px 20px 0px 25px;

}

.blog-page .complete-sec .content {
  height: 340px;
  width: 100%;
  position: relative;
}

.blog-page .content .custum-butn .butn {
  position: absolute;
  bottom: 25px;
  left: 25px;
}

.blog-page .complete-sec .content-two {
  padding: 42px 20px 0px 25px;
}

.content-three {
  padding: 42px 20px 0px 25px;
}

.blog-page .complete-sec .heading p {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.blog-page .complete-sec .text .description-div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 4;
  line-clamp: 5;
  /* text-align: justify; */
  font-size: 14px;
}

.blog-page .complete-sec .heading:hover {
  color: #2eb0d1;
  cursor: default;
}

.blog-page .custum-butn .butn {
  background-color: #707070;
  color: white;
  border-left: 6px solid #2eb0d1;
  border-radius: 0%;
  font-size: 15px;
  padding: 2px 5px;
  box-shadow: inset 0 0 0 0 #2eb0d1;
  transition: background-color 0.5s ease, box-shadow 0.5s ease;
}

.blog-page .custum-butn .butn:hover {
  background-color: #707070;
  color: white;
  box-shadow: inset 400px 0 0 0 #2eb0d1;
  border-right: 1px solid #2eb0d1;
  border-top: 1px solid #2eb0d1;
  border-bottom: 1px solid #2eb0d1;
  transition: background-color 0.9s ease, box-shadow 0.9s ease;
}

.blog-page .complete-sec .square-img .image {
  width: 100%;
  height: 340px;
  object-fit: cover;
}

.square-img .image {
  box-shadow: 0 0 5px grey, 0 0 10px gray, 0 0 15px grey;
}

.blog-page .complete-sec .middle-butn {
  text-align: center;
}

.blog-page .complete-sec .middle-butn .butn-two {
  background-color: #2eb0d1;
  border-radius: 0px 15px 0px 15px;
  border: none;
  margin: 60px 60px 30px 60px;
  padding: 0px 40px 6px 40px;
  font-size: 14px;
}

.blog-page .complete-sec .middle-butn .butn-two:hover {
  transform: translateY(10px);
  transition: transform 0.3s ease-in-out;
}

.blog-page .complete-sec .middle-butn .arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.blog-page .complete-sec .middle-butn .down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.blog-page .complete-sec .blog-icons {
  display: flex;
  justify-content: end;
  margin-right: 20px;
  margin-bottom: -40px;
  margin-top: 5%;
}

.blog-page .complete-sec .blog-icons .image {
  height: 30px;
  width: 30px;
  margin: 0px 3px;
}

.blog-page .complete-sec .blog-icons .line {
  margin: 10px;
  height: 2px;
  width: 25px;
  background-color: #ee9723;
}

.blog-page .complete-sec .blog-icons .icon-text p {
  font-weight: bold;
  margin-right: 10px;
}

.text {
  font-family: "Poppins", "sans-serif" !important;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1; */
}

.blog-page .complete-sec .middle-butn {
  text-align: center;
}

.blog-page .complete-sec .middle-butn .butn-two {
  background-color: #2eb0d1;
  border-radius: 0px 15px 0px 15px;
  border: none;
  margin: 60px 60px 30px 60px;
  padding: 0px 40px 6px 40px;
  font-size: 14px;
}

.blog-page .complete-sec .middle-butn .butn-two:hover {
  transform: translateY(10px);
  transition: transform 0.3s ease-in-out;
}

.blog-page .complete-sec .middle-butn .arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.blog-page .complete-sec .middle-butn .down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* .blog3 {
  display: flex;
  flex-direction: row-reverse;
}
.blog2 {
  display: flex;
  flex-direction: row-reverse;
} */
/* .blog4 {
  display: flex;
  flex-direction: row-reverse;
} */
/* .blog2 {
  display: flex;
  flex-direction: row-reverse;
  margin: 21px 0px;
} */

@media screen and (min-width: 0px) and (max-width: 320px) {
  .blog-page .complete-sec .content-one {
    padding: 20px;
  }

  .blog-page .complete-sec .content-two {
    padding: 20px;
  }

  .blog-page .complete-sec .content {
    height: 250px;
  }

  .blog-page .complete-sec .content-three {
    padding: 20px;
  }

  .blog-page .complete-sec .coloums {
    padding: 0px 25px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    margin: 40px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    padding: 0px 30px 6px 30px;
  }

  .blog-page .complete-sec .text p {
    font-size: 12px;
  }

  .blog-page .complete-sec .blog-icons .icon-text p {
    font-size: 12px;
  }


  .blog-page .complete-sec .square-img .image {
    width: 100%;
    height: 250px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    margin: 40px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    padding: 0px 30px 6px 30px;
  }

  .blog3 {
    display: flex;
    flex-direction: column-reverse;
  }

  .blog4 {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 321px) and (max-width: 486px) {
  .blog-page .complete-sec .content-one {
    padding: 20px;
  }


  .blog-page .complete-sec .content {
    height: 250px;
  }

  .blog-page .complete-sec .content-two {
    padding: 20px;
  }

  .blog-page .complete-sec .content-three {
    padding: 20px;
  }

  .blog-page .complete-sec .firstBlog_row {
    margin-bottom: 22px;
  }

  .blog-page .complete-sec .coloums {
    padding: 1px 40px 0px 40px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    margin: 40px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    padding: 0px 30px 6px 30px;
  }

  .blog-page .complete-sec .text p {
    font-size: 12px;
  }

  .blog-page .complete-sec .square-img .image {
    width: 100%;
    height: 300px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    margin: 40px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    padding: 0px 30px 6px 30px;
  }

  .blog3 {
    display: flex;
    flex-direction: column-reverse;
  }

  .blog4 {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 486px) and (max-width: 575px) {
  .blog-page .complete-sec .content-one {
    padding: 20px;
  }

  .blog-page .complete-sec .content-two {
    padding: 20px;
  }

  .blog-page .complete-sec .content-three {
    padding: 20px;
  }

  .blog-page .complete-sec .coloums {
    padding: 1px 40px 0px 40px;
  }


  .blog-page .complete-sec .middle-butn .butn-two {
    margin: 40px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    padding: 0px 30px 6px 30px;
  }

  .blog-page .complete-sec .heading p {
    font-size: 18px;
  }

  .blog-page .complete-sec .text p {
    font-size: 12px;
  }

  /* .blog3 {
    display: flex;
    flex-direction: column-reverse;
  } */
  .blog4 {
    display: flex;
    flex-direction: column-reverse;
  }

  .blog3 {
    display: flex;
    flex-direction: column-reverse;
  }

  /* 
  .blog-page .complete-sec .square-img .image {
    height: auto;
    width: 100%;
  } */
  .blog-page .complete-sec .middle-butn .butn-two {
    margin: 40px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    padding: 0px 30px 6px 30px;
  }

  /* .blog3 {
    display: flex;
    flex-direction: row-reverse;
} */
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .blog-page .complete-sec .heading p {
    font-size: 18px;
  }

  .blog-page .complete-sec .text p {
    font-size: 14px;
  }

  .blog-page .complete-sec .coloums {
    padding: 1px 40px 0px 40px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    margin: 40px;
  }

  .blog-page .complete-sec .blog-icons {
    margin-bottom: -6%;
  }

  .blog-page .complete-sec .content-one {
    padding: 18px 20px 0px 25px;
  }

  .blog-page .complete-sec .content-two {
    padding: 18px 20px 0px 25px;
  }

  .content-three {
    padding: 17px 20px 0px 25px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    margin: 40px;
  }

  .blog2 {
    display: flex;
    flex-direction: row-reverse;
  }

  .blog3 {
    display: flex;
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .blog-page .complete-sec .heading p {
    font-size: 21px;
  }

  .blog-page .complete-sec .text p {
    font-size: 15px;
  }

  .blog-page .complete-sec .coloums {
    padding: 1px 40px 0px 40px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    margin: 40px;
  }

  .blog2 {
    display: flex;
    flex-direction: row-reverse;
  }

  /* .blog-page .complete-sec .content-two {
    padding: 67px 20px 0px 25px;
  } */

  .blog-page .complete-sec .blog-icons {
    margin-bottom: -5%;
  }

  .blog-page .complete-sec .firstBlog_row {
    margin-bottom: 22px;
  }

  .blog3 {
    display: flex;
    flex-direction: row-reverse;
  }

  .blog2 {
    display: flex;
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .blog-page .complete-sec .heading p {
    font-size: 18px;
  }

  .blog-page .complete-sec .text p {
    font-size: 14px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    margin: 60px;
  }

  .blog-page .complete-sec .firstBlog_row {
    margin-bottom: 22px;
  }

  .blog-page .complete-sec .blog-icons {
    margin-bottom: -3%;
  }

  .blog-page .complete-sec .content {
    height: 260px;
  }

  .blog-page .complete-sec .content-two {
    padding: 18px 20px 0px 25px;
  }

  .content-three {
    padding: 17px 20px 0px 25px;
  }

  .blog-page .complete-sec .square-img .image {
    width: 100%;
    height: 260px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    margin: 60px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .blog-page .complete-sec .heading p {
    font-size: 19px;
  }

  .blog-page .complete-sec .content {
    height: 300px;
  }

  .blog-page .complete-sec .square-img .image {
    width: 100%;
    height: 300px;
  }

  .blog-page .complete-sec .text p {
    font-size: 14px;
  }

  .blog-page .complete-sec .blog-icons {
    margin-bottom: -3%;
  }
}

@media screen and (min-width: 1401px) and (max-width: 1800px) {


  .blog-page .complete-sec .heading p {
    font-size: 20px;
  }

  .blog-page .complete-sec .text p {
    font-size: 15px;
  }


}

@media screen and (min-width: 1801px) and (max-width: 2200px) {
  /* .blog-page .complete-sec .heading p {
    font-size: 30px;
} */
  /* .blog-page .complete-sec .text p {
    font-size: 20px;
  } */
}

/* @media (min-width: 0px) and (max-width: 768px) {
  .blog-page .header-img {
    height: 200px;
  }

  .blog-page .header-img .text-holder {
    height: 200px;
  }

  .blog-page .header-img .text-holder h1 {
    font-size: 25px;
  }
} */

/* @media (min-width: 768px) and (max-width: 991px) {
  .blog-page .header-img {
    height: 300px;
  }

  .blog-page .header-img .text-holder {
    height: 300px;
  }

  .blog-page .header-img .text-holder h1 {
    font-size: 30px;
  }
} */

@media screen and (min-width: 2000px) and (max-width: 2685px) {
  .blog-page .complete-sec .heading p {
    font-size: 30px;
    margin-bottom: 50px;
  }

  .blog-page .complete-sec .content {
    height: 450px;
    width: 100%;
  }

  .blog-page .complete-sec .square-img .image {
    width: 100%;
    height: 450px;
  }

  .blog-page .complete-sec .text p {
    font-size: 25px;
  }

  .blog-page .complete-sec {
    padding-top: 80px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    font-size: 25px;
    margin: 90px 60px 60px 60px;
    padding: 0px 50px 6px 50px;
  }

  .blog-page .header-img {
    height: 680px;
  }

  .blog-page .header-img .text-holder {
    height: 680px;
  }

  .blog-page .header-img .text-holder h1 {
    font-size: 90px;
  }

  .blog-page .complete-sec .blog-icons .line {
    margin: 20px 0px;
    height: 2px;
    width: 45px;
  }

  .blog-page .complete-sec .blog-icons .icon-text p {
    font-size: 25px;
  }

  .blog-page .complete-sec .blog-icons .image {
    height: 45px;
    width: 45px;
    margin: 0px 10px;
  }

  .blog-page .complete-sec .middle-butn .butn-two {
    font-size: 25px;
    margin: 90px 60px 60px 60px;
    padding: 0px 50px 6px 50px;
  }
}