.play .play-lottie{
width: 96px;
height: 96px;
margin: auto;
}
section.play {
margin-top: 4%;
}
@media (min-width:0px) and (max-width:320px) {
.play .play-lottie {
width: 60px;
height: 60px;
}
section.play {
margin-top: 6%;
}
}
@media (min-width:321px) and (max-width:485px) {
.play .play-lottie {
width: 60px;
height: 60px;
}
section.play {
margin-top: 6%;
}
}
@media (min-width:486px) and (max-width:575px) {

}
@media (min-width:576px) and (max-width:767px) {
}
