.main .Rooms-Banner {
  background-image: url(/public/assets/Images/RoomsDetails/Rooms_Details_banner/Rooms_Details_banner_img.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 475px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .main .Rooms-Banner {
    height: 200px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main .Rooms-Banner {
    height: 300px;
  }
}
@media (min-width: 2000px) and (max-width: 2600px) {
  .main .Rooms-Banner {
    height: 680px;
  }
}
